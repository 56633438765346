import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import { Grid, Tabs, Tab } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilRuler,
  faCog,
  faBrain,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { Tools } from "./VerticalToolBar";
import { withAllViewerContexts } from "../contexts/AllViewerContexts";

import SideBarTabAI from "./sidebar/SideBarTabAI";
import SideBarTabRois from "./sidebar/SideBarTabRois";
import SideBarTabView from "./sidebar/SideBarTabView";
import HistoClassSideBarTabResults from "./sidebar/HistoClassSideBarTabResults";
import HistoPntCntSideBarTabResults from "./sidebar/HistoPntCntSideBarTabResults";
import { Role } from "../../common/utils";
import { authenticationService } from "../../common/services";
import Backend from "../../common/utils/Backend";

// define the component's styling
const styles = () => ({
  sidebar: {
    margin: 0,
    background: "#fff",
  },
  tabsContainer: {
    width: "100%",
    "& .MuiTabs-fixed": {
      minHeight: 44,
      height: 44,
    },
  },
  tab: {
    minWidth: 395 / 3,
    minHeight: 44,
    height: 44,
    paddingBottom: 0,
    paddingTop: 0,
    "& *": {
      display: "inline-block",
      fontSize: "16px",
      lineHeight: "16px",
    },
    "& svg": {
      marginRight: "5px",
      position: "relative",
      top: "4px",
    },
  },
  flexVerticalContainer: {
    height: "100%",
    display: "grid",
    gridTemplateRows: "60px 1fr",
  },
  flexRowContentHeight: {
    //flex: "0 1 auto",
    padding: 10,
  },
  tabContentContainer: {
    display: "grid",
    gridTemplateRows: "1fr auto",
    height: "100%",
    overflow: "hidden",
  },
});

class SideBar extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      channelSelectMode: "all",
      availableModels: [],
      currentUser: null,
      isAdmin: false,
      modelsInitialized: false,
    };

    const { project } = this.props;

    window.forceSidebarUpdate = this.forceSidebarUpdate;

    this.isHistoClass = project.type.includes("HistoClassification");
    this.isHistoPntC = project.type.includes("HistoPointCounting");
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  forceSidebarUpdate = () => {
    this.forceUpdate();
  };

  componentDidMount = () => {
    this._isMounted = true;
    // set active tab from local storage
    let activeTab = this.props.persistentStorage.load("activeTab");
    if (activeTab) {
      if (activeTab !== this.state.activeTab) {
        this.setMountedState({ activeTab: activeTab });
      }
      this.props.projectContext.setActiveTab(activeTab);
    }
    authenticationService.currentUser.subscribe((x) => {
      this.setMountedState({
        currentUser: x,
        isAdmin: x && x.role === Role.Admin,
      });
    });

    // only load ai data if ai tab is present
    if (this.props.viewerConfig.project.toolsInProject["AICockpit"]) {
      this.initAIFormData(false);
      this.initAICockpitData();
    }
  };

  initAICockpitData = () => {
    let aiStateObject = {
      open: false,
      activeStep: 0,
      maxEpochs: 0,
      stepsProgress: "-",
      epochsProgress: "-",
      showTrainingButton: true,
      buildingModel: false,
      showTrainingProgress: false,
      trainingFinished: false,
      newModelName: "",
      overallProgress: 0.0,
      trainingSuccessful: true,
      startTrainingTime: null,
      errorLabel: "Failed",
      metricsDict: {},
      formData: {
        modelType: "segmentation",
        datasetOnly: false,
        fullScene:
          this.props.viewerConfig.project.projectStringProperties[
            "ViewerType"
          ] == "FilesGallery",
        selStructures: [],
        structureIndices: [],
        augmentations: ["h_flip", "v_flip"],
        metaData: {
          modelName: "",
          versionName: "001",
          newModel: true,
          uniqueName: false,
          validChar: false,
        },
        advancedSettings: {
          backbones: {
            unet: [],
            unet_pp: [],
            deepLabV3_p: [],
            classification_models: [],
          },
          lossFunction: "Cross Entropy",
          comDLArchitecture: null,
          level: this.props.ome.maxLevel,
          physicalSize: this.props.ome.physicalSizeX
            ? this.props.ome.physicalSizeX
            : 1,
          physicalSizeUnit: this.props.ome.physicalSizeXUnit
            ? this.props.ome.physicalSizeXUnit
            : "px",
          in_channels: this.setInputChannels(),
          flChannels: this.setFlChannels(),
          modelsize: "m",
          epochs: 100,
          optimizer: "Adam",
          lr: 0.0001,
          batch_size: 2,
          datasetApproach: "Sliding Window",
          includeBackgroundTiles: false,
          tileSize: 512,
          overlap: 64,
          objectBasedBaseStructure: null,
          calculateWeightMap: false,
          useExistingDataset: false,
          useClassWeights: false,
        },
      },
    };

    this.props.projectContext.setState({
      aiStateObject: aiStateObject,
    });
  };

  setInputChannels = () => {
    const { ome } = this.props;
    let isBrightfield =
      ome &&
      ome.channels.length === 1 &&
      ome.channels[0].type === "brightfield";

    return isBrightfield ? 3 : ome.channels.length;
  };

  setFlChannels = () => {
    const { ome, histogramConfig, fileId } = this.props;
    let isBrightfield =
      ome &&
      ome.channels.length === 1 &&
      ome.channels[0].type === "brightfield";

    if (histogramConfig[fileId] && !isBrightfield) {
      return histogramConfig[fileId].channels.map((c) => c.name);
    } else {
      return [];
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  setAvailableModels = (availableModels) => {
    this.setMountedState({ availableModels: availableModels });
  };

  setModelsInitialized = (initialized) => {
    this.setMountedState({ modelsInitialized: initialized });
  };

  loadPersistentDefaultModels = () => {
    let defaultModels = this.props.persistentStorage.loadProjectTypeValue(
      this.props.project.type,
      "defaultModels"
    );
    return Array.isArray(defaultModels) ? defaultModels : [];
  };

  /**
   * Request all model data from disk and optionally from online sources.
   * @param {bool} connectOnline Should models be donwloaded from the HSA online server. Defaults to false.
   */
  initAIFormData = (connectOnline = false) => {
    this.setMountedState({ modelsInitialized: false });

    Backend.getModelMetadata(
      "verified_models",
      connectOnline,
      (aiModels) => {
        // TODO Viktor: please check online mode

        // if (aiModelRepository.length === 0 && aiModelRepository.online) {
        //   this.initModelCounter++;
        //   if (this.initModelCounter < 5 && window.navigator.onLine) {
        //     setTimeout(() => {
        //       this.initAIFormData(connectOnline);
        //     }, 2000);
        //     return;
        //   }
        // }
        if (aiModels) {
          let formDataAICockpit = {};
          // start with index 1 -> skip Base ROI
          for (let i = 1; i < this.props.structures.length; i++) {
            let s = this.props.structures[i];
            let selected_model = null;
            let selected_version = null;
            if (this.props.formDataAICockpit != null) {
              if (typeof this.props.formDataAICockpit[s.id] !== "undefined") {
                selected_model =
                  this.props.formDataAICockpit[s.id].selectedModel;

                if (
                  selected_model !== null &&
                  this.props.formDataAICockpit[s.id].models[selected_model]
                ) {
                  selected_version =
                    this.props.formDataAICockpit[s.id].models[selected_model]
                      .selectedVersion;
                }
              }
            }

            formDataAICockpit[s.id] = {
              structureIndex: i,
              selectedModel: selected_model,
              selectedVersion: selected_version,
              modelType: null,
              fullStructure: s,
              models: {},
            };
            if (aiModels) {
              for (let model of aiModels) {
                if (model.versions.length > 0) {
                  formDataAICockpit[s.id].models[model.name] = {
                    selectedVersion:
                      formDataAICockpit[s.id].selectedVersion === null
                        ? model.versions.slice(-1)[0].label
                        : formDataAICockpit[s.id].selectedVersion,
                    modelType:
                      formDataAICockpit[s.id].modelType === null
                        ? model.versions.slice(-1)[0].modeltype
                        : formDataAICockpit[s.id].modeltype,
                  };
                }
              }
            }
          }
          this.setMountedState({
            availableModels: aiModels,
            modelsInitialized: true,
          });
          //this.props.projectContext.setAiModelRepository(aiModelRepository);
          this.props.projectContext.setState({
            aiModelRepository: aiModels,
          });

          // when project not saved yet
          // try to apply models in project config
          Backend.loadProject({ id: this.props.project.id }, (project) => {
            if (project.projectData === null) {
              const stringProperties =
                this.props.viewerConfig.project.projectStringProperties;

              let defaultModels = this.loadPersistentDefaultModels();
              if (
                defaultModels.length === 0 &&
                stringProperties.DefaultModels
              ) {
                defaultModels = stringProperties.DefaultModels.split(",");
              }
              if (defaultModels.length > 0) {
                for (let modelName of defaultModels) {
                  let foundModel = aiModels.find(
                    (item) => item.name === modelName
                  );
                  if (foundModel && foundModel.versions) {
                    let foundVersion =
                      foundModel.versions[foundModel.versions.length - 1];
                    if (foundVersion) {
                      for (let structureIndex of foundVersion.structure_indices) {
                        if (structureIndex > 0) {
                          let structureKey = Object.keys(
                            formDataAICockpit
                          ).find(
                            (key) =>
                              formDataAICockpit[key].structureIndex ===
                              structureIndex
                          );
                          if (structureKey) {
                            formDataAICockpit[structureKey].selectedModel =
                              modelName;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            // check if selected models still exist, otherwise, deselect it!
            for (const structure of Object.values(formDataAICockpit)) {
              if (structure.selectedModel !== null) {
                const modelInRepo = aiModels.find(
                  (item) => item.name === structure.selectedModel
                );
                if (typeof modelInRepo === "undefined") {
                  structure.selectedModel = null;
                  structure.selectedVersion = null;
                }
              }
            }

            this.props.setAIFormData(formDataAICockpit);
          });
        }
      },
      (error) => {
        console.warn(error);
      }
    );

    Backend.initPythonModules();
  };

  componentDidUpdate() {
    const { activeTool } = this.props;
    if (
      activeTool === "none" ||
      typeof activeTool === "undefined" ||
      activeTool === Tools.NONE ||
      activeTool === "iam_ai_inference" ||
      (activeTool === "gridtool" &&
        !(
          this.props.project.type.includes("HistoPointCounting") ||
          this.props.project.type.includes("HistoClassification")
        ))
    )
      return;
  }

  handleTabChange = (event, value) => {
    if (value === this.state.activeTab) return;
    // select tool coresponding to roilayer roi tab
    if (value === 1 && this.props.activeTool === "none") {
      if (this.props.structures[this.props.selectedLayer].tools[0]) {
        let toolName =
          this.props.structures[this.props.selectedLayer].tools[0].name;
        if (
          (toolName =
            "iam_ai_inference" &&
            !this.props.aiUsedStructures.filter(
              (e) => e.id === this.props.structures[this.props.selectedLayer].id
            ).length > 0)
        ) {
          toolName = "none";
        }

        this.props.onChangeTool(toolName);
      }
    }

    // make tools to none if view tab is selected for some cases
    if (value === 0) {
      if (
        this.isHistoPntC ||
        this.isHistoClass ||
        (this.props.activeTool && this.props.activeTool.includes("iam_"))
      ) {
        this.props.onChangeTool("none");
      }
    }

    // if resultTab select coresponding tools
    if (this.isResultTab(value)) {
      if (this.isHistoPntC) {
        this.props.onChangeTool("pointcountingtile");
      } else if (this.isHistoClass) {
        this.props.onChangeTool("selectiontile");
      }
    }

    // save active tab to local storage
    if (this.state.activeTab !== value) {
      this.props.persistentStorage.save("activeTab", value);
      this.setMountedState({ activeTab: value });
    }
    this.props.projectContext.setActiveTab(value);

    // only update if sidebar width changed
    if (this.isHistoPntC || this.isHistoClass) {
      setTimeout(() => this.props.setSideBarWidth(), 50);
    }
  };

  isResultTab = (tab) => {
    // check if active tab is result tab
    if (!this.checkToolInConfig("ResultTab")) {
      return false;
    }

    // check which tabs exist in module --> then decide which index result tab has
    let nextTab = tab;
    if (
      this.checkToolInConfig("AICockpit") &&
      this.checkToolInConfig("RoiTab")
    ) {
      // resultTab = 3
      return nextTab === 3;
    } else if (
      this.checkToolInConfig("AICockpit") ||
      this.checkToolInConfig("RoiTab")
    ) {
      // resultTab = 2
      return nextTab === 2;
    } else {
      // resultTab = 1
      return nextTab === 1;
    }
  };

  changeChannelSelectMode = (mode) => {
    this.setMountedState({
      channelSelectMode: mode,
    });
  };

  checkToolInConfig(toolName) {
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  }

  getActiveTabResults = () => {
    // get tab number/index for reslults tab
    // if AICockpit in project module results tab is number 3
    if (
      this.checkToolInConfig("AICockpit") &&
      this.checkToolInConfig("RoiTab")
    ) {
      return 3;
    } else if (
      this.checkToolInConfig("AICockpit") ||
      this.checkToolInConfig("RoiTab")
    ) {
      return 2;
    } else {
      return 1;
    }
  };

  renderCommentTool = () => {
    const { tool } = this.props;
    return (
      <div>
        {tool && !tool.noConfig && <Grid>{tool.renderConfiguration()}</Grid>}
      </div>
    );
  };

  render() {
    const { classes, ...propsWithoutClasses } = this.props;
    const { rois, roiLayers, activeTool, showGallery, rendererRef, tool } =
      this.props;
    const { activeTab, isAdmin } = this.state;
    const { annotationsReduced, totalRoiCount } = this.props.projectContext;

    if (!roiLayers) return null;

    let roiCount = 0;
    const gridTileCount =
      rendererRef && rendererRef.gridTileCount ? rendererRef.gridTileCount : 0;
    if (annotationsReduced) {
      roiCount = totalRoiCount;
    } else if (rendererRef) {
      roiCount =
        rois.length -
        gridTileCount +
        roiLayers.reduce((acc, cur) => acc + cur.layer.regionRois.length, 0);
    }

    return (
      <Grid
        item
        style={{
          width: this.props.sideBarWidth,
          overflow: "hidden",
        }}
        className={`${classes.sidebar} ${classes.flexVerticalContainer}`}
      >
        <Tabs
          className={`${classes.tabsContainer} ${classes.flexRowContentHeight}`}
          value={activeTab}
          onChange={this.handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            id="viewTabBtn"
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faCog} size="lg" />}
            label="View"
          ></Tab>
          {this.checkToolInConfig("RoiTab") && rendererRef && (
            <Tab
              id="roiTabBtn"
              className={classes.tab}
              icon={<FontAwesomeIcon icon={faPencilRuler} size="lg" />}
              label={"ROIS (" + roiCount + ")"}
            />
          )}
          {this.checkToolInConfig("AICockpit") && (
            <Tab
              id="aiTabBtn"
              className={classes.tab}
              icon={<FontAwesomeIcon icon={faBrain} size="lg" />}
              label="AI"
            ></Tab>
          )}
          {this.checkToolInConfig("ResultTab") && (
            <Tab
              id="resultTabBtn"
              className={classes.tab}
              icon={<FontAwesomeIcon icon={faChartBar} size="lg" />}
              label="scoring"
            ></Tab>
          )}
        </Tabs>
        {activeTool !== "comment" || activeTool !== "landmark" ? (
          <div className={classes.tabContentContainer}>
            <SideBarTabView
              visible={activeTab === 0}
              {...propsWithoutClasses}
            />
            {this.checkToolInConfig("RoiTab") && (
              <SideBarTabRois
                visible={activeTab === 1}
                isAdmin={isAdmin}
                forceSidebarUpdate={this.forceSidebarUpdate}
                {...propsWithoutClasses}
              />
            )}
            {this.checkToolInConfig("AICockpit") && (
              <SideBarTabAI
                visible={activeTab === 2}
                modelsInitialized={this.state.modelsInitialized}
                availableModels={this.state.availableModels}
                setAvailableModels={this.setAvailableModels}
                setModelsInitialized={this.setModelsInitialized}
                initAIFormData={this.initAIFormData}
                {...propsWithoutClasses}
              />
            )}
            {this.checkToolInConfig("ResultTab") &&
              !this.isHistoClass &&
              this.isHistoPntC && (
                <HistoPntCntSideBarTabResults
                  componentRef={(c) => (this.sideBarTabResults = c)}
                  visible={
                    activeTab === this.getActiveTabResults() && !showGallery
                  }
                  {...propsWithoutClasses}
                />
              )}
            {this.checkToolInConfig("ResultTab") &&
              this.isHistoClass &&
              !this.isHistoPntC && (
                <HistoClassSideBarTabResults
                  componentRef={(c) => (this.sideBarTabResults = c)}
                  visible={
                    activeTab === this.getActiveTabResults() && !showGallery
                  }
                  {...propsWithoutClasses}
                />
              )}
            {tool && !tool.noConfig && (
              <Grid
                className={classes.flexRowContentHeight}
                item
                key={activeTool}
                xs={12}
                style={{
                  borderTop: "#EBEBEB solid",
                  overflowY: "auto",
                  padding: "20px",
                }}
              >
                {tool.renderConfiguration()}
              </Grid>
            )}
          </div>
        ) : (
          <div style={{ padding: 10 }}>{this.renderCommentTool()}</div>
        )}
      </Grid>
    );
  }
}

// define the component's interface
SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  sideBarWidth: PropTypes.number,
  viewerConfig: PropTypes.object,
  alterStructure: PropTypes.func,
  histogramConfig: PropTypes.object,
  onChangeChannels: PropTypes.func,
  id: PropTypes.string.isRequired,
  ome: PropTypes.object,
  rois: PropTypes.array,
  onCenterROI: PropTypes.func,
  onHoverROI: PropTypes.func,
  onSelectFile: PropTypes.func,
  onExcludeFileToggle: PropTypes.func,
  // roiLayers
  roiLayers: PropTypes.array,
  selectedLayer: PropTypes.number,
  onChangeLayers: PropTypes.func,
  onChangeTool: PropTypes.func,
  deleteforAllScenes: PropTypes.func,
  // tools
  activeTool: PropTypes.string,
  tool: PropTypes.object,
  // opacity slider
  opacity: PropTypes.number,
  onChangeOpacity: PropTypes.func,
  // Parameterset Export Import
  onExportParameters: PropTypes.func,
  onImportParameters: PropTypes.func,
  formDataAICockpit: PropTypes.object,
  onUpdateDimensions: PropTypes.func,
  onSave: PropTypes.func,
  //not ordered
  persistentStorage: PropTypes.object,
  projectContext: PropTypes.object,
  project: PropTypes.object,
  rendererRef: PropTypes.object,
  setSideBarWidth: PropTypes.func,
  showGallery: PropTypes.bool,
  structures: PropTypes.array,
  setAIFormData: PropTypes.func,
  setAiUsedStructures: PropTypes.func,
  aiUsedStructures: PropTypes.array,
  fileId: PropTypes.string,
};

export default withAllViewerContexts(withStyles(styles)(SideBar));
