import React, { Component } from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";
import { DialogTitle, Dialog, LinearProgress } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from "@mui/styles/withStyles";
import "./Spinloader.css";

const SpinloaderContext = React.createContext();

export const withSpinloader = (Component) => {
  const WrappedComponent = (props) => (
    <SpinloaderContext.Consumer>
      {(context) => <Component {...props} spinloader={context} />}
    </SpinloaderContext.Consumer>
  );

  WrappedComponent.displayName = `withSpinloader(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

const styles = () => ({
  progressContainer: {
    position: "fixed",
    top: 64,
    left: 0,
    right: 390,
    bottom: 0,
    zIndex: 100000,
    margin: "auto",
    textAlign: "center",
    height: 50,
  },
  dialog: { pointerEvents: "none", right: 500, top: 60 },
  message: {
    display: "inline-block",
    position: "relative",
    top: -10,
    marginLeft: 10,
  },
  linearProgress: {
    height: 10,
  },
  greyedOutBackground: {
    background: "rgba(0,0,0,0.5)",
    top: 0,
    left: 0,
    width: "10000px",
    height: "10000px",
    position: "fixed",
    zIndex: 999999,
  },
});

// this is the main component, it has to be added at the root of the app.
// all components that use withSpinloader(...) will have access to it via this.props.spinloader...
class SpinloaderProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rightWidth: 100,
      show: false,
      messagePresent: false,
      message: "",
      indefinite: true,
      progress: 0,
    };
    this.timeout = null;
  }
  /**
   * Shows an indefinte spinloader
   */
  show() {
    if (!this.state.show) {
      this.setState({
        show: true,
        messagePresent: false,
        message: "",
        indefinite: true,
      });
    }
  }

  /**
   * Shows a progressbar with a prominent message above
   * @param {JSON} progressObject JSON Object with message and progress {message: , progress: }
   */
  showWithProgress = (progressObject) => {
    const progress = parseInt(progressObject.progress);
    this.setState({
      show: true,
      messagePresent: true,
      message: progressObject.message + ": " + progress + "%",
      progress: isNaN(progress) ? 0 : progress,
      indefinite: false,
    });
    clearTimeout(this.timeout);
  };

  /**
   * Shows an indefinite loader with a prominent message above
   * @param {String} message The info to be displayed
   */
  showWithMessage = (message) => {
    this.setState({
      show: true,
      messagePresent: true,
      message: message,
      indefinite: true,
    });
  };

  /**
   * Shows Spinloader and hides it after a defined time to prevent constant blocking of the screen
   * @param {int} scenesLeft Scenes to analyse. Used for delay calculation.
   * @param {int} timeoutSeconds Seconds to wait until hiding spinloader
   */
  showWithTimeout(scenesLeft, timeoutSeconds = 30) {
    let waitTime = 300000;
    if (scenesLeft) {
      waitTime = scenesLeft * timeoutSeconds * 1000;
      waitTime = Math.max(waitTime, 60000);
    }
    this.setState({ show: true });
    this.timeout = setTimeout(() => this.timeoutHide(), waitTime);
  }

  /**
   *
   * @param {*} scenesLeft
   * @param {*} timeoutSeconds
   */
  resetTimer(scenesLeft, timeoutSeconds = 30) {
    let waitTime = scenesLeft * timeoutSeconds * 1000;
    waitTime = Math.min(waitTime, 60000);
    clearTimeout(this.timeout);
    if (scenesLeft > 0) {
      this.timeout = setTimeout(() => this.timeoutHide(), waitTime);
    } else {
      this.hide();
    }
  }

  hide() {
    clearTimeout(this.timeout);
    this.setState({ show: false, messagePresent: false, message: "" });
  }

  timeoutHide() {
    this.hide();
    window.showWarningSnackbar(
      "Warning: Timeout! Process is taking especially long."
    );
  }

  hideTimeDelayed(delay) {
    setTimeout(() => {
      this.hide();
    }, delay);
  }

  load(func) {
    this.setState({ show: true });
    setTimeout(() => {
      func();
      this.setState({ show: false, messagePresent: false });
    }, 0);
  }

  setRightWidth(width) {
    this.setState({ rightWidth: width });
  }

  render() {
    const { classes } = this.props;
    return (
      <SpinloaderContext.Provider
        value={{
          show: () => this.show(),
          showWithTimeout: (numberScenes) => this.showWithTimeout(numberScenes),
          showWithProgress: (progObject) => this.showWithProgress(progObject),
          showWithMessage: (message) => this.showWithMessage(message),
          resetTimer: (numberRestScenes) => this.resetTimer(numberRestScenes),
          hide: () => this.hide(),
          hideTimeDelayed: (delay) => this.hideTimeDelayed(delay),
          load: (callback) => this.load(callback),
          setRightWidth: (width) => this.setRightWidth(width),
        }}
      >
        {this.props.children}
        {this.state.show && (
          <div className={classes.greyedOutBackground}>
            <div
              style={{ right: this.state.rightWidth - 5 }}
              className={classes.progressContainer}
            >
              {this.state.messagePresent ? (
                // Dialog option for more text
                <Dialog className={classes.dialog} open={true} hideBackdrop>
                  <DialogTitle>
                    <CircularProgress />
                    <div className={classes.message}>{this.state.message}</div>
                  </DialogTitle>
                  {this.state.indefinite ? (
                    <LinearProgress
                      className={classes.linearProgress}
                      style={{ height: 10 }}
                    />
                  ) : (
                    <LinearProgress
                      variant="determinate"
                      value={this.state.progress}
                      style={{ height: 10 }}
                    />
                  )}
                </Dialog>
              ) : (
                // Normal, indefinite spinloader
                <CircularProgress className={classes.progress} size={50} />
              )}
            </div>
          </div>
        )}
      </SpinloaderContext.Provider>
    );
  }
}

SpinloaderProvider.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

export default withStyles(styles)(SpinloaderProvider);
