import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import ScoringMatrixHistoPointCounting from "../ScoringMatrixHistoPointCounting";
import MiniMap from "../MiniMap";

import { Add } from "@mui/icons-material";

import withStyles from "@mui/styles/withStyles";

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  ListItem,
  TextField,
} from "@mui/material";

import { withAllViewerContexts } from "../../contexts/AllViewerContexts";
import { withTiles } from "../../contexts/TilesContext";
import { withResultTab } from "../../contexts/ResultTabContext";

import Backend from "../../../common/utils/Backend";

// define the component's styling
const styles = () => ({
  flexRowRemainingHeight: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  spacing: {
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
  },
});

// all possible substructures for module "Histo-Klassifikation"
const HistoClassififcationLabels = [
  "Skelettmuskulatur [Skm]",
  "Brätstrukturen [Brät]",
  "Bindegewebe kollagenes  [kBdg]",
  "Bindegewebe elastisches [eBdg]",
  "Fettgewebe [Fett]",
  "Pflanzliches Fett  [pFett]",
  "Gewürze [Gewü]",
  "Gefäße [Gefä]",
  "Drüsengewebe [Drüs]",
  "Haut äußere Schwarten [Schwa]",
  "Lebergewebe [Leber]",
  "Lymphatisches Gewebe [Lymph]",
  "Muskeleiweiß strukturlos (teils granuliert) [Mus]",
  "Brät mit Hülle wiederverarbeitetes [ReMit]",
  "Brät ohne Hülle wiederverarbeitetes [ReOhne]",
  "Gelatineartige fremde Eiweißstrukturen [Gelat]",
  "Stärkepartikel [Stärke]",
  "Haut Geflügel [GHaut]",
  "Speicheldrüse [Speidrü]",
  "Knorpelpartikel [Knorpel]",
  "Knochenpartikel [Knochen]",
  "Pflanzliches Protein [pProt]",
  "Pflanzliche Partikel [pPart]",
  "Muskelabrieb, strukturlose Masse (teils granuliert, auch Vakuolen) [Mgranu]",
  "Muskelabrieb, feinbrätartig (>drei Vakuolen auf einer Stelle) [Mbrät]",
  "Muskelabrieb in Form amorpher Proteinstrukturen [Mamorph]",
  "Deutlich von umgebender Muskulatur abgegrenzte straßenartige Bahnen aus Muskeleiweiß wabig-schaumig vernetzt großporig [MeatIn1]",
  "Deutlich von umgebender Muskulatur abgegrenzte straßenartige Bahnen aus Muskeleiweiß wabig-schaumig vernetzt kleinporig [MeatIn2]",
  "Deutlich von umgebender Muskulatur abgegrenzte straßenartige Bahnen aus Muskeleiweiß in Form von Detritus [MeatIn3]",
  "Strukturen wie durch Transglutaminase entstanden inter-/intramuskulär [Transgl]",
  "Tierisches Fremdprotein Plasmaeiweiß [tProt]",
  "Schilddrüse [SchiDrü]",
  "Skelettmuskulatur, zusammengefügt [SkmZus]",
  "unbekannte Strukturen [US]",
];

class HistoPntCntSideBarTabResults extends Component {
  constructor(props) {
    console.log("This should be Histo Point Counting");
    super(props);
    this.state = {
      isMounted: false,
      dynamicStructureText: "",
      dynamicStructureAbbrText: "",
      // shows if grid from last use uf project exists
      gridExists: this.getGridExists(),
      structuresToAdd: [],
      showLabelsToAdd: false,
    };

    const { project, onSelectFile } = this.props;

    this.store = this.props.persistentStorage;
    this.gridtool = "gridtool";
    this.pointcountingtile = "pointcountingtile";

    this.sortSlides();
    const newProj = this.verifyIfNewProject();
    if (newProj) onSelectFile(project.files[0].id);
  }

  componentDidMount = () => {
    this.setState({ isMounted: true });
    this.props.resultTab.setZoomLevelFixed(false); // Re-enable zoom on customer request
    if (!this.props.tiles.getHistoClassificationStarted()) {
      this.props.onChangeTool(this.gridtool);
    }
  };

  UNSAFE_componentWillMount = () => {
    this.setSelectedSampleFromFilename();
  };

  componentWillUnmount = () => {
    this.props.onChangeTool("none");
  };

  setSelectedSampleFromFilename = () => {
    const { project, resultTab, structures } = this.props;
    // set selected sample according to file name (hint in filename about sample)
    if (project && project.name && !resultTab.getSelSampleSet()) {
      const res = project.name.split("-");
      const sampleAbbr = res[res.length - 2];
      let sampleLabel = "";
      switch (sampleAbbr) {
        case "KP": // Kochpökelware
          sampleLabel = "Kochpökelware";
          break;
        case "BR": // Brühwurst
          sampleLabel = "Brühwurst";
          break;
        case "HA": // Hackfleisch
          sampleLabel = "Hackfleisch";
          break;
        case "KO": // Kochwurst
          sampleLabel = "Kochwurst";
          break;
        case "RO": // Rohwurst
          sampleLabel = "Rohwurst";
          break;
        case "VP": // VegFleischersatz
          sampleLabel = "VegFleischersatz";
          break;
        default:
          break;
      }

      // get index of sample to select
      const idxNewSelectedStructure = structures.findIndex(
        (element) => element.label === sampleLabel
      );

      if (idxNewSelectedStructure >= 0) {
        this.props.onSelectLayer(idxNewSelectedStructure);
        const selId = structures[idxNewSelectedStructure].id;

        // get all parent structures
        const parentStructures = structures.filter(
          (element) => element.subtypeLevel === 0
        );

        // get Index of sample in parent structures
        const parentIdxSelStructure = parentStructures.findIndex(
          (element) => element.id === selId
        );
        resultTab.setSelectedStructure(parentIdxSelStructure);
        this.store.save("selLayerSample", idxNewSelectedStructure);
      }
      resultTab.setSelSampleSet(true);
    }
  };

  getGridExists = () => {
    const { roiLayers, resultTab } = this.props;
    // check if grid was created in one roilayer
    let exists = false;
    roiLayers.forEach((l) => {
      if (l.layer.regionRois.length > 0) {
        exists = true;
      }
    });
    resultTab.setGridExists(exists);
    return exists;
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return direct classification subtypes
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  resetStructures = (changeSample) => {
    const {
      structures,
      roiLayers,
      selectedLayer,
      tools,
      onChangeTool,
      resultTab,
    } = this.props;
    const numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
    const gridSize = Math.sqrt(numberTiles);

    // reset properties for each structure
    structures.forEach((structure) => {
      structure.avgClassFrequency = 0;
      for (const key of Object.keys(structure.classFrequencies)) {
        structure.classFrequencies[key] = 0;
      }
    });
    resultTab.setSelectedRoi(0);
    resultTab.setSelectedChildIndex(1);
    resultTab.setResetPressed(true);
    resultTab.setHundredTiles(false);

    // save selectedRoi in local storage
    this.store.save("selectedRoi", resultTab.getSelectedRoi());

    if (changeSample) {
      // delete roiLayers
      roiLayers.forEach((roiLayer) => {
        roiLayer.layer.regionRois = [];
      });
    } else {
      // make new grid
      // if no grid finish here
      if (numberTiles === 0) {
        return;
      }

      const roiItem = this.setRoiItem(roiLayers, selectedLayer, 0);

      // make new grid
      tools[this.gridtool].createGrid(gridSize, true);
      onChangeTool(this.pointcountingtile);
      tools[this.pointcountingtile].setNextTile(roiItem);
    }
  };

  resetRoiLayers = () => {
    const { allRoiLayers } = this.props;
    // make all roiLayers in all files empty
    for (const value of Object.entries(allRoiLayers)) {
      value[1].forEach((element) => {
        element.layer.regionRois = [];
      });
    }
  };

  sortSlides = () => {
    this.props.project.files.sort((a, b) => {
      if (a.fileName < b.fileName) return -1;
      if (a.fileName > b.fileName) return 1;
      return 0;
    });
  };

  verifyIfNewProject = () => {
    const alreadyOpened = this.store.load("tileIndices");
    return alreadyOpened === undefined;
  };

  onStart = () => {
    const { onChangeTool, roiLayers, selectedLayer, resultTab, tiles } =
      this.props;
    const numberRegionRois = roiLayers[selectedLayer].layer.regionRois.length;
    const gridSize = Math.sqrt(numberRegionRois);

    // reset
    if (tiles.getHistoClassificationStarted()) {
      // initialize gridtool if not initialized yet
      onChangeTool(this.gridtool);
      setTimeout(() => this.resetStructures(), 100);
      // set started to false
      tiles.setHistoClassificationStarted(false);

      return;
    }

    // set corresponding zoom level for grid size
    onChangeTool(this.gridtool);
    // make small delay to initialize grid tool
    setTimeout(() => this.startHistoPointCounting(), 100);

    tiles.setHistoClassificationStarted(true);
    resultTab.setResetPressed(false);
    window.setGridSize(gridSize);
  };

  startHistoPointCounting = () => {
    const { tools, onChangeTool, roiLayers, selectedLayer, resultTab } =
      this.props;
    const numberRegionRois = roiLayers[selectedLayer].layer.regionRois.length;
    const savedRoi = this.store.load("selectedRoi");

    // only create grid if not exists
    if (numberRegionRois === 0) {
      tools[this.gridtool].createGrid(20, true);
    }

    window.setZoomLevelForGridSize(20);

    // get selectedRoi from previous use
    if (savedRoi !== undefined) {
      resultTab.setSelectedRoi(savedRoi);
    } else {
      resultTab.setSelectedRoi(0);
    }

    // make tilesClassificationTool active
    onChangeTool(this.pointcountingtile);
    const roiItem = this.setRoiItem(roiLayers, selectedLayer, 0);
    console.log(tools);
    tools[this.pointcountingtile].setNextTile(roiItem);

    // check if 100 tiles already classified
    const totalCount = this.getTotalCount();
    resultTab.setHundredTiles(totalCount >= 100);
  };

  onPause = () => {
    const belongsToFinishedTab = this.store.load("belongsToFinishedTab");

    if (belongsToFinishedTab === undefined) {
      this.props.onSave();
    } else {
      this.saveToJSON();
    }
  };

  setRoiItem = (roiLayers, selectedLayer, selectedRoi) => {
    return {
      maxX: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.left,
      maxY: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.top,
      minX: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.right,
      minY: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds
        .bottom,
      roi: roiLayers[selectedLayer].layer.regionRois[selectedRoi],
    };
  };

  blockPrevious = () => {
    const { roiLayers, selectedLayer, onChangeTool } = this.props;
    const numberRegionRois = roiLayers[selectedLayer].layer.regionRois.length;

    // if no grid
    if (numberRegionRois === 0) {
      window.showWarningSnackbar("Please create a grid before annotating");
      onChangeTool(this.gridtool);
      return true;
    }

    return false;
  };

  onPrevious = () => {
    if (this.blockPrevious()) return;

    const { tools, roiLayers, selectedLayer, onChangeTool, resultTab } =
      this.props;
    const selectedRoi = resultTab.getSelectedRoi();
    const roiItem = this.setRoiItem(roiLayers, selectedLayer, selectedRoi);

    this.previousHistoPointCounting();
    tools[this.pointcountingtile].setNextTile(roiItem);
    onChangeTool(this.pointcountingtile);

    this.store.save("selectedRoi", resultTab.getSelectedRoi());
  };

  previousHistoClassification = () => {
    const { roiLayers, selectedLayer } = this.props;

    this.checkIfSkipped();
    // if first tile of scene go to previous file last tile
    if (this.props.resultTab.getSelectedRoi() === 0) {
      // change file
      let prevFileId = this.getPrevFileId();
      if (prevFileId) {
        let numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
        this.props.resultTab.setSelectedRoi(numberTiles - 1);
        this.props.resultTab.setSelectedChildIndex(1);
        window.showWarningSnackbar("Please wait, loading previous file ...");
        this.props.onSelectFile(prevFileId);
        this.props.resultTab.setZoomLevelFixed(false);
        this.setClassToZero();
      } else {
        return;
      }
    } else {
      let nextRoi = this.props.resultTab.getSelectedRoi() - 1;
      this.props.resultTab.setSelectedRoi(nextRoi);
      this.props.resultTab.setSelectedChildIndex(1);
      this.setClassToZero();
    }
  };

  previousHistoPointCounting = () => {
    const { resultTab, onSelectFile } = this.props;
    const selectedRoi = resultTab.getSelectedRoi();
    const lastTileIndices = this.store.load("tileIndices");
    let nextRoi;

    // if first tile of scene do nothing
    if (selectedRoi === 0) {
      const prevFileId = this.getPrevFileId();
      if (prevFileId) {
        const roiFile = lastTileIndices ? lastTileIndices[prevFileId] : 0;
        // get tile index from previous file
        resultTab.setSelectedRoi(roiFile);
        resultTab.setSelectedChildIndex(1);
        resultTab.setZoomLevelFixed(false);
        resultTab.setHundredTiles(false);
        window.showWarningSnackbar("Please wait, loading previous file ...");
        onSelectFile(prevFileId);
      } else {
        return;
      }
    } else {
      // if first tile of blue tiles from specification
      if (resultTab.getSelectedRoi() === 21) {
        // set last red tile from specification
        resultTab.setSelectedRoi(378);
        return;
      }

      // check if last tile in row --> then skip next row
      if (selectedRoi % 20 === 0 || selectedRoi % 20 === 1) {
        nextRoi = selectedRoi - 22;
      } else {
        nextRoi = selectedRoi - 2;
      }

      resultTab.setSelectedRoi(nextRoi);
    }
  };

  blockNext = () => {
    const { roiLayers, selectedLayer, onChangeTool, resultTab } = this.props;
    const numberTiles = roiLayers[selectedLayer].layer.regionRois.length;

    // if file is changing no classification is possible for a few seconds
    if (resultTab.getFileChange()) return true;

    // if no grid is initialized yet no classification is possible
    if (numberTiles === 0) {
      window.showWarningSnackbar("Please create a grid before annotating");
      onChangeTool(this.gridtool);
      return true;
    }

    // if last tile in last file stop
    if (this.nextButtonDisabled() && !resultTab.getFileChange()) {
      window.showWarningSnackbar("Finished!");
      return true;
    }

    return false;
  };

  onNext = () => {
    if (this.blockNext()) return;

    const { tools, roiLayers, selectedLayer, onChangeTool, resultTab } =
      this.props;
    const selectedRoi = resultTab.getSelectedRoi();
    const roiItem = this.setRoiItem(roiLayers, selectedLayer, selectedRoi);

    this.nextHistoPointCounting();
    tools[this.pointcountingtile].setNextTile(roiItem);
    onChangeTool(this.pointcountingtile);

    this.store.save("selectedRoi", resultTab.getSelectedRoi());
  };

  checkIfSkipped = () => {
    const { structures, roiLayers, resultTab } = this.props;
    const selectedRoi = resultTab.getSelectedRoi();
    const id_range = this.getRoiLayersIdRange();

    // check if some layer was classified, if so return
    for (let id in id_range) {
      if (
        roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass !==
        0
      ) {
        return;
      }
    }

    // set Class to -1 and reduce counter by one for all Rois
    for (let id in id_range) {
      roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass = -1;
      for (let e in structures) {
        if (structures[e].id === roiLayers[id_range[id]].id) {
          structures[e].classFrequencies.class_0 -= 1;
          break;
        }
      }
    }
  };

  setClassToZero = () => {
    const { structures, roiLayers, resultTab } = this.props;
    const selectedRoi = resultTab.getSelectedRoi();
    const id_range = this.getRoiLayersIdRange();

    // set Class to 0 and increase the according counter
    for (let id in id_range) {
      if (
        roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass ===
        -1
      ) {
        roiLayers[id_range[id]].layer.regionRois[
          selectedRoi
        ].frequencyClass = 0;
        for (let e in structures) {
          if (structures[e].id === roiLayers[id_range[id]].id) {
            structures[e].classFrequencies.class_0 += 1;
            break;
          }
        }
      }
    }
  };

  getRoiLayersIdRange = () => {
    const { structures, roiLayers, selectedLayer } = this.props;
    // get ids of RoiLayers that should be set to zero
    const tmp = structures.filter(
      (element) =>
        element.subtypeLevel === structures[selectedLayer].subtypeLevel + 1 &&
        element.parentId === structures[selectedLayer].id
    );
    let roi_min = 99999;
    let roi_max = -1;

    roiLayers.filter((element, idx) => {
      for (let e in tmp) {
        if (tmp[e].id === element.id) {
          if (roi_min > idx) {
            roi_min = idx;
          }
          if (roi_max < idx) {
            roi_max = idx;
          }
          return true;
        }
      }
      return false;
    });

    // create a range of ids
    let id_range = [...Array(roi_max - roi_min + 1).keys()]; //+roi_min
    for (let e in id_range) {
      id_range[e] += roi_min;
    }

    return id_range;
  };

  nextHistoPointCounting = () => {
    const { roiLayers, selectedLayer, onSelectFile, fileId, resultTab } =
      this.props;
    const numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
    const selectedRoi = resultTab.getSelectedRoi();
    const totalCount = this.getTotalCount();
    let lastTileIndices = this.store.load("tileIndices");
    let indicesObject = {};
    resultTab.setHundredTiles(totalCount >= 100);

    if (
      (resultTab.getHundredTiles() && this.isLastClassifiedTile()) ||
      selectedRoi === numberTiles - 1
    ) {
      const nextFileId = this.getNextFileId();
      if (nextFileId) {
        if (lastTileIndices === undefined) {
          indicesObject[fileId] = selectedRoi;
          this.store.save("tileIndices", indicesObject);
        } else {
          lastTileIndices[fileId] = selectedRoi;
          this.store.save("tileIndices", lastTileIndices);
        }

        window.showWarningSnackbar("Please wait, loading next file ...");
        onSelectFile(nextFileId);
        resultTab.setFileChange(true);
        resultTab.setZoomLevelFixed(false);
        resultTab.setSelectedRoi(0);
        resultTab.setHundredTiles(false);
        return;
      }
    }

    // check if last tile in row --> then skip next row
    let nextRoi;
    if (selectedRoi % 20 === 18 || selectedRoi % 20 === 19) {
      nextRoi = selectedRoi + 22;
    } else {
      nextRoi = selectedRoi + 2;
    }

    // check if nextRoi exists
    if (nextRoi >= numberTiles) {
      // roi does not exist --> start with "blue" tiles from specifications
      nextRoi = 21;
    }

    resultTab.setSelectedRoi(nextRoi);
  };

  isLastClassifiedTile = () => {
    const { structures, roiLayers, selectedLayer, resultTab } = this.props;
    const selectedRoi = resultTab.getSelectedRoi();

    // get id of "leer" child
    let emptyId = 0;
    this.findChilds(structures[selectedLayer]).forEach((child) => {
      if (child.label === "leer [leer]") {
        emptyId = child.id;
      }
    });

    // get all classified tiles
    // if selectedRoi is even --> red tile (see specifications)
    let classifiedTiles;
    if (selectedRoi % 2 === 0) {
      classifiedTiles = roiLayers[selectedLayer].layer.regionRois.filter(
        (roi, idx) =>
          roi.isAnnotated && roi.structureId !== emptyId && idx % 2 === 0
      );
    } else {
      // if selectedRoi is uneven --> blue tile (see specifications)
      classifiedTiles = roiLayers[selectedLayer].layer.regionRois.filter(
        (roi, idx) =>
          roi.isAnnotated && roi.structureId !== emptyId && idx % 2 === 1
      );
    }

    // get index of last classified tile
    const idxLastTile = roiLayers[selectedLayer].layer.regionRois.findIndex(
      (element) => element === classifiedTiles[classifiedTiles.length - 1]
    );

    // check if blue tiles classified
    const blueTiles = roiLayers[selectedLayer].layer.regionRois.filter(
      (roi, idx) =>
        roi.isAnnotated && roi.structureId !== emptyId && idx % 2 === 1
    );

    const blueTilesClassified = blueTiles.length > 0 ? true : false;

    // if selectedRoi has same Index as last classified tile return true
    if (idxLastTile === selectedRoi) {
      if (selectedRoi === 378 && blueTilesClassified) {
        // if last red tile but blue tiles are already classified
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  getNextFileId = () => {
    const { project, fileId } = this.props;
    const inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile + 1]) {
      return project.files[inxCurrentFile + 1].id;
    } else {
      window.showWarningSnackbar("Last file.");
      return null;
    }
  };

  getPrevFileId = () => {
    const { project, fileId } = this.props;
    const inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile - 1]) {
      return project.files[inxCurrentFile - 1].id;
    } else {
      window.showWarningSnackbar("First file.");
      return null;
    }
  };

  saveToJSON = () => {
    this.props.onSave();

    if (this.getTotalCount() < 100) {
      window.showWarningSnackbar("Not 100 classified tiles in each file");
    }

    // make excel table with results
    Backend.setProjectsPending([this.props.project.id], () => {
      console.log("project state changed to pending:", this.props.project.id);
    });
  };

  onEnd = () => {
    this.store.save("belongsToFinishedTab", true);
    this.saveToJSON();

    const { history } = this.props;

    setTimeout(
      function () {
        history.push("/");
      },
      3000,
      history
    );
  };

  getTotalCount = () => {
    const { structures, roiLayers, selectedLayer } = this.props;

    let emptyId = 0;
    this.findChilds(structures[selectedLayer]).forEach((child) => {
      if (child.label === "leer [leer]") {
        emptyId = child.id;
      }
    });

    // get number of rois that are not of class "leer"
    let numberRois = roiLayers[selectedLayer].layer.regionRois.filter(
      (roi) => roi.isAnnotated && roi.structureId !== emptyId
    ).length;

    return numberRois;
  };

  renderGridTool = () => {
    const { tool } = this.props;
    return (
      <div>
        {tool && !tool.noConfig && <Grid>{tool.renderConfiguration()}</Grid>}
      </div>
    );
  };

  addStructure = () => {
    const { projectContext, structures, selectedLayer } = this.props;
    const { dynamicStructureText, dynamicStructureAbbrText } = this.state;

    if (dynamicStructureText === "") {
      window.showWarningSnackbar("Please add label of new structure.");
      return;
    } else if (dynamicStructureAbbrText === "") {
      window.showWarningSnackbar("Please add abbreviation for new structure.");
      return;
    }

    // put label and abbreviation together for label of new structure
    const newSubstructureLabel =
      dynamicStructureText + " [" + dynamicStructureAbbrText + "]";

    // add new subtype
    projectContext.addSubType(
      structures[selectedLayer],
      newSubstructureLabel,
      true
    );

    this.setState({ dynamicStructureText: "", dynamicStructureAbbrText: "" });
  };

  addSelectedStructures = () => {
    this.setState({ structuresToAdd: [], showLabelsToAdd: false });
  };

  getCurrentFileInx = () => {
    const { project, fileId } = this.props;
    return project.files.findIndex((element) => element.id === fileId) + 1;
  };

  startOrContinueText = () => {
    if (!this.props.tiles.getHistoClassificationStarted()) {
      return "RUN";
    } else {
      return "RESET CURRENT FILE";
    }
  };

  nextOrSkipText = () => {
    if (!this.getGridExists()) {
      return "NEXT";
    }
    const { roiLayers, resultTab } = this.props;
    const selectedRoi = resultTab.getSelectedRoi();

    const id_range = this.getRoiLayersIdRange();

    // set label to "NEXT" if Roi has classifications
    for (let id in id_range) {
      if (roiLayers[id_range[id]].layer.regionRois[selectedRoi] === undefined) {
        return "NEXT";
      }
      if (
        roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass !==
        0
      ) {
        return "NEXT";
      }
    }
    return "SKIP TILE";
  };

  isLastFile = () => {
    const { project, fileId } = this.props;
    const inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (inxCurrentFile === project.files.length - 1) {
      return true;
    } else {
      return false;
    }
  };

  nextButtonDisabled = () => {
    const { roiLayers, selectedLayer, resultTab, tiles } = this.props;

    if (resultTab.getFileChange()) return true;

    let disabled;
    if (tiles.getHistoClassificationStarted()) {
      disabled = false;
    } else {
      disabled = true;
    }

    const selectedRoi = resultTab.getSelectedRoi();
    const numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
    if (!disabled) {
      // last file and last tile or last file and 100 annotated
      const lastTileCounting = selectedRoi === numberTiles - 1 ? true : false;
      if (
        (this.isLastFile() && lastTileCounting) ||
        (this.isLastFile() &&
          resultTab.getHundredTiles() &&
          this.isLastClassifiedTile())
      ) {
        return true;
      }
    }

    return disabled;
  };

  getAbbreviationOfStructure = (label) => {
    let labelWithBracket = label.split("[")[1];
    if (!labelWithBracket) {
      labelWithBracket = label;
    }
    const abbreviation = labelWithBracket.substring(
      0,
      labelWithBracket.length - 1
    );
    return abbreviation;
  };

  getOtherLabels = () => {
    const { structures, selectedLayer } = this.props;

    // get all childs of selected sample
    const sampleChilds = structures.filter(
      (structure) => structure.parentId === structures[selectedLayer].id
    );

    // get all labels sampleChilds
    let sampleChildsLabels = [];
    sampleChilds.forEach((child) => {
      sampleChildsLabels.push(child.label);
    });

    // get all labels of histoclassificationlabels that are not in samplechilds
    let newLabels = [];
    HistoClassififcationLabels.forEach((histoLabel) => {
      if (!sampleChildsLabels.includes(histoLabel)) {
        newLabels.push(histoLabel);
      }
    });

    return newLabels;
  };

  handleChangeNewStructures = (e, label) => {
    // check if add ort remove structure
    let updatedStructures = this.state.structuresToAdd;
    if (e.target.checked) {
      // add structure
      updatedStructures.push(label);
    } else {
      // remove structure
      // find index of label and remove it
      const idx = updatedStructures.findIndex((labelStr) => labelStr === label);
      updatedStructures.splice(idx, 1);
    }

    this.setState({ structuresToAdd: updatedStructures });
  };

  render() {
    const { classes, visible, ...propsWithoutClasses } = this.props;

    if (!visible) return null;

    let numberTilesFile =
      this.props.roiLayers[this.props.selectedLayer].layer.regionRois.length;

    let showAddSubstructure = !this.props.tiles.getHistoClassificationStarted();
    return (
      <Grid
        style={{ overflowX: "hidden", overflowY: "auto" }}
        container
        className={classes.flexRowRemainingHeight}
      >
        <Grid className={classes.spacing} item xs={12}>
          <Typography
            variant="h6"
            style={{ fontSize: "18px", marginBottom: "20px" }}
          >
            File {this.getCurrentFileInx()} / {this.props.project.files.length}
          </Typography>
          <ScoringMatrixHistoPointCounting
            selectedRoi={this.props.resultTab.getSelectedRoi()}
            onNext={this.onNext}
            resetStructures={this.resetStructures}
            zoomLevelFixed={this.props.resultTab.getZoomLevelFixed()}
            project={this.props.project}
            {...propsWithoutClasses}
          ></ScoringMatrixHistoPointCounting>
          {showAddSubstructure && (
            <ListItem
              style={{
                paddingBottom: 0,
                paddingTop: 15,
                paddingLeft: 0,
              }}
              key={1111}
            >
              <TextField
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                  paddingRight: 5,
                  width: 180,
                }}
                placeholder="Label"
                label="Label"
                value={this.state.dynamicStructureText}
                onChange={(e) =>
                  this.setState({
                    dynamicStructureText: e.target.value,
                  })
                }
              />
              <TextField
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  paddingLeft: 0,
                  width: 120,
                }}
                placeholder="Abbreviation"
                label="Abbreviation"
                value={this.state.dynamicStructureAbbrText}
                onChange={(e) =>
                  this.setState({
                    dynamicStructureAbbrText: e.target.value,
                  })
                }
              />
              <Tooltip
                disableInteractive
                title="Add new structure (label [abbreviation])"
              >
                <IconButton onClick={this.addStructure} size="large">
                  <Add />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item xs={4}>
              {this.props.resultTab.getRendererInitialized() &&
                this.props.histogramConfig && (
                  <MiniMap
                    inSideBar={true}
                    ome={this.props.ome}
                    histogramConfig={
                      this.props.histogramConfig[this.props.fileId]
                    }
                    visibleImage={this.props.tiles.getVisibleImages()}
                    coloredImages={this.props.tiles.getColoredImages()}
                    position={this.props.resultTab.getPosition()}
                    zoom={this.props.resultTab.getScale()}
                    canvas={this.props.resultTab.getRendererCanvas()}
                    getPageForChannel={(c) => c}
                    fileId={this.props.fileId}
                  />
                )}
            </Grid>
            <Grid item xs={8}>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      disabled={this.nextButtonDisabled()}
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onNext()}
                    >
                      {this.nextOrSkipText()}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      disabled={
                        this.props.tiles.getHistoClassificationStarted()
                          ? false
                          : true
                      }
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onPrevious()}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs style={{ marginTop: "11px" }}>
                    <Typography variant="body1" style={{ textAlign: "center" }}>
                      Tile {this.props.resultTab.getSelectedRoi() + 1} /{" "}
                      {numberTilesFile}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onStart()}
                    >
                      {this.startOrContinueText()}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onPause()}
                    >
                      Pause
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onEnd()}
                    >
                      End
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

// define the component's interface
HistoPntCntSideBarTabResults.propTypes = {
  classes: PropTypes.object.isRequired,
  // sidebar tab properties
  sideBarWidth: PropTypes.number,
  visible: PropTypes.bool,
  // general properties
  id: PropTypes.string.isRequired,
  viewerConfig: PropTypes.object,
  // historgram
  ome: PropTypes.object,
  histogramConfig: PropTypes.object,
  onChangeChannels: PropTypes.func,
  // file management
  onSelectFile: PropTypes.func,
  onExcludeFilesToggle: PropTypes.func,
  //not ordered
  project: PropTypes.object,
  resultTab: PropTypes.object,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  onSelectLayer: PropTypes.func,
  persistentStorage: PropTypes.object,
  selectedLayer: PropTypes.number,
  tools: PropTypes.array,
  onChangeTool: PropTypes.func,
  tiles: PropTypes.object,
  allRoiLayers: PropTypes.object,
  fileId: PropTypes.string,
  onSave: PropTypes.func,
  history: PropTypes.object,
  tool: PropTypes.object,
  projectContext: PropTypes.object,
};

export default withRouter(
  withAllViewerContexts(
    withTiles(withResultTab(withStyles(styles)(HistoPntCntSideBarTabResults)))
  )
);
