// Copyright HS-Analysis GmbH, 2019

// Framework imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// External imports
import RBush from "rbush";

// Material UI imports
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";

// HSA imports
import { ChannelsConfig } from "./components/Histogram";
import { debounce } from "../common/utils/Utils";
import { RegionROI, CommentROI, createRoisFromAnno } from "./utils/ROI";
import {
  updateLayer,
  calcBoundingBox,
  createRegionRoi,
} from "./utils/PolygonUtil";
import { withAllViewerContexts } from "./contexts/AllViewerContexts";
import { withResultTab } from "./contexts/ResultTabContext";
import { withTiles } from "./contexts/TilesContext";
import ActiveLearningTool from "./components/tools/ActiveLearningTool";
import AITrainingTool from "./components/tools/AITrainingTool";
import Backend from "../common/utils/Backend";
import ClassroomChat from "./components/ClassroomChat";
import CommentTool from "./components/tools/CommentTool";
import CopyTool from "./components/tools/CopyTool";
import EllipseTool from "./components/tools/EllipseTool";
import FillTool from "./components/tools/FillTool";
import FilterAnnotationsTool from "./components/tools/FilterAnnotationsTool";
import Gallery from "./components/Gallery";
import GridAnnotationTool from "./components/tools/GridAnnotationTool";
import GridTool from "./components/tools/GridTool";
import HeatmapTool from "./components/tools/HeatmapTool";
import InstantAnalysisTool from "./components/tools/InstantAnalysisTool";
import LandmarkTool from "./components/tools/LandmarkTool";
import MagicWandTool from "./components/tools/MagicWandTool";
import OverlaySlider from "./components/OverlaySlider";
import PenTool from "./components/tools/PenTool";
import PlotNearestRoiTool from "./components/tools/PlotNearestRoiTool";
import RectangleTool from "./components/tools/RectangleTool";
import RegionGrabCutTool from "./components/tools/RegionGrabCutTool";
import RegionGrowingTool from "./components/tools/RegionGrowingTool";
import RegionTool from "./components/tools/RegionTool";
import Renderer from "./components/Renderer";
import SelectionTool from "./components/tools/SelectionTool";
import SideBar from "./components/SideBar";
import TilesClassificationTool from "./components/tools/TilesClassificationTool";
import TilesHistoPointCountingTool from "./components/tools/TilesHistoPointCountingTool";
import VerticalToolBar, { Tools } from "./components/VerticalToolBar";
import Viewer3D from "./components/3D-Viewer";
import WindowTool from "./components/WindowTool";
import FindFilesDialog, {
  ProjectActionMode,
} from "../home/dialogs/FindFilesDialog";

const styles = {
  root: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "calc(100% - 64px)",
    background: "#EBEBEB",
  },
  mainContainer: {
    flexGrow: 0,
    margin: 0,
  },
  excludeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 100,
  },
  attachButton: {
    position: "absolute",
    top: 5,
    right: 40,
    zIndex: 100,
    color: "#666",
  },
  dropfieldRoot: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.514)",
    color: "rgb(255, 255, 255)",
    zIndex: 100,
    pointerEvents: "none",
    border: "dashed white 4px",
    textAlign: "center",
    paddingTop: "calc(50vh - 74px)",
    fontSize: "20px",
  },
  activeIndicatorBorder: {
    position: "absolute",
    pointerEvents: "none",
    zIndex: 100,
    height: "100%",
    width: "100%",
    border: "solid #0673C1 4px",
  },
};

class Viewer extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    let sideBarWidthObject = this.props.persistentStorage.load("sideBarWidth");
    let timeLineHeightObject =
      this.props.persistentStorage.load("timeLineHeight");

    this.state = {
      minSideBarWidth: 455,
      minTimeLineHeight: 90,
      minRendererWidth: 600,
      minCanvasHeight: 100,
      sideBarWidth: sideBarWidthObject ? sideBarWidthObject : 455,
      timeLineHeight: timeLineHeightObject ? timeLineHeightObject : 170,
      classRoomChatWidth: 0,
      verticalToolBarWidth: 45,
      verticalToolBarIconsHeight: 0,
      project: null,
      user: null,
      //fileId: 0,
      viewerConfig: null,
      ome: null,
      omeDict: {},
      splitscreenFileIds: [],
      fullscreenFileIds: [],
      showSplitscreenDropzone: false,
      activeFileId: 0,
      importProgress: -1,
      //
      t: 0,
      c: 0,
      z: 0,
      minZ: 0,
      maxZ: 0,
      rois: [],
      selRoi: null,
      histogramConfig: {},
      activeTool: Tools.NONE,
      showGallery: false,
      show3DViewer: false,
      showPointCloud: false,
      showTilesGallery: false,
      updateStructure: true,
      showTimeBar: false,
      displayTimeBar: false,
      showZStackBar: false,
      showFileTreeView: true,
      commentLayers: {},
      landmarkLayers: {},
      drawLayer: {
        regionRois: [],
        inverted: false,
        clear: false,
      },
      containerKey: 0,
      opacity: 1.2,
      hC: null,

      zoomRoi: false,
      zoomLeft: 0,
      zoomRight: 0,
      zoomTop: 0,
      zoomBottom: 0,
      scrollGallery: "auto",
      alruns: false,
      exportSubstructures: false,
      formDataAICockpit: null,
      dimensionsUpdated: false,
      gridSize: null,
      annotatedFileIds: [],
      showFullscreen: false,
      showOverlay: false,
      showWindowTool: false,
      firstFullscreenOpacity: 100,
      secFullscreenOpacity: 0,
      fullscreenChain: true,
      loadedAnnotationsCounter: 0,
      frameArrayDict: {},
      showFirstAccordion: true,
      aiUsedStructures: [],
      refreshState: false,
      preparingStartJob: false,
      annotationsAreReduced: false,
    };
    this.mousePosition = {
      mouseX: 0,
      mouseY: 0,
    };
    // frontend cached images
    this.visibleImage = [];
    // necessary splitscreen vars
    this.rendererdict = {};
    this.chainList = {};
    this.chainListFileIds = {};
    this.zoomObjectDict = {};
    this.zoomObject = {
      zoomRoi: false,
      zoomBottom: 0,
      zoomTop: 0,
      zoomRight: 0,
      zoomLeft: 0,
    };

    // apply model after training
    this.applyModelAfterTraining = false;

    // check if automatic training
    this.automaticTraining = false;

    // selected objects for active learning
    this.selObjects = {
      coordinates: [],
    };

    this.selObjs = false;
    this.updateCount = 0;
    this.graphData = 0;
    this.changingFile = false;

    // initialize tools
    this.tools = [];
    this.tools[Tools.PEN_ROI] = new PenTool();
    this.tools[Tools.MAGICWAND_ROI] = new MagicWandTool();
    this.tools[Tools.REGIONGROWING_ROI] = new RegionGrowingTool();
    this.tools[Tools.REGIONGRABCUT_ROI] = new RegionGrabCutTool();
    this.tools[Tools.REGION_ROI] = new RegionTool();
    this.tools[Tools.RECTANGLE_ROI] = new RectangleTool();
    this.tools[Tools.COMMENT_ROI] = new CommentTool();
    this.tools[Tools.ELLIPSE_ROI] = new EllipseTool();
    this.tools[Tools.FILL] = new FillTool();
    this.tools[Tools.COPY_ROI] = new CopyTool();
    this.tools[Tools.FILL] = new FillTool();
    this.tools[Tools.SELECTION_ROI] = new SelectionTool();
    this.tools[Tools.FILTERANNOTATIONS_ROI] = new FilterAnnotationsTool();
    this.tools[Tools.PLOTNEAREST_ROI] = new PlotNearestRoiTool();
    this.tools[Tools.AL] = new ActiveLearningTool();
    this.tools[Tools.AITRAINING] = new AITrainingTool(this);
    this.tools[Tools.GRIDTOOL] = new GridTool();
    this.tools[Tools.GRIDANNOTATIONTOOL] = new GridAnnotationTool();
    this.tools[Tools.SELECTION_Tile] = new TilesClassificationTool();
    this.tools[Tools.POINTCOUNTING_Tile] = new TilesHistoPointCountingTool();
    this.tools[Tools.LANDMARK] = new LandmarkTool();
    this.tools[Tools.HEATMAP] = new HeatmapTool();

    window.sharedToolParameters = {};

    //initialize roi specific tools
    this.structureTools = [];

    // new indices of Layers (for old projects)
    this.newLayerIndices = [];

    this.setHistogramConfig = this.setHistogramConfig.bind(this);
    this.createZoomObjectForRect = this.createZoomObjectForRect.bind(this);
    this.resetRoiZoom = this.resetRoiZoom.bind(this);

    this.saveChangesGallery = this.saveChangesGallery.bind(this);
    this.applyDL = this.applyDL.bind(this);
    this.setAlruns = this.setAlruns.bind(this);

    this.updateViewer = this.updateViewer.bind(this);

    this.galleryChangeTool = this.galleryChangeTool.bind(this);
    this.onGallerychangePage = this.onGallerychangePage.bind(this);

    this.resetRoiZoomInDict = this.resetRoiZoomInDict.bind(this);

    window.getMousePosition = this.getMousePosition;
    window.zoomToRect = this.zoomToRect;
    window.moveToRect = this.moveToRect;

    window.trainingProgress = this.trainingProgress;
    window.galleryTrainingProgress = this.galleryTrainingProgress;
    window.toggleClassRoomChat = this.toggleClassRoomChat;
    window.setGridSize = this.setGridSize;
    window.onSelectFile = this.onSelectFile;

    document.onmousedown = () => {
      if (this._isMounted) {
        this.forceUpdate();
      }
    };

    document.onmouseup = () => {
      if (this._isMounted) {
        this.forceUpdate();
      }
    };

    document.onmousemove = (e) => {
      this.mousePosition = {
        mouseX: e.pageX,
        mouseY: e.pageY,
      };
    };

    // document.onwheel = () => {
    //   // TODO: [HWB-911] Find solution that only updates on first load of renderer
    //   const { showGallery, show3DViewer, showTilesGallery } = this.state;
    //   if (!showGallery && !show3DViewer && !showTilesGallery) {
    //     this.forceUpdate();
    //   }
    // };

    // initialize ProjectContext with Viewer Component Parameter
    this.props.projectContext.init(this);

    Backend.requestProject(
      this.props.id,
      (res) => {
        if (!res.newMappingsFound && res.allUniquelyMapped) {
          this.loadProject();
        } else {
          this.FindMissingFiles.show(res, () => {
            this.loadProject();
          });
        }
      },
      (error) => window.openErrorDialog(error)
    );
  }

  loadProject = () => {
    Backend.loadProject(
      {
        id: this.props.id,
      },
      (project) => {
        if (project.projectData) {
          if (project.files.length > project.projectData.files.length) {
            project.projectData.files = project.files.map((file) => {
              file.annotations = [];
              return file;
            });
          }
          // Attempt to load project data from saved json.
          const idMapping = project.files.reduce((map, file) => {
            map[file.fileName + file.scene] = file.id;
            return map;
          }, {});

          project.projectData.files = project.projectData.files.map((file) => {
            const fileId = idMapping[file.fileName + file.scene];
            if (!fileId) {
              console.error(
                "Error loading files: Id mapping failed, was the path to the slides changed?"
              );
            }
            return { ...file, id: fileId };
          });

          project.projectData.files = project.projectData.files.map((file) => {
            const fileId = idMapping[file.fileName + file.scene];
            if (!fileId) {
              console.error(
                "Error loading files: Id mapping failed, was the path to the slides changed?"
              );
            }
            return { ...file, id: fileId };
          });

          this.setMountedState({
            formDataAICockpit: JSON.parse(
              project.projectData.formDataAICockpit
            ),
          });
        } else {
          for (let structure of project.viewerConfig.project.structures) {
            if (structure.toolPresets) {
              for (const toolName of Object.keys(structure.toolPresets)) {
                const tool = structure.tools.find(
                  (tool) => tool.name === toolName
                );
                if (tool) {
                  for (const [paramName, value] of Object.entries(
                    structure.toolPresets[toolName]
                  )) {
                    const parameter = tool.parameters.find(
                      (parameter) => parameter.name === paramName
                    );
                    if (parameter) {
                      parameter.ui.default = value.default;
                      if (parameter.ui.default[0] === "[") {
                        // if e.g. range slider
                        parameter.ui.default = JSON.parse(parameter.ui.default);
                      } else if (parameter.type === "int") {
                        parameter.ui.default = parseInt(
                          parameter.ui.default,
                          10
                        );
                      } else if (parameter.type === "float") {
                        parameter.ui.default = parseFloat(parameter.ui.default);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // display project name in navigationBar
        window.setNavigationbarTitle(
          `${project.name} (${project.viewerConfig.project.label})`
        );
        let commentLayersObject = {};
        let landmarkLayersObject = {};
        let frameArrayDict = {};

        for (let file of project.files) {
          frameArrayDict[file.id] = {};
          commentLayersObject[file.id] = { commentRois: [] };
          landmarkLayersObject[file.id] = { landmarkRois: [] };

          if (!(file.id in this.rendererdict)) this.rendererdict[file.id] = {};
          if (project.projectData) {
            if ("tMatrices" in project.projectData) {
              if (project.projectData.tMatrices !== null) {
                if (file.id in project.projectData.tMatrices) {
                  this.props.tiles.setTransformationMatnFactnOff(
                    project.projectData.tMatrices[file.id],
                    file.id,
                    project.projectData.tFactors[file.id],
                    project.projectData.tOffsets[file.id]
                  );
                }
              }
            }
          }
        }

        if (project.projectData) {
          let projectDataFiles = project.projectData.files;
          for (let j = 0; j < project.files.length; j++) {
            frameArrayDict[projectDataFiles[j].id] = {};
            // in case of old projects => create empty commentLayersObject
            if (!commentLayersObject[projectDataFiles[j].id]) {
              commentLayersObject[projectDataFiles[j].id] = { commentRois: [] };
            }

            if (!landmarkLayersObject[projectDataFiles[j].id]) {
              landmarkLayersObject[projectDataFiles[j].id] = {
                landmarkRois: [],
              };
            }

            if (projectDataFiles[j].commentRois) {
              commentLayersObject[projectDataFiles[j].id].commentRois =
                projectDataFiles[j].commentRois.map((e) => {
                  return new CommentROI(
                    e.regions,
                    e.color,
                    e.type,
                    e.commentValue,
                    e.computedValue
                  );
                });
            }
            if (projectDataFiles[j].landmarkRois) {
              landmarkLayersObject[projectDataFiles[j].id].landmarkRois =
                projectDataFiles[j].landmarkRois.map((e) => {
                  return new CommentROI(
                    e.regions,
                    e.color,
                    e.type,
                    e.commentValue,
                    e.computedValue
                  );
                });
            }
            const fileIdx = j;
            let dataFileIdx = projectDataFiles.findIndex((file) => {
              return file.id === project.files[fileIdx].id;
            });
            if (dataFileIdx === -1) {
              console.info(
                "saved file and project file do not match, comparing by name and scene"
              );
              dataFileIdx = projectDataFiles.findIndex((file) => {
                return (
                  file.fileName === project.files[fileIdx].fileName &&
                  file.scene === project.files[fileIdx].scene
                );
              });
            }
            if (dataFileIdx > -1) {
              const projectDataFile = projectDataFiles[dataFileIdx];
              const projectFile = project.files[fileIdx];
              projectFile.excludeScene = projectDataFile.excludeScene;
              projectFile.fullyAnnotated = projectDataFile.fullyAnnotated;
              projectFile.annotated = projectDataFile.annotated === true;
              projectFile.saved = projectDataFile.saved;
              projectFile.classId = projectDataFile.classId;
            } else {
              // this should not happen
              console.log(
                "file data could not be matched with saved data for file:",
                project.files[fileIdx].fileName,
                project.files[fileIdx].scene
              );
            }
          }
        }

        Backend.getCurrentUser((user) => {
          this.setMountedState({ user: user.fullName });
          this.props.projectContext.setState({ user: user.fullName });
        });

        this.migrateTools(project);

        this.setMountedState({
          project,
          frameArrayDict: frameArrayDict,
          commentLayers: commentLayersObject,
          landmarkLayers: landmarkLayersObject,
        });

        // ensuring that old files (old structure config) also work
        // check if new structures added
        let newStructures =
          project.projectData &&
          project.viewerConfig.project.structures.length >
            project.projectData.structures.length;

        // if newStructures make project compatible
        if (newStructures) {
          // this.makeProjectCompatible(project); // needs to be fixed
        }

        this.initLayersAndStructures();

        let openedFileId = this.props.persistentStorage.load("opened_file_id");
        openedFileId = openedFileId ? openedFileId : project.files[0].id;
        //Set correct aFileID
        if (this.props.persistentStorage.load("ActiveFileId")) {
          this.setMountedState({
            activeFileId: this.props.persistentStorage.load("ActiveFileId"),
          });
        } else {
          this.setMountedState({ activeFileId: openedFileId });
        }
        //Set active FileID as first splitscreenFileID - needed if new project
        if (!this.props.persistentStorage.load("SplitscreenFileIds")) {
          let sFileIds = this.state.splitscreenFileIds;
          sFileIds.push(this.state.activeFileId);
          this.setMountedState({
            splitscreenFileIds: sFileIds,
          });
        }

        let fsFileIds = this.state.fullscreenFileIds;
        fsFileIds.push(this.state.activeFileId);
        this.setMountedState({
          fullscreenFileIds: fsFileIds,
        });

        if (this.state.splitscreenFileIds) {
          if (
            !this.state.splitscreenFileIds.includes(this.state.activeFileId)
          ) {
            // make sure that activeFileId is in SplitscreenFileIds
            let sFileIds = this.state.splitscreenFileIds;
            sFileIds.push(this.state.activeFileId);
            this.setMountedState({
              splitscreenFileIds: sFileIds,
            });
          }
          let zoom = null;
          let newZoomObjectDict = null;
          for (const value of Object.values(this.state.splitscreenFileIds)) {
            //load file with needed information
            this.openFile(value, this.props.id, false);
            //load zoomstate
            zoom = this.props.persistentStorage.load("zoomObject" + value);

            newZoomObjectDict = this.zoomObjectDict;
            if (zoom) {
              newZoomObjectDict[value] = JSON.parse(JSON.stringify(zoom));
            } else {
              newZoomObjectDict[value] = {
                zoomRoi: false,
                zoomLeft: 0,
                zoomRight: 0,
                zoomTop: 0,
                zoomBottom: 0,
              };
            }
          }
          this.zoomObjectDict = newZoomObjectDict;
        } else {
          //this.props.tiles.setFileId(openedFileId); //not needed anymore?
          this.openFile(openedFileId, this.props.id);
        }

        var groupBy = function (xs, key) {
          return xs.reduce(function (rv, x) {
            // Making sure not to bunch virtual slides into eachother.
            if (x[key].endsWith("empty_file.png")) {
              (rv[x["fileName"]] = rv[x[key]] || []).push(x);
              return rv;
            }
            // Normal sorting I don't understand, but it works.
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };

        const fileGroups = groupBy(project.files, "sourcePath");
        const fileNames = Object.keys(fileGroups).sort();
        const sortedFiles = [];
        for (let fileName of fileNames) {
          sortedFiles.push(...fileGroups[fileName]);
        }
        project.files = sortedFiles;

        //this.props.tiles.setFileId(openedFileId); //not needed anymore?
        this.setMountedState({
          showGallery:
            this.state.viewerConfig.project.projectStringProperties[
              "ViewerType"
            ] == "FilesGallery",
        });
        this.setSideBarWidth();
        this.props.projectHistory.init(this);
        this.props.projectContext.setState({
          project: project,
          fileGroups: fileGroups,
        });
      }
    );
  };

  setSelLayerConfig = () => {
    // set selected layer defined in config
    let idx = this.props.projectContext.structures.findIndex(
      (element) => element.defaultSelected
    );
    if (idx !== -1) {
      this.setSelectedLayer(idx);
    }
  };

  //take tool structure from viewerconfig and set saved tool parameters if same type
  migrateTools = (project) => {
    if (project.projectData) {
      for (let [
        structureIndex,
        structure,
      ] of project.projectData.structures.entries()) {
        for (let [toolIndex, tool] of structure.tools.entries()) {
          let viewerConfigTool = project.viewerConfig.tools.find(
            (vTool) => vTool.name === tool.name
          );
          if (
            typeof viewerConfigTool === "undefined" &&
            project.viewerConfig.project.structures[structureIndex]
          ) {
            //if tool was changed in project config
            tool =
              project.viewerConfig.project.structures[structureIndex].tools[
                toolIndex
              ];
            if (tool) {
              viewerConfigTool = project.viewerConfig.tools.find(
                (vTool) => vTool.name === tool.name
              );
            }
          }
          if (viewerConfigTool) {
            let transformedVParameters = this.generateToolParameterDict(
              viewerConfigTool.parameters
            );
            for (const [key, value] of Object.entries(transformedVParameters)) {
              if (key in tool.parameters) {
                if (typeof tool.parameters[key] === typeof value) {
                  transformedVParameters[key] = tool.parameters[key];
                }
              }
            }
            tool.parameters = transformedVParameters;
          }
        }
      }
    }
  };

  makeProjectCompatible = (project) => {
    // does not work in all cases --> needs to get fixed
    window.showWarningSnackbar(
      "Please wait. Project was created with old version. Adjusting project for new version ..."
    );

    let sructuresWithNoId = [];
    if (project.projectData) {
      sructuresWithNoId = project.projectData.structures.filter(
        (element) => element.id !== 0
      );
    }

    if (sructuresWithNoId.length === 0 && project.projectData) {
      project.projectData.structures.forEach((projDataStr) => {
        let idx = project.viewerConfig.project.structures.findIndex(
          (viewerConfStr) => viewerConfStr.label === projDataStr.label
        );
        // get indices of old structures to adjust roiLayers (annotations)
        if (!this.newLayerIndices.includes(idx)) {
          this.newLayerIndices.push(idx);
        }
      });
      project.projectData.structures = project.viewerConfig.project.structures;
    }

    // load all files and change id of annotations to new position
    this.loadAllFiles();
  };

  loadAllFiles = () => {
    const { project } = this.state;

    let allRoiLayers = {};
    for (let file of this.state.project.files) {
      allRoiLayers[file.id] = [];
      if (project.viewerConfig.project.structures.length > 0) {
        allRoiLayers[file.id] = project.viewerConfig.project.structures.map(
          () => {
            return {
              layer: {
                regionRois: [],
                inverted: false,
              },
              tree: new RBush(),
            };
          }
        );
      }
    }

    let newProjectObject = project;
    let newRoiLayers = [];
    let files = project.files;
    let projId = project.id;
    if (newProjectObject.projectData !== null) {
      let i = 0;
      let fileAnnotationsLoaded = 0;
      let loadAnnotationsFunction = () => {
        newRoiLayers = allRoiLayers;
        // if all annotations of files loaded save annotations with adjusted id
        if (fileAnnotationsLoaded === newProjectObject.files.length - 1) {
          this.createAnnotationsProjectModel(newRoiLayers);
        }
        fileAnnotationsLoaded = fileAnnotationsLoaded + 1;
      };
      for (let file of newProjectObject.files) {
        this.loadAnnotations(
          file,
          files[i].id,
          projId,
          allRoiLayers,
          loadAnnotationsFunction
        );
        i = i + 1;
      }
    }
  };

  countVisibleStructures = () => {
    return this.state.project.viewerConfig.project.structures.reduce(
      (acc, cur) => acc + (cur.visible ? 1 : 0),
      0
    );
  };

  createAnnotationsProjectModel = (newRoiLayers) => {
    const { project } = this.state;

    const projectModel = {
      id: project.id,
      files: project.files.map((e) => ({
        id: e.id,
        annotations: newRoiLayers[e.id].reduce((acc, e, i) => {
          if (e.layer.regionRois.length > 0) {
            acc.push({
              id: i,
              geoJSON: {
                type: "MultiPolygon",
                coordinates: e.layer.regionRois.map((c) => c.regions),
                withHoles: true,
                color1: e.layer.regionRois.map((c) => [c.color]),
                subtype: e.layer.regionRois.map((c) => [c.isSubtype]),
                name: e.layer.regionRois.map((c) => [c.subtypeName]),
                aiAnnotated: e.layer.regionRois.map((c) => [c.aiAnnotated]),
                isAnnotated: e.layer.regionRois.map((c) => [c.isAnnotated]),
                isLabeled: e.layer.regionRois.map((c) => [c.isLabeled]),
                isSelObj: e.layer.regionRois.map((c) => [c.isSelObj]),
                isSaved: e.layer.regionRois.map((c) => [c.isSaved]),
                z: e.layer.regionRois.map((c) => [c.z]),
                comment: e.layer.regionRois.map((c) => [c.comment]),
                tileName: e.layer.regionRois.map((c) => [c.tileName]),
                structureId: e.layer.regionRois.map((c) => [c.structureId]),
                isObject: e.layer.regionRois.map((c) => [c.isObject]),
                frequencyClass: e.layer.regionRois.map((c) => [
                  c.frequencyClass,
                ]),
              },
            });
          }
          return acc;
        }, []),
      })),
      onlyAnnotations: true,
    };

    // save only annotations of project with adjusted id
    this.saveWithSpinloader(projectModel);
  };

  setAIFormData = (formDataAICockpit) => {
    const { project } = this.state;
    this.setMountedState({ formDataAICockpit: formDataAICockpit });
    // start job if defined in project config
    if (
      "AutoSaveStartJob" in project.viewerConfig.project.projectProperties &&
      project.viewerConfig.project.projectProperties["AutoSaveStartJob"] &&
      !this.state.preparingStartJob &&
      this.state.project.state === "ready"
    ) {
      this.setState({ preparingStartJob: true });
      this.saveFunction();
      Backend.setProjectsPending([this.state.project.id], () => {
        console.log("project state changed to pending:", this.state.project.id);
      });

      // return to Home Screen
      let { history } = this.props;
      setTimeout(
        function () {
          history.push("/");
        },
        3000,
        history
      );
    }
  };

  alterStructure = (c) => {
    let project = this.state.project;
    // load svg's text into blob so we can access it
    for (let tool of project.viewerConfig.tools) {
      if (!this.tools[tool.name]) {
        this.tools[tool.name] = new InstantAnalysisTool(tool, this);
      }
    }
    // load viewer configuration
    let allToolNames = Object.values(project.viewerConfig.tools).map(
      (v) => v.name
    );
    let toolParameters = [];
    for (let t of c.tools) {
      if (!window.sharedToolParameters[t.name]) {
        window.sharedToolParameters[t.name] = this.generateToolParameterDict(
          t.parameters
        );
      }
      if (!toolParameters[t.name]) {
        if (t.shared) {
          toolParameters[t.name] = window.sharedToolParameters[t.name];
        } else {
          toolParameters[t.name] = this.generateToolParameterDict(t.parameters);
        }
      }
    }

    return Object.assign(c, {
      isSubtype: c.parentId > 0 ? true : false,
      hasChild: this.structureHasChild(c.id),
      subtypeLevel: this.findStructureSubtypeLevel(c.parentId),
      tools: c.tools.map((t) => ({
        name: t.name,
        parameters: toolParameters[t.name],
      })),
      classFrequencies: {
        class_0: 0,
        class_1: 0,
        class_2: 0,
        class_3: 0,
        class_4: 0,
        class_5: 0,
        class_6: 0,
      },
      allToolNames: allToolNames,
      visible: true,
      isUnfolded: c.parentId > 0 ? false : true,
      showSubtypes: false,
      addSubtypeText: "",
    });
  };

  updateGlobalZ = (z, minZ, maxZ) => {
    this.setMountedState({ z, minZ, maxZ });
  };

  updateGlobalT = (t) => {
    this.setMountedState({ t: t });
  };

  structureHasChild = (id) => {
    // check if structure (id) has child --> check if other structure has parentId of structure
    let hasChild = false;
    this.state.project.viewerConfig.project.structures.forEach((element) => {
      if (element.parentId === id) {
        hasChild = true;
      }
    });
    return hasChild;
  };

  findStructureSubtypeLevel = (parentId, subtypeLevelCount = 0) => {
    // return subtype level of structure --> calculate number of parent structures recursively
    let parentIndex = this.getParentIndex(parentId);
    if (parentIndex !== -1) {
      return this.findStructureSubtypeLevel(
        this.state.project.viewerConfig.project.structures[parentIndex]
          .parentId,
        subtypeLevelCount + 1
      );
    } else {
      return subtypeLevelCount;
    }
  };

  getParentIndex = (parentId) => {
    let { structures } = this.props.projectContext;
    if (structures.length < 1) {
      structures = this.state.project.viewerConfig.project.structures;
    }
    // return index of parent structures in all structures
    let parentIndex = structures.findIndex(
      (element) => element.id === parentId
    );
    return parentIndex;
  };

  getParentIndexLayer = (structure) => {
    const { structures } = this.props.projectContext;
    // get index of parent roilayer
    let index = structures.findIndex((element) => element.id === structure.id);
    while (
      structures[index].subtypeLevel !== 0 &&
      structures[index].classificationSubtype
    ) {
      let pIndex = this.getParentIndex(structures[index].parentId);
      if (pIndex >= 0) index = pIndex;
      else break;
    }
    return structures.findIndex((element) => element === structures[index]);
  };

  clearOtherLayers = () => {
    const currentFileId = this.props.projectContext.fileId;
    let project = this.state.project;
    if (project.projectData) {
      let roiLayers = this.props.projectContext.roiLayers;
      for (let file of project.files) {
        if (file.id === currentFileId) {
          continue;
        }
        roiLayers[file.id] = [];
        roiLayers[file.id] = project.projectData.structures.map((c) => {
          return {
            id: c.id,
            layer: {
              regionRois: [],
              inverted: false,
            },
            tree: new RBush(),
          };
        });
      }
      this.props.projectContext.setState({ roiLayers });
    }
  };

  updateStructuresWithPersistentStorage = (structures) => {
    // check structures
    const structuresStateList =
      this.props.persistentStorage.loadProjectTypeValue(
        this.state.project.type,
        "structuresStateList"
      );
    if (structuresStateList === null) return;
    if (structuresStateList.length === structures.length) {
      for (let i = 0; i < structures.length; i++) {
        structures[i].isUnfolded = structuresStateList[i].isUnfolded;
        structures[i].visible = structuresStateList[i].visible;
        structures[i].showSubtypes = structuresStateList[i].showSubtypes;
      }
    }
  };

  initLayersAndStructures = () => {
    let project = this.state.project;

    // load svg's text into blob so we can access it
    for (let tool of project.viewerConfig.tools) {
      this.tools[tool.name] = new InstantAnalysisTool(tool, this);
    }
    this.setMountedState({ viewerConfig: project.viewerConfig });
    let roiLayers = {};
    let commentLayers = {};
    let landmarkLayers = {};
    let structures = project.viewerConfig.project.structures.map((c) => {
      return this.alterStructure(c);
    });

    for (let file of project.files) {
      roiLayers[file.id] = [];
      commentLayers[file.id] = { commentRois: [] };
      landmarkLayers[file.id] = { landmarkRois: [] };
      if (project.viewerConfig.project.structures.length > 0) {
        if (
          project.projectData &&
          project.projectData.structures &&
          project.projectData.structures[0].id !== 0
        ) {
          roiLayers[file.id] = project.projectData.structures.map((c) => {
            return {
              id: c.id,
              layer: {
                regionRois: [],
                inverted: false,
              },
              tree: new RBush(),
            };
          });
        } else {
          roiLayers[file.id] = project.viewerConfig.project.structures.map(
            (c) => {
              return {
                id: c.id,
                layer: {
                  regionRois: [],
                  inverted: false,
                },
                tree: new RBush(),
              };
            }
          );
        }
      }
    }

    // load data from project.json
    if (project.projectData) {
      let histogramConfig = this.state.histogramConfig;
      for (let file of project.projectData.files) {
        histogramConfig[file.id] = file.histogram;
        for (let i = 0; i < project.projectData.structures.length; i++) {
          // if structure from config is not same as saved one --> add dynamic structure
          if (
            !structures[i] ||
            structures[i].id !== project.projectData.structures[i].id
          ) {
            // add additional dynamic structure at index
            structures.splice(
              i,
              0,
              this.alterStructure({
                dynamic: true,
                visible: true,
                isUnfolded: true,
                classificationSubtype: false,
                parentId: 0,
                showSubtypes: false,
                subtypeLevel: 0,
                allToolNames: structures[0].allToolNames,
                tools: project.viewerConfig.project.dynamicStructure.tools,
              })
            );
            const structure = project.projectData.structures[i];
            // make correct value for unfolded
            if (structure.parentId) {
              structures[i].isUnfolded = false;
            }
            structures[i] = Object.assign(structures[i], structure);

            // merge stored parameters into the toolsconfig
            for (let t of structures[i].tools) {
              let storedParams = structure.tools.find((c) => c.name === t.name);
              if (storedParams) {
                Object.assign(t.parameters, storedParams.parameters);
              }
            }
          } else {
            const structure = project.projectData.structures[i];
            if (structure.id === 0) {
              // for old projects
              // do nothing
            } else {
              // merge stored parameters into the toolsconfig
              for (const toolIndex in structure.tools) {
                let t = structure.tools[toolIndex];
                let storedParams = structure.tools.find(
                  (c) => c.name === t.name
                );
                if (storedParams) {
                  Object.assign(t.parameters, storedParams.parameters);
                }
                if (
                  project.viewerConfig.project.structures[i] &&
                  !(t.name in this.tools)
                ) {
                  //happens when tool changed in config
                  t =
                    project.viewerConfig.project.structures[i].tools[toolIndex];
                }
                if (t) {
                  if (
                    this.tools[t.name] &&
                    this.tools[t.name].toolConfig.shared
                  ) {
                    Object.assign(
                      window.sharedToolParameters[t.name],
                      t.parameters
                    );
                    t.parameters = window.sharedToolParameters[t.name];
                  }
                  structure.tools[toolIndex] = t;
                }
              }

              structures[i] = Object.assign(structures[i], structure);

              if (this.checkIfErrorInProjectData(structures[i])) {
                structures[i].hasChild = this.structureHasChild(
                  structures[i].id
                );
                structures[i].isSubtype =
                  structures[i].parentId > 0 ? true : false;
                structures[i].subtypeLevel = this.findStructureSubtypeLevel(
                  structures[i].parentId
                );
              }
            }
          }
        }

        // load annotation layers
        for (let anno of file.annotations) {
          let newRegionRois = createRoisFromAnno(anno);
          roiLayers[file.id][anno.id].layer.regionRois = newRegionRois;
          roiLayers[file.id][anno.id].tree.clear();
          roiLayers[file.id][anno.id].tree.load(
            newRegionRois.map((item) => item.treeItem)
          );
        }
      }

      this.selObjects.coordinates = project.projectData.selectedObjects;
      this.graphData = project.projectData.selectedObjects
        ? this.selObjects.coordinates.length
        : 0;
      this.setMountedState({ histogramConfig: histogramConfig });
    }

    if (this._isMounted) this.forceUpdate();

    this.updateStructuresWithPersistentStorage(structures);

    this.props.projectContext.setState({
      roiLayers,
      structures,
      viewerConfig: project.viewerConfig,
    });
    this.setMountedState({
      structures,
    });
  };

  checkIfErrorInProjectData = (str) => {
    if (!str.classificationSubtype && str.parentId === 0 && !str.hasChild) {
      // if parent
      return true;
    } else if (str.classificationSubtype && str.subtypeLevel === 0) {
      // if classification subtype error
      return true;
    } else if (
      !str.classificationSubtype &&
      str.parentId > 0 &&
      !str.isSubtype
    ) {
      // if substructure error
      return true;
    } else {
      return false;
    }
  };

  //zoomToRoi - creates zoom object with given coordinates and
  createZoomObjectForRect(gl, left, right, top, bottom) {
    // set state that viewer/rendererdict[this.state.activeFileId] zooms to roi thath was clicked in gallery with mouse wheel

    this.X1 = 0;
    this.Y1 = 0;

    let newZoomObjectDict = this.zoomObjectDict;
    newZoomObjectDict[this.state.activeFileId] = {
      zoomRoi: true,
      zoomLeft: left,
      zoomRight: right,
      zoomTop: top,
      zoomBottom: bottom,
    };
    this.setMountedState({
      showGallery: gl,
      showTilesGallery: gl,
    });
    this.zoomObjectDict = newZoomObjectDict;

    /*if (this.state.sideBarWidth === 0) {
      this.toggleSideBar();
    }*/
  }

  setGalleryTool = () => {
    this.setMountedState({ activeTool: Tools.NONE });
  };

  changeToSelectedFile = () => {
    const { project } = this.state;

    let selectedFileId = null;
    // find id of file that is selected in gallery
    project.files.forEach((f) => {
      if (f.selectedInGallery === true) {
        selectedFileId = f.id;
      }
    });

    if (selectedFileId) {
      this.onSelectFile(selectedFileId);
    }
  };

  resetRoiZoom() {
    this.zoomObject.zoomRoi = false;
  }

  resetRoiZoomInDict(fileId) {
    let newZoomObjectDict = this.zoomObjectDict;
    if (newZoomObjectDict) {
      newZoomObjectDict[fileId].zoomRoi = false;
      this.zoomObjectDict = newZoomObjectDict;
      this.setMountedState({ refreshState: true });
    }
  }

  setHistogramConfig(hConfig) {
    this.setMountedState({ hC: hConfig });
  }

  generateToolParameterDict(parameters) {
    if ("paramLabels" in parameters) return parameters;
    //if (parameters.name) return parameters;
    let paramDict = {};
    let paramLabelDict = {};
    for (let parameter of parameters) {
      paramDict[parameter.name] = parameter.ui.default;
      paramLabelDict[parameter.name] = parameter.ui.label;
    }
    paramDict["paramLabels"] = paramLabelDict;
    return paramDict;
  }

  processGeoJSONArray = (anno, frameArray) => {
    const { selectedLayer, structures } = this.props.projectContext;
    if (anno.geoJSONArray) {
      let layerID = anno.id === -1 ? selectedLayer : structures[anno.id].id;
      for (let item of anno.geoJSONArray) {
        const frame = item.i;
        if (item.geoJSON == null) continue;
        let newRegionRoisObject = item.geoJSON.coordinates.map(
          (c) => new RegionROI({ regions: c })
        );
        let layerObject = {
          id: layerID,
          isKeyFrame: true,
          layer: {
            regionRois: newRegionRoisObject,
            inverted: false,
          },
          tree: new RBush(),
        };
        layerObject.tree.load(newRegionRoisObject.map((item) => item.treeItem));

        if (frame in frameArray) {
          const tIdx = frameArray[frame].findIndex(
            (item) => item && item.id === layerID
          );
          if (tIdx >= 0) {
            frameArray[frame][tIdx] = layerObject;
          } else {
            frameArray[frame].push(layerObject);
          }
        } else {
          frameArray[frame] = [layerObject];
        }
        if (frame === this.state.t || frame === this.state.z) {
          anno.geoJSON = item.geoJSON;
        }
      }
    }
  };

  updateProject = (project, fileId = this.props.projectContext.fileId) => {
    const { roiLayers, selectedLayer } = this.props.projectContext;
    let frameArray = this.state.frameArrayDict[this.state.activeFileId];

    // load annotation layers
    for (let anno of project.files.find((c) => c.id === fileId).annotations) {
      this.processGeoJSONArray(anno, frameArray);
      if (anno.geoJSON === null) continue;
      let newRegionRois = createRoisFromAnno(anno);
      let idx = 0;
      if (anno.id === -1) {
        idx = selectedLayer;
      }
      roiLayers[fileId][idx].layer.regionRois = newRegionRois;
      roiLayers[fileId][idx].tree.load(
        newRegionRois.map((item) => item.treeItem)
      );
    }
    this.props.projectContext.setState({ roiLayers: roiLayers });
  };

  updateFileClasses = (projectWithClasses) => {
    const { project } = this.props.projectContext;
    projectWithClasses.files.forEach((file, fileIndex) => {
      project.files[fileIndex].classId = file.classId;
    });
  };

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", () => this.updateDimensions());
    window.addEventListener("keydown", this.keyDown);

    let ids = this.props.persistentStorage.load("SplitscreenFileIds");
    if (ids) {
      this.setMountedState({ splitscreenFileIds: ids });
    }

    let activeId = this.props.persistentStorage.load("ActiveFileId");
    if (activeId) {
      this.setMountedState({ activeFileId: activeId });
    }

    let chainList = this.props.persistentStorage.load("chainList");
    if (chainList) this.chainList = chainList;
    let chainListFileIds =
      this.props.persistentStorage.load("chainListFileIds");
    if (chainListFileIds) this.chainListFileIds = chainListFileIds;

    if (ids) {
      let newZoomObjectDict = this.zoomObjectDict;
      for (const fileId of Object.values(ids)) {
        newZoomObjectDict[fileId] = this.getLocalZoomObject(fileId);
      }
      this.zoomObjectDict = newZoomObjectDict;
    }

    this.props.spinloader.setRightWidth(
      this.state.sideBarWidth +
        this.state.verticalToolBarWidth +
        this.state.classRoomChatWidth
    );
    this.props.projectContext.setPersistentStorage(
      this.props.persistentStorage
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", () => this.updateDimensions());
    window.removeEventListener("keydown", this.keyDown);
    window.setNavigationbarTitle(undefined);
    this.props.spinloader.hide();
  }

  getLocalZoomObject = (fileId) => {
    const zoom = this.props.persistentStorage.load("zoomObject" + fileId);
    let zoomObject = {};
    if (zoom) {
      zoom.zoomRoi = true;
    }
    if (zoom) {
      zoomObject = JSON.parse(JSON.stringify(zoom));
    } else {
      zoomObject = {
        zoomRoi: false,
        zoomLeft: 0,
        zoomRight: 0,
        zoomTop: 0,
        zoomBottom: 0,
      };
    }
    return zoomObject;
  };

  toggleRois = () => {
    const { structures } = this.state.project.viewerConfig.project;
    if (this.countVisibleStructures() > 0) {
      for (let roiLayer of structures) {
        roiLayer.visible = false;
      }
    } else {
      for (let roiLayer of structures) {
        roiLayer.visible = true;
      }
    }
    this.forceUpdate();
  };

  /**
   * handle shortcuts
   * @param {ActionEvent} e Event when keyboard button is pressed
   */
  keyDown = (e) => {
    let elem = null;
    if (
      this.rendererdict[this.state.activeFileId] &&
      this.rendererdict[this.state.activeFileId].keyDown
    )
      this.rendererdict[this.state.activeFileId].keyDown(e);
    if (
      document.activeElement.localName !== "input" &&
      document.activeElement.localName !== "textarea"
    ) {
      if (e.key === "Insert") {
        // [shift] + [Numpad 0]
        elem = document.getElementById("toolZoomFitBtn");
      } else if (e.key === "End") {
        // [shift] + [Numpad 1]
        elem = document.getElementById("toolZoomOriginalBtn");
      } else if (e.shiftKey) {
        switch (e.key) {
          case "*":
          case "+": // [shift] + [+]
            elem = document.getElementById("toolZoomInBtn");
            break;
          case "_":
          case "-": // [shift] + [-]
            elem = document.getElementById("toolZoomOutBtn");
            break;
          case "=": // [shift] + [0]
            elem = document.getElementById("toolZoomFitBtn");
            break;
          case "!": // [shift] + [1]
            elem = document.getElementById("toolZoomOriginalBtn");
            break;
          case "R": // [shift] + [r]
            this.toggleRois();
            break;
          default:
            break;
        }
      } else if (e.altKey) {
        for (let i = 1; i < 10; i++) {
          if (e.key === i.toString()) {
            elem = document.getElementById("channel_" + (i - 1).toString());
            break;
          }
        }
      }
      if (e.ctrlKey) {
        switch (e.key) {
          case "ArrowRight":
            this.openNextFile();
            break;
          case "ArrowLeft":
            this.openPrevFile();
            break;
          case "s": // [ctrl] + [s]
            this.onSaveClick();
            e.preventDefault();
            break;
          case "1": // [ctrl] + [1]
            elem = document.getElementById("toolPenBtn");
            break;
          case "2": // [ctrl] + [2]
            elem = document.getElementById("toolRegionBtn");
            break;
          case "3": // [ctrl] + [3]
            elem = document.getElementById("toolSelectBtn");
            break;
          case "4": // [ctrl] + [4]
            elem = document.getElementById("toolCopyBtn");
            break;
          case "g": // [ctrl] + [g]
            this.setMountedState({
              showTilesGallery: this.state.showTilesGallery ? false : true,
            });
            this.toggleSideBar();
            this.setGalleryTool();
            this.gallery.setPageSelectedRoi(this.state.selRoi);
            e.preventDefault();
            break;
          case "5": // [ctrl] + [5]
            elem = document.getElementById("toolFillBtn");
            break;
          case "p": //[ctrl] + [p]
            elem = document.getElementById("toolPrintBtn");
            break;

          case "z": //[ctrl] + [z]
            if (!this.state.showGallery && !this.state.showTilesGallery) {
              this.props.projectHistory.undo();
            }
            e.preventDefault();
            break;
          case "y": //[ctrl] + [y]
            if (!this.state.showGallery && !this.state.showTilesGallery) {
              this.props.projectHistory.redo();
            }
            e.preventDefault();
            break;
          default:
            break;
        }
      } else {
        switch (e.key) {
          case " ": // space
            if (this.state.showTilesGallery) {
              this.gallery.handleChangeCrosshair(null, true);
            }
            e.preventDefault();
            break;
          case "Delete":
            if (
              typeof this.state.showTilesGallery === "undefined" &&
              this.state.showGallery
            ) {
              this.gallery.deleteSelectedRoi();
            }
            e.preventDefault();
            break;
          case "ArrowUp":
            if (this.state.showTilesGallery) {
              this.gallery.handleChangePage(null, "up");
            } else if (
              typeof this.state.showTilesGallery === "undefined" ||
              this.state.showTilesGallery === false
            ) {
              if (this.state.activeTool === "selection") {
                // select roi next to selected roi
                this.rendererdict[this.state.activeFileId].keyDownSelection(e);
              } else if (this.state.activeTool !== "rectangle") {
                // translate by the offset to the destination point if not in result tab
                if (!this.props.resultTab.getZoomLevelFixed()) {
                  this.rendererdict[this.state.activeFileId].ctx.translate(
                    0,
                    50
                  );
                }
              }
            }
            e.preventDefault();
            break;
          case "ArrowDown":
            if (this.state.showTilesGallery) {
              this.gallery.handleChangePage(null, "down");
            } else if (
              typeof this.state.showTilesGallery === "undefined" ||
              this.state.showTilesGallery === false
            ) {
              if (this.state.activeTool === "selection") {
                // select roi next to selected roi
                this.rendererdict[this.state.activeFileId].keyDownSelection(e);
              } else if (this.state.activeTool !== "rectangle") {
                // translate by the offset to the destination point if not in result tab
                if (!this.props.resultTab.getZoomLevelFixed()) {
                  this.rendererdict[this.state.activeFileId].ctx.translate(
                    0,
                    -50
                  );
                }
              }
            }
            e.preventDefault();
            break;
          case "ArrowLeft":
            if (
              typeof this.state.showTilesGallery === "undefined" ||
              this.state.showTilesGallery === false
            ) {
              if (this.state.showGallery) {
                this.gallery.selectNewImage("left");
              } else {
                if (this.state.activeTool === "selection") {
                  // select roi next to selected roi
                  this.rendererdict[this.state.activeFileId].keyDownSelection(
                    e
                  );
                } else if (this.state.activeTool !== "rectangle") {
                  // translate by the offset to the destination point if not in result tab
                  if (!this.props.resultTab.getZoomLevelFixed()) {
                    this.rendererdict[this.state.activeFileId].ctx.translate(
                      50,
                      0
                    );
                  }
                }
              }
            }
            e.preventDefault();
            break;
          case "ArrowRight":
            if (
              typeof this.state.showTilesGallery === "undefined" ||
              this.state.showTilesGallery === false
            ) {
              if (this.state.showGallery) {
                this.gallery.selectNewImage("right");
              } else {
                if (this.state.activeTool === "selection") {
                  // select roi next to selected roi
                  this.rendererdict[this.state.activeFileId].keyDownSelection(
                    e
                  );
                } else if (this.state.activeTool !== "rectangle") {
                  // translate by the offset to the destination point if not in result tab
                  if (!this.props.resultTab.getZoomLevelFixed()) {
                    this.rendererdict[this.state.activeFileId].ctx.translate(
                      -50,
                      0
                    );
                  }
                }
              }
            }
            e.preventDefault();
            break;
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            {
              let pressedNumber = parseInt(e.key);
              if (this.state.showTilesGallery) {
                this.gallery.classifyTilesGalleryWithKey(pressedNumber);
              } else if (this.state.showGallery) {
                this.gallery.classifyImageWithKey(pressedNumber);
              } else {
                if (this.state.activeTool === "selection") {
                  this.rendererdict[
                    this.state.activeFileId
                  ].classifySelectedRoi(pressedNumber);
                } else if (
                  this.resultTabActive() &&
                  this.state.project.type.includes("HistoClassification")
                ) {
                  window.addFrequencyClass(pressedNumber);
                } else if (
                  this.resultTabActive() &&
                  this.state.project.type.includes("HistoPointCounting")
                ) {
                  window.classifyTileWithkey(pressedNumber);
                }
              }
              e.preventDefault();
            }
            break;
          case "+":
            this.rendererdict[this.state.activeFileId].onScaleOnly({
              wheelDelta: 120,
            });
            e.preventDefault();
            break;
          case "-":
            this.rendererdict[this.state.activeFileId].onScaleOnly({
              wheelDelta: -120,
            });
            e.preventDefault();
            break;
          case "r":
            document.getElementById("roiTabBtn").click();
            e.preventDefault();
            break;
          case "v":
            document.getElementById("viewTabBtn").click();
            e.preventDefault();
            break;
          default:
            break;
        }
        if (
          typeof this.tools[this.state.activeTool] !== "undefined" &&
          this.tools[this.state.activeTool].onKeyDown
        )
          this.tools[this.state.activeTool].onKeyDown(
            e,
            false,
            this.checkToolInConfig("TilesTool")
          );
      }
    }
    if (elem !== null) {
      elem.click();
      e.preventDefault();
    }
    if (
      this.rendererdict[this.state.activeFileId] &&
      this.rendererdict[this.state.activeFileId].showResultTable
    ) {
      window.updateResultTable();
    }
  };

  setSelectedRoi = (roi) => {
    this.setMountedState({ selRoi: roi.r });
  };

  updateDimensions() {
    let savedT = 0;
    if (
      this.rendererdict[this.state.activeFileId] &&
      this.rendererdict[this.state.activeFileId].state
    ) {
      savedT = this.rendererdict[this.state.activeFileId].state.t;
    }
    // reset all child components
    this.setMountedState({
      dimensionsUpdated: true,
      containerKey: new Date().getTime(),
    });
    if (this.rendererdict[this.state.activeFileId] && savedT > 0) {
      this.rendererdict[this.state.activeFileId].updateT(savedT);
    }
    let zoom;
    let newZoomObjectDict;
    for (const value of Object.values(this.state.splitscreenFileIds)) {
      zoom = this.props.persistentStorage.load("zoomObject" + value);
      //if (zoom) zoom.zoomRoi = true;
      newZoomObjectDict = this.zoomObjectDict;
      if (zoom) {
        newZoomObjectDict[value] = JSON.parse(JSON.stringify(zoom));
      } else {
        newZoomObjectDict[value] = {
          zoomRoi: false,
          zoomLeft: 0,
          zoomRight: 0,
          zoomTop: 0,
          zoomBottom: 0,
        };
      }
      this.zoomObjectDict = newZoomObjectDict;
    }
  }

  getArrayDepth = (depthArray) => {
    let depth = 0;
    let child = depthArray[0];
    while (typeof child !== "undefined") {
      depth++;
      child = child[0];
    }
    return depth;
  };

  /**
   * Check if polygon corresponds to the given treeItem
   * @param {*} polygon Polygon that should be compared
   * @param {*} treeItem treeItem that should be compared
   * @returns True/False
   */
  isCorrespondingPolygon = (polygon, treeItem) => {
    for (let contour of polygon) {
      // check if contour has one common point with treeItem
      if (
        contour.find(
          (point) =>
            point[0] === treeItem.regions[0][0] &&
            point[1] === treeItem.regions[0][1]
        )
      ) {
        // check if contour has second common point with treeItem
        if (
          contour.find(
            (point) =>
              point[0] === treeItem.regions[1][0] &&
              point[1] === treeItem.regions[1][1]
          )
        ) {
          // assume to be same object and discard other polygons
          return true;
        }
      }
    }
    return false;
  };

  createNewRois = (frameGeoJson) => {
    if (
      this.getArrayDepth(frameGeoJson.coordinates) === 5 &&
      frameGeoJson.coordinates.length === 1
    )
      frameGeoJson.coordinates = frameGeoJson.coordinates[0];

    //let newRois = [];
    let tree = new RBush();
    let newRois = createRoisFromAnno({ geoJSON: frameGeoJson });
    tree.load(newRois.map((item) => item.treeItem)); //load tree to new data
    return [newRois, tree];
  };

  loadAnnotations(file, id, projectId, newroiLayers, callback) {
    this.props.spinloader.show();
    const fileData = {
      id: projectId,
      fileId: file.id,
    };
    const queryOptions = {
      minX: -1,
      minY: -1,
      maxX: -1,
      maxY: -1,
      maxResults: 2000,
    };
    const zoomObject = this.getLocalZoomObject(file.id);
    if (zoomObject && zoomObject.zoomRoi) {
      queryOptions.minX = parseInt(zoomObject.zoomLeft, 10);
      queryOptions.minY = parseInt(zoomObject.zoomTop, 10);
      queryOptions.maxX = parseInt(zoomObject.zoomRight, 10);
      queryOptions.maxY = parseInt(zoomObject.zoomBottom, 10);
    }
    Backend.loadAnnotations(fileData, queryOptions)
      .then((jsonAnnotations) => {
        this.processLoadedAnnotations(
          jsonAnnotations,
          file,
          id,
          projectId,
          newroiLayers,
          callback
        );
        if (jsonAnnotations.is_reduced === true) {
          window.showWarningSnackbar(
            "Too many annotations to render, only sample annotations from middle are loaded!"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        window.openErrorDialog("Could not load annotations:\n" + err);
        this.props.spinloader.hide();
      });
  }

  updateVisibleROIDebounced = debounce(
    (...args) => this.updateVisibleROI(...args),
    100
  );

  processLoadedAnnotations = (
    jsonAnnotations,
    file,
    id,
    projectId,
    newroiLayers,
    callback
  ) => {
    const { structures } = this.props.projectContext;
    if (jsonAnnotations && jsonAnnotations.is_locked) {
      // call Backend.loadAnnotations(...) again after 5 seconds until jsonAnnotations.is_locked does not exist anymore
      setTimeout(() => {
        this.loadAnnotations(file, id, projectId, newroiLayers, callback);
      }, 5000);
      return;
    }
    this.props.projectContext.setState({
      annotationsReduced: jsonAnnotations.is_reduced,
      totalRoiCount: jsonAnnotations.total_roi_count,
    });
    let result = jsonAnnotations.data;
    this.setMountedState({
      annotationsAreReduced: jsonAnnotations.is_reduced,
    });

    if (!callback) {
      file.annotations = result;
    }

    // part of video annotation. Start

    let keys = [];
    let emptyIdxs = [0];
    for (let i = 1; i < result.length; i++) {
      let idframeArray =
        result[i].geoJSON.idframeArray || result[i].geoJSON.idTimeArray;
      if (
        typeof idframeArray === "undefined" ||
        Object.keys(idframeArray).length === 0
      ) {
        emptyIdxs.push(i);
        continue;
      }

      keys.push(
        ...Object.keys(
          result[i].geoJSON.idframeArray || result[i].geoJSON.idTimeArray
        )
      );
    }
    keys = [...new Set(keys)];
    function objectframeArray(keys) {
      return keys.reduce(function (result, key) {
        result[key] = [];
        return result;
      }, {});
    }
    let loadedframeArray = objectframeArray(keys, function (value) {
      return value;
    });
    Object.keys(loadedframeArray).forEach((key) => {
      for (let i = 1; i < result.length; i++) {
        let anno = result[i];
        let frameGeoJson =
          anno.geoJSON.idframeArray && anno.geoJSON.idframeArray[key]
            ? anno.geoJSON.idframeArray[key]
            : anno.geoJSON; //take default annotations, if no frame found

        if (!frameGeoJson) {
          continue;
        }
        const [newRois, tree] = this.createNewRois(frameGeoJson);
        let structROIs = {
          id: anno.geoJSON.structID ? anno.geoJSON.structID : structures[i].id,
          layer: {
            inverted: false,
            regionRois: newRois,
          },
          tree: tree,
        };
        if (frameGeoJson.isKeyFrame && frameGeoJson.isKeyFrame === true) {
          structROIs.isKeyFrame = true;
        }
        loadedframeArray[key].push(structROIs);
      }
    });

    // add annotations from job
    for (let i = 0; i < result.length; i++) {
      this.processGeoJSONArray(result[i], loadedframeArray);
    }

    let frameArrayDict = this.state.frameArrayDict;
    frameArrayDict[this.state.activeFileId] = loadedframeArray;

    this.setMountedState({
      frameArrayDict: frameArrayDict,
      loadedAnnotationsCounter: this.state.loadedAnnotationsCounter + 1,
    });

    // part of video annotation. End

    // part of frame annotation. Start
    // for (let i of emptyIdxs) {
    for (let i = 0; i < result.length; i++) {
      if (i >= result.length) break;
      let anno = result[i];
      // ensuring that old files also work
      const newRois = this.createNewRois(anno.geoJSON)[0];
      let idx = 0;
      if (this.newLayerIndices.length !== 0) {
        idx = this.newLayerIndices[i];
      } else {
        idx = anno.id;
      }

      if (newroiLayers[file.id][idx]) {
        // create an Id if not set
        if (!newroiLayers[file.id][idx].id)
          newroiLayers[file.id][idx].id = idx + 1;

        newroiLayers[file.id][idx].layer.regionRois = newRois;
        newroiLayers[file.id][idx].tree = new RBush();
        newroiLayers[file.id][idx].tree.load(
          newRois.map((item) => item.treeItem)
        ); //load tree to new data
      }
    }

    // part of frame annotation. End
    this.props.spinloader.hide();
    if (!this.state.annotatedFileIds.includes(file.id)) {
      this.setMountedState((prevState) => ({
        annotatedFileIds: [...prevState.annotatedFileIds, file.id],
      }));
    }
    if (typeof callback === "function") {
      callback();
    }
  };

  updateVisibleROI = (p1, p2) => {
    if (!this.state.annotationsAreReduced) return;
    const { roiLayers, fileId, structures } = this.props.projectContext;
    const structuresMap = {};
    for (let i = 0; i < structures.length; i++) {
      structuresMap[structures[i].id] = i;
    }
    structuresMap[1] = 0;
    let currentFile = this.props.projectContext.project.files.find(
      (file) => file.id === fileId
    );
    if (!this.props.projectContext.isLoadingAnnotations) {
      this.props.projectContext.setState({ isLoadingAnnotations: true });

      const fileData = {
        id: this.state.project.id,
        fileId: currentFile.id,
      };

      const queryOptions = {
        minX: parseInt(p1.x, 10),
        minY: parseInt(p1.y, 10),
        maxX: parseInt(p2.x, 10),
        maxY: parseInt(p2.y, 10),
        maxResults: 2000,
      };

      Backend.loadAnnotations(fileData, queryOptions)
        .then((jsonAnnotations) => {
          this.processLoadedAnnotations(
            jsonAnnotations,
            currentFile,
            fileId,
            this.state.project.id,
            roiLayers
          );
          this.props.spinloader.hide();
          this.props.projectContext.setState({ isLoadingAnnotations: false });
          const lp1 = this.lastViewRoiParams.p1;
          const lp2 = this.lastViewRoiParams.p2;
          if (p1.x !== lp1.x || p1.y !== lp1.y) {
            this.updateVisibleROIDebounced(lp1, lp2);
          }
        })
        .catch((err) => {
          console.error(err);
          window.openErrorDialog(
            `Could not load annotations of file ${currentFile.label}:\n${err}`
          );
          this.props.spinloader.hide();
          this.props.projectContext.setState({ isLoadingAnnotations: false });
        });
    }
    this.lastViewRoiParams = { p1, p2 };
  };

  devtoolsOpen() {
    const threshold = 160;
    const widthThreshold = window.outerWidth - window.innerWidth > threshold;
    const heightThreshold = window.outerHeight - window.innerHeight > threshold;
    //const orientation = widthThreshold ? "vertical" : "horizontal";

    if (
      !(heightThreshold && widthThreshold) &&
      ((window.Firebug &&
        window.Firebug.chrome &&
        window.Firebug.chrome.isInitialized) ||
        widthThreshold ||
        heightThreshold)
    ) {
      return true;
    } else {
      return false;
    }
  }

  openNextFile = () => {
    const { fileId, project } = this.props.projectContext;
    const files = project.files;
    const fileIdx = files.findIndex((file) => file.id === fileId);
    if (fileIdx >= 0 && files.length > fileIdx + 1) {
      this.onSelectFile(files[fileIdx + 1].id);
    }
  };

  openPrevFile = () => {
    const { fileId, project } = this.props.projectContext;
    const files = project.files;
    const fileIdx = files.findIndex((file) => file.id === fileId);
    if (fileIdx > 0) {
      this.onSelectFile(files[fileIdx - 1].id);
    }
  };

  openFile(id, projectId, fileChange = false) {
    if (this.lastFileId === id) return; //prevent loading File twice
    this.lastFileId = id;
    const { roiLayers, structures } = this.props.projectContext;
    // create empty roilayers for dynamic structures
    if (roiLayers[id]) {
      for (let structure of structures) {
        const roiLayer = roiLayers[id].find((c) => c.id === structure.id);
        if (!roiLayer) {
          roiLayers[id].push({
            id: structure.id,
            layer: {
              regionRois: [],
              inverted: false,
            },
            tree: new RBush(),
          });
        }
      }
      this.props.projectContext.setState({ roiLayers });
    }

    // load image meta data
    Backend.loadImage(
      {
        id: id,
      },
      (ome) => {
        if (ome.fileName) {
          let newOme = { ...this.state.omeDict };
          newOme[id] = ome;
          this.props.persistentStorage.save("opened_file_id", id);
          let newStateObject = {
            omeDict: newOme,
            fileId: id,
            showTimeBar: ome.sizeT > 1,
            showZStackBar: ome.sizeZ > 1,
            displayTimeBar: ome.sizeT > 1,
            displayZStackBar: ome.sizeZ > 1,
            histogramConfig: this.state.histogramConfig,
            ome,
            importProgress: -1,
          };
          if (
            !fileChange &&
            !this.props.persistentStorage.load("SplitscreenFileIds")
          ) {
            newStateObject.splitscreenFileIds = [id];
          }

          if (!fileChange) newStateObject.containerKey = new Date().getTime();

          if (!newStateObject.histogramConfig[id]) {
            // write loaded data in to component state
            newStateObject.histogramConfig[id] = new ChannelsConfig(
              ome.channels.map((c, i) => {
                //set general name, if none in metadata
                if (c.name === null) {
                  c.name = "Ch " + i;
                }
                return c;
              })
            );
          }
          Object.assign(this.state, newStateObject);
          this.props.projectContext.setState(newStateObject, () => {
            let newProjectObject = this.state.project;
            if (newProjectObject.projectData !== null) {
              let currentFile = newProjectObject.files.find(
                (file) => file.id === id
              );
              if (currentFile) {
                // if (!droppedFile && this.state.splitscreenFileIds.length === 1) {
                //   this.deleteRoiLayersForScene(); // make roiLayers of current scene empty
                // }
                this.loadAnnotations(
                  currentFile,
                  id,
                  projectId,
                  roiLayers,
                  () => {
                    this.setMountedState({ project: newProjectObject });
                    this.props.projectContext.setState({
                      project: newProjectObject,
                      roiLayers,
                    });
                    this.props.projectHistory.init(this);
                  }
                );
              }
            }
          });
        } else {
          let failedFilePath = this.state.project.files.find(
            (file) => file.id === id
          ).sourcePath;
          window.openErrorDialog(
            "Error: Most likely the original file could not be found:\n" +
              failedFilePath
          );
        }

        // if histo classification project draw grid for next file
        if (
          this.state.project.type.includes("HistoClassification") &&
          this.state.gridSize &&
          this.rendererdict[this.state.activeFileId]
        ) {
          setTimeout(() => {
            this.onChangeTool("gridtool");
            this.tools["gridtool"].createGrid(this.state.gridSize, false, true);
          }, 400);
          setTimeout(
            () => this.rendererdict[this.state.activeFileId].centerRoi(),
            600
          );
        }

        // if point counting project draw grid for next file
        if (
          this.state.project.type.includes("HistoPointCounting") &&
          this.state.gridSize &&
          this.rendererdict[this.state.activeFileId]
        ) {
          setTimeout(() => {
            this.onChangeTool("gridtool");
            this.tools["gridtool"].createGrid(this.state.gridSize, true, true);
          }, 400);
          setTimeout(
            () => this.rendererdict[this.state.activeFileId].centerRoi(),
            600
          );
        }

        if (!fileChange) {
          // set default selected layer
          this.setSelLayerConfig();
        }

        // if e.g. Tobacco Analysis and no Base-Roi set, set whole Scene as Base-Roi
        if (this.checkPropertyInConfig("AutomaticBaseRoiWholeImage")) {
          let idx = 0;
          if (this.props.projectContext.selectedLayer != null) {
            idx = this.props.projectContext.selectedLayer;
          }
          setTimeout(() => {
            this.setSelectedLayer(0);
            this.onChangeTool("gridtool");
            this.tools["gridtool"].createGrid(1, true, false);
            this.onChangeTool("none");
            this.setSelectedLayer(idx);
          }, 1000);
        }

        // set full image as structure
        let automaticFullImageRoiLayer =
          this.state.viewerConfig.project.projectStringProperties[
            "AutomaticFullImageRoiLayer"
          ];
        if (automaticFullImageRoiLayer) {
          this.setMountedState({
            opacity: 0,
          });
          let idx = 0;
          if (this.props.projectContext.selectedLayer != null) {
            idx = this.props.projectContext.selectedLayer;
          }
          setTimeout(() => {
            this.setSelectedLayer(parseInt(automaticFullImageRoiLayer, 10));
            let gridExists =
              roiLayers[id][parseInt(automaticFullImageRoiLayer, 10)].layer
                .regionRois.length > 0;
            if (!gridExists) {
              this.onChangeTool("gridtool");
              this.tools["gridtool"].createGrid(1, true, false);
              this.onChangeTool("none");
            }
            this.onChangeTool("selection");
            this.setSelectedLayer(idx);
          }, 2000);
        }

        // set file change false
        setTimeout(() => {
          this.props.resultTab.setFileChange(false);
          if (this._isMounted) this.forceUpdate();
        }, 1);
      },
      (error) => {
        let failedFilePath = this.state.project.files.find(
          (file) => file.id === id
        ).sourcePath;
        let msg = `Error importing file ${failedFilePath}:\n${error}`;
        console.log(msg);
        window.openErrorDialog(msg);
      },
      (e) => {
        console.log("Import progress:", e);
        this.setMountedState({ importProgress: e.progress });
      }
    );

    if (this.state.showGallery) {
      this.setMountedState({ showGallery: false });
    }
  }

  deleteRoiLayersForScene = () => {
    const { roiLayers, structures } = this.props.projectContext;
    const { activeFileId } = this.state;

    for (let i = 0; i < structures.length; i++) {
      if (roiLayers[activeFileId][i]) {
        roiLayers[activeFileId][i].layer.regionRois = [];
        roiLayers[activeFileId][i].tree.clear();
      }
    }
    this.props.projectContext.setState({ roiLayers });

    // remove file from annotated files
    const index = this.state.annotatedFileIds.indexOf(activeFileId);
    if (index > -1) {
      this.state.annotatedFileIds.splice(index, 1);
    }
  };

  // change the active tool
  onChangeTool = (activeTool, params) => {
    if (
      activeTool === "iam_ai_inference" &&
      this.props.projectContext.activeTab === 1 &&
      !this.state.aiUsedStructures.filter(
        (e) =>
          e.id ===
          this.props.projectContext.structures[
            this.props.projectContext.selectedLayer
          ].id
      ).length > 0
    ) {
      activeTool = "none";
    }
    if (this.state.showGallery) {
      setTimeout(() => this.galleryChangeTool(activeTool, params), 100);
    } else {
      if (
        this.rendererdict[this.state.activeFileId] &&
        this.rendererdict[this.state.activeFileId].updatePreviewRect &&
        this.checkTool(activeTool)
      ) {
        if (
          this.tools[this.state.activeTool] &&
          typeof this.tools[this.state.activeTool].exit === "function"
        ) {
          this.tools[this.state.activeTool].exit();
        }

        this.setMountedState({ activeTool }, () => {
          this.rendererdict[this.state.activeFileId].updatePreviewRect(
            activeTool
          );

          this.rendererdict[
            this.state.activeFileId
          ].props.drawLayer.regionRois = [];

          let tool = this.tools[activeTool];
          if (tool && tool.onParameterChange) {
            tool.onParameterChange(params);
          }
        });
      }
    }
  };

  checkTool = (e) => {
    const { structures, selectedLayer } = this.props.projectContext;
    // check if tool can be selected with selected structure
    switch (e) {
      case "selection":
        if (!structures[selectedLayer].hasChild) {
          window.showWarningSnackbar("Structure has no children.");
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  };

  checkToolInConfig = (toolName) => {
    if (this.state.viewerConfig) {
      if (this.state.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  };

  checkPropertyInConfig = (property) => {
    if (this.state.viewerConfig) {
      if (this.state.viewerConfig.project.projectProperties[property]) {
        return true;
      } else {
        return false;
      }
    }
  };

  resultTabActive = () => {
    // check if active tab is result tab
    if (!this.checkToolInConfig("ResultTab")) {
      return false;
    }

    // check which tabs exist in module --> then decide which index result tab has
    let persisObj = this.props.persistentStorage.load("activeTab");
    let activeTab = persisObj ? persisObj : 0;
    if (
      this.checkToolInConfig("AICockpit") &&
      this.checkToolInConfig("RoiTab")
    ) {
      // resultTab = 3
      return activeTab === 3;
    } else if (
      this.checkToolInConfig("AICockpit") ||
      this.checkToolInConfig("RoiTab")
    ) {
      // resultTab = 2
      return activeTab === 2;
    } else {
      // resultTab = 1
      return activeTab === 1;
    }
  };

  updateStructureChange = () => {
    setTimeout(() => this.setMountedState({ updateStructure: true }), 100);
  };

  onGallerychangePage() {
    this.setMountedState({ activeTool: Tools.NONE });
  }

  galleryChangeTool(activeTool, params) {
    if (this.checkTool(activeTool)) {
      this.setMountedState({ activeTool: activeTool });
      let tool = this.tools[activeTool];
      if (tool && tool.onParameterChange) {
        tool.onParameterChange(params);
      }
    }
  }

  onSelectFile = (e) => {
    let { activeFileId, splitscreenFileIds } = this.state;
    if (splitscreenFileIds.includes(e)) {
      if (this.state.showFullscreen) {
        if (this.sliderRef && this.state.showOverlay) {
          this.sliderRef.fileCheck(e, () => {
            this.addFileToFullscreen(e, this.sliderRef.state.checkedUp ? 1 : 0);
          });
        } else {
          this.addFileToFullscreen(e, 0);
        }
      } else {
        this.addFileToFullscreen(e, 0);
      }
      activeFileId = e;
      this.props.persistentStorage.save("ActiveFileId", activeFileId);
      this.setMountedState({
        activeFileId,
      });
    } else {
      this.props.tiles.resetVisibleImages(); //prevent ram issue
      this.props.tiles.resetColoredImages(); //prevent ram issue
      //this.props.tiles.setFileId(e);
      //this.props.projectContext.setChangingFile(true);
      // save annotations when changing file

      this.saveFunction(null, () => {
        this.onSwitchFileSplitscreen(e);
        if (this.sliderRef && this.state.showOverlay) {
          this.addFileToFullscreen(e, this.sliderRef.state.checkedUp ? 0 : 1);
        } else {
          this.addFileToFullscreen(e, 0);
        }
        if (
          !(
            this.state.project.type.includes("HistoPointCounting") ||
            this.state.project.type.includes("HistoClassification")
          )
        ) {
          this.clearOtherLayers();
        }
      });
    }
  };

  onExcludeFilesToggle = (files, value) => {
    let projectObject = this.state.project;
    for (let file of files) {
      for (let f of projectObject.files) {
        if (file.id === f.id) {
          file.excludeScene = value;
        }
      }
    }
    this.setMountedState(projectObject);
  };

  toggleFileTreeView = () => {
    this.setMountedState({
      showFileTreeView: !this.state.showFileTreeView,
      containerKey: new Date().getTime(),
    });
  };

  onExportParameters = () => {
    const { structures } = this.props.projectContext;

    let dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(structures));
    let dlAnchorElem = document.createElement("a");
    dlAnchorElem.setAttribute("href", dataStr);
    const strData = this.props.projectContext.getProjectStringInfos();
    dlAnchorElem.setAttribute(
      "download",
      strData.name + "_" + strData.type + "_" + strData.fileName + ".strhsa"
    );
    dlAnchorElem.click();
    dlAnchorElem.remove();
  };

  //TODO: [HWB-910] to fix because probably broken because of turf integration
  loadSCNXMLAnnotations(docstr) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(docstr, "application/xml");
    window.annodoc = doc;

    const annotations = doc.getElementsByTagName("Annotation");
    for (let i = 0; i < annotations.length; i++) {
      console.log(i, "=>", annotations[i]);
      const regions = annotations[i].children[1];
      for (let region of regions.children) {
        if (region.tagName !== "Region") continue;
        const vertices = region.children[1];
        const points = [];
        for (let vertex of vertices.children) {
          points.push([
            parseInt(vertex.getAttribute("X")),
            parseInt(vertex.getAttribute("Y")),
          ]);
        }
        let drawRegion = {
          regions: [
            // NOTE: the last two parameters might have to be adjusted in case of bugs.
            createRegionRoi([points], "#ff0000", false, "Tumor", null, null),
          ],
          inverted: false,
          bounds: calcBoundingBox([points]),
        };
        const { roiLayers } = this.props.projectContext;
        // roiLayers[this.state.activeFileId][1].layer.regionRois.push(
        //   drawRegion
        // );
        const selRoiLayer = roiLayers[this.state.activeFileId][i + 1]; // skip base roi
        updateLayer(
          selRoiLayer.layer,
          drawRegion,
          false,
          "#ff0000",
          false,
          "Tumor",
          selRoiLayer.tree
        );
      }
    }
  }

  onImportParameters = (e) => {
    let files = e.target.files;
    if (files.length <= 0) return false;

    let fr = new FileReader();
    fr.onload = (e) => {
      try {
        if (e.target.result.startsWith("<Annotations")) {
          // SCN Annotation XML File
          this.loadSCNXMLAnnotations(e.target.result);
        } else {
          // exported structures
          let structures = JSON.parse(e.target.result);
          if (structures.length > 0 && structures[0].label) {
            this.props.projectContext.importStructures(structures);
          } else {
            window.showErrorSnackbar("File not supported!");
          }
        }
      } catch (e) {
        console.log("import errror:", e);
        window.showErrorSnackbar("File not supported!");
      }
    };
    fr.readAsText(files.item(0));
  };

  deleteforAllScenes = (selectedLayer) => {
    const { roiLayers } = this.props.projectContext;
    Object.keys(roiLayers).forEach((e) => {
      roiLayers[e][selectedLayer].layer.regionRois = [];
      roiLayers[e][selectedLayer].tree.clear();
    });
    this.props.projectContext.setState({ roiLayers });
  };

  getNumberSubstructuresWithRois = () => {
    const { activeFileId } = this.state;
    const { selectedLayer, structures, roiLayers } = this.props.projectContext;
    // calculate number of substructures that have rois (for export)
    let parentLayer = this.getParentIndexLayer(structures[selectedLayer]);
    let nextParentLayer = parentLayer + 1;
    // calculate next substructure or parentlayer on same subtypelevel
    for (let i = parentLayer; i < structures.length; i++) {
      if (
        !structures[i].classificationSubtype &&
        structures[i].subtypeLevel === structures[parentLayer].subtypeLevel
      ) {
        nextParentLayer = i;
      }
    }

    // count substructures with rois between parentlayer and nextparentlayer
    let count = 0;
    for (let i = parentLayer + 1; i < nextParentLayer; i++) {
      if (
        structures[i].isSubtype &&
        !structures[i].classificationSubtype &&
        roiLayers[activeFileId][i].layer.regionRois.length !== 0
      ) {
        count = count + 1;
      }
    }
    return count;
  };

  createProjectModel = (layersToSave, previewRect) => {
    const {
      project,
      histogramConfig,
      viewerConfig,
      formDataAICockpit,
      commentLayers,
      landmarkLayers,
    } = this.state;
    const { selectedLayer, structures, roiLayers, user } =
      this.props.projectContext;

    //this.adjustRoiLayers();
    let parentLayer = this.getParentIndexLayer(structures[selectedLayer]);
    const projectModel = {
      name: project.name,
      user: user,
      id: project.id,
      readableId: project.readableId,
      metaData: JSON.parse(project.metaData),
      type: project.type,
      tools: viewerConfig.project.tools,
      job: viewerConfig.project.job,
      structures: structures,
      formDataAICockpit: JSON.stringify(formDataAICockpit),
      tMatrices: this.props.tiles.getAllTransformationMatrices(),
      tFactors: this.props.tiles.getAllTransformationFactors(),
      tOffsets: this.props.tiles.getAllTransformationOffsets(),
      files: project.files.map((file) => {
        let frameArray = this.state.frameArrayDict[file.id];

        if (this.state.project.type.includes("HistoClassification")) {
          // move rois back to correct layer
          for (let i = 0; i < roiLayers[file.id].length; i++) {
            const regionRois = roiLayers[file.id][i].layer.regionRois;
            if (regionRois.length > 0) {
              const correctStructureId = structures[i].id;
              const regionRoisToMove = regionRois.filter(
                (roi) => roi.isSubtype && roi.structureId !== correctStructureId
              );
              if (regionRoisToMove.length > 0) {
                const newRois = regionRois.filter(
                  (roi) => roi.structureId === correctStructureId
                );
                roiLayers[file.id][i].layer.regionRois = newRois;
                roiLayers[file.id][i].tree.clear();
                roiLayers[file.id][i].tree.load(
                  newRois.map((item) => item.treeItem)
                ); //load tree to new data

                regionRoisToMove.forEach((roi) => {
                  const roiLayerIdx = structures.findIndex(
                    (structure) => structure.id === roi.structureId
                  );
                  roiLayers[file.id][roiLayerIdx].layer.regionRois.push(roi);
                  roiLayers[file.id][roiLayerIdx].tree.insert(roi.treeItem);
                });
              }
            }
          }
        }

        return {
          id: file.id,
          fileName: file.fileName,
          sourcePath: file.sourcePath,
          histogram: histogramConfig[file.id],
          scene: file.scene,
          excludeScene: file.excludeScene,
          fullyAnnotated: file.fullyAnnotated,
          annotated: file.annotated === true,
          saved: file.saved,
          classId: file.classId,
          commentRois: commentLayers[file.id].commentRois.map((roi, i) => {
            return {
              id: i,
              regions: roi.regions,
              color: roi.color,
              type: roi.type,
              commentValue: roi.commentValue,
            };
          }),
          landmarkRois: landmarkLayers[file.id].landmarkRois.map((roi, i) => {
            return {
              id: i,
              regions: roi.regions,
              color: roi.color,
              type: roi.type,
              commentValue: roi.commentValue,
            };
          }),
          annotations: roiLayers[file.id].reduce((acc, roiLayer, i) => {
            if (
              (roiLayer.layer.regionRois ||
                this.state.annotatedFileIds.includes(file.id)) &&
              layersToSave !== "none"
            ) {
              if (layersToSave === "all" || layersToSave.includes(i)) {
                let rois;
                let current_id = roiLayer.id;
                let idTimeArr = {};

                for (let key in frameArray) {
                  if (key in frameArray) {
                    for (
                      let idLayer = 0;
                      idLayer < frameArray[key].length;
                      idLayer++
                    ) {
                      if (frameArray[key][idLayer].id === current_id) {
                        let tArrRois =
                          frameArray[key][idLayer].layer.regionRois;
                        let frameIdTimeArr = {
                          type: "MultiPolygon",
                          coordinates: tArrRois.map((c) => c.regions),
                          withHoles: true,
                          color1: tArrRois.map((c) => [c.color]),
                          subtype: tArrRois.map((c) => [c.isSubtype]),
                          name: tArrRois.map((c) => [c.subtypeName]),
                          aiAnnotated: tArrRois.map((c) => [c.aiAnnotated]),
                          isAnnotated: tArrRois.map((c) => [c.isAnnotated]),
                          isLabeled: tArrRois.map((c) => [c.isLabeled]),
                          isSelObj: tArrRois.map((c) => [c.isSelObj]),
                          isSaved: tArrRois.map((c) => [c.isSaved]),
                          z: tArrRois.map((c) => [c.z]),
                          comment: tArrRois.map((c) => [c.comment]),
                          tileName: tArrRois.map((c) => [c.tileName]),
                          structureId: tArrRois.map((c) => [c.structureId]),
                          isObject: tArrRois.map((c) => [c.isObject]),
                          frequencyClass: tArrRois.map((c) => [
                            c.frequencyClass,
                          ]),
                        };
                        if (
                          frameArray[key][idLayer].isKeyFrame &&
                          frameArray[key][idLayer].isKeyFrame === true
                        ) {
                          frameIdTimeArr.isKeyFrame = true;
                        }
                        idTimeArr[key] = frameIdTimeArr;
                      }
                    }
                  }
                }
                if (previewRect) {
                  rois = roiLayer.tree
                    .search({
                      minX: previewRect.x,
                      minY: previewRect.y,
                      maxX: previewRect.x + previewRect.w,
                      maxY: previewRect.y + previewRect.h,
                    })
                    .map((treeItem) => treeItem.roi);
                } else {
                  rois = roiLayer.layer.regionRois;
                }
                acc.push({
                  id: i,
                  geoJSON: {
                    structID: current_id,
                    idframeArray: idTimeArr,
                    type: "MultiPolygon",
                    coordinates: rois.map((c) => c.regions),
                    withHoles: true,
                    color1: rois.map((c) => [c.color]),
                    subtype: rois.map((c) => [c.isSubtype]),
                    name: rois.map((c) => [c.subtypeName]),
                    aiAnnotated: rois.map((c) => [c.aiAnnotated]),
                    isAnnotated: rois.map((c) => [c.isAnnotated]),
                    isLabeled: rois.map((c) => [c.isLabeled]),
                    isSelObj: rois.map((c) => [c.isSelObj]),
                    isSaved: rois.map((c) => [c.isSaved]),
                    z: rois.map((c) => [c.z]),
                    comment: rois.map((c) => [c.comment]),
                    tileName: rois.map((c) => [c.tileName]),
                    structureId: rois.map((c) => [c.structureId]),
                    isObject: rois.map((c) => [c.isObject]),
                    frequencyClass: rois.map((c) => [c.frequencyClass]),
                  },
                });
              }
            }
            return acc;
          }, []),
        };
      }),

      selectedObjects: this.selObjects.coordinates,
      selLayer: selectedLayer,
      selStructure: structures[selectedLayer].label,
      selStructureId: structures[parentLayer].id,
      exportSubstructures: this.state.exportSubstructures, // not used at the moment (always export substructures)
      onlyAnnotations: false,
      zLevelClassification: this.props.tiles.getZLevel(),
      numberSubstructuresWithRois: this.getNumberSubstructuresWithRois(),
    };
    return projectModel;
  };

  getSelectedObjects = (x, fileID, fromActiveLearning) => {
    const { activeFileId } = this.state;
    //const { selectedLayer, roiLayers } = this.props.projectContext;
    // get classified objects from backend/training/classificaton and assign class and new properties

    this.selObjects = x;
    this.selObjs = true;
    this.updateCount = this.updateCount + 1;

    // isSaved property removed --> because with isSaved labeled images can not be classified by ai
    /*if (roiLayers[fileID] && roiLayers[fileID][selectedLayer]) {
      // set isSaved property true for all labeled images (by user) --> do not save twice (in next iterations)
      roiLayers[fileID][selectedLayer].layer.regionRois.forEach(function (
        element
      ) {
        if (!element.aiAnnotated && element.isSubtype) {
          // if roi is subtype but not annotated by ai
          element.isSaved = true;
        } else if (element.aiAnnotated && element.isLabeled) {
          // if roi annotated by ai but classified by user afterwards
          element.isSaved = true;
        }
      });
    }*/

    // if in current file --> set properties for objects/rois
    if (fileID === activeFileId) {
      this.setAIObjects(fromActiveLearning);
    }

    // set training finished and update
    this.setAlruns(false);
    if (this._isMounted) this.forceUpdate();
  };

  setAIObjects = (fromActiveLearning) => {
    const { activeFileId } = this.state;
    const { selectedLayer, structures, roiLayers } = this.props.projectContext;

    // set properties for rois (classification result)
    let fileID = activeFileId;
    let Objs = this.selObjects;

    if (Objs && Objs.coordinates.length > 0 && Objs.coordinates[0] !== null) {
      roiLayers[fileID][selectedLayer].layer.regionRois.forEach(function (roi) {
        // get same element from backend results
        let r = Objs.coordinates.filter(
          (element) =>
            parseInt(roi.bounds.left, 10) === element.x &&
            parseInt(roi.bounds.top, 10) === element.y
        )[0];
        // only set new properties if roi is not already classified by user
        if (r && !roi.isLabeled) {
          let subtypeStructureIdx = structures.findIndex(
            (element) => element.id === r.id && element.isSubtype
          );
          roi.structureId = structures[subtypeStructureIdx].id;
          roi.color = structures[subtypeStructureIdx].color;
          roi.subtypeColor = structures[subtypeStructureIdx].color;
          roi.subtypeName = structures[subtypeStructureIdx].label;
          roi.aiAnnotated = true;
          roi.isSelObj = true;
        }
      });
    }

    // if fromActiveLearning --> sort rois by uncertainty
    if (fromActiveLearning) {
      // get index of last unlabeled element in roiLayer
      let idxLastLabeled = 0;
      roiLayers[fileID][selectedLayer].layer.regionRois.forEach(
        (element, idx) => {
          if (element.isLabeled) {
            idxLastLabeled = idx;
          }
        }
      );

      // get array with labeled rois
      let labeled = roiLayers[fileID][selectedLayer].layer.regionRois.slice(
        0,
        idxLastLabeled + 1
      );
      // get array with unlabeled rois
      let unlabeled = roiLayers[fileID][selectedLayer].layer.regionRois.slice(
        idxLastLabeled + 1
      );

      let sortedObjects = this.selObjects;
      let newSortedArray = [];
      sortedObjects.coordinates.forEach((element) => {
        // check if element exists in array with unlabeled data
        // get same element from backend results
        let r = unlabeled.filter(
          (roi) =>
            parseInt(roi.bounds.left, 10) === element.x &&
            parseInt(roi.bounds.top, 10) === element.y
        );
        // if element is in array --> put in new sorted array
        if (r[0]) {
          newSortedArray.push(r[0]);
        }
      });
      let updatedRoiLayer = labeled.concat(newSortedArray);
      roiLayers[fileID][selectedLayer].layer.regionRois = updatedRoiLayer;
    }

    this.selObjects = {
      coordinates: [],
    };

    // set firstTimeGallery true after scene switch
    this.setFirstTimeGallery();
  };

  setFirstTimeGallery = () => {
    // firstTimeGallery = true --> load new tiles
    const { activeFileId } = this.state;
    const { selectedLayer, roiLayers } = this.props.projectContext;

    roiLayers[activeFileId][selectedLayer].layer.regionRois.forEach(function (
      element
    ) {
      element.firstTimeGallery = true;
    });
  };

  setSelectedLayer = (x) => {
    if (this.state.selLayer !== x) {
      this.props.projectContext.setState({ selectedLayer: x });
    }
  };

  saveChangesGallery(fromExport) {
    this.saveFunction(fromExport);
  }

  updateViewer() {
    if (this._isMounted) this.forceUpdate();
  }

  setFUllyAnnotated = () => {
    let projectObject = this.state.project;
    projectObject.files[this.findFileIndex()].fullyAnnotated = true;
    this.setMountedState({ project: projectObject });
  };

  setAnnotated = () => {
    let projectObject = this.state.project;
    projectObject.files[this.findFileIndex()].annotated = true;
    this.setMountedState({ project: projectObject });
  };

  findFileIndex = () => {
    let idx = 0;
    this.state.project.files.forEach((element, i) => {
      if (element.id === this.state.activeFileId) {
        idx = i;
      }
    });
    return idx;
  };

  setApplyModelAfterTraining = (e) => {
    // set if apply model after training to unlabeled images
    this.applyModelAfterTraining = e;
    if (this._isMounted) this.forceUpdate();
  };

  startAutomaticTraining = () => {
    // start taining of dl model after x annotations automatically
    this.saveFunction();
    this.automaticTraining = true;

    // first do training with all annotated objects
    this.applyDL(
      this.tools["alm_gallery_tool"],
      this.props.id,
      this.state.activeFileId,
      false
    );

    this.setAlruns(true);
    // then do classification for all unlabeled objects
    this.applyModelAfterTraining = true;
  };

  startPassiveLearningTraining = (tool, projectId, fileId) => {
    let previewRect = {
      // rect parameters for train model
      x: 2,
      y: 2,
      w: 2,
      h: 2,
    };
    if (tool.toolConfig.name.includes("alm_")) {
      if (!this.automaticTraining) {
        // show loading spinner
        this.props.spinloader.show();
      }
      window.showSuccessSnackbar("Start training");
      Backend.activeLearningSignalR(
        {
          name: tool.toolConfig.name,
          projectId: projectId,
          fileIds: [fileId],
          parameters: previewRect,
          preview: false,
          rect: null,
          project: this.createProjectModel("none"),
        },
        (alModel) => {
          this.setAlruns(false);
          this.props.spinloader.hide();
          if (this.applyModelAfterTraining) {
            // apply model to all unlabeled rois after training
            this.applyDL(
              this.tools["alm_gallery_tool"],
              this.props.id,
              this.state.activeFileId,
              true,
              true
            );
          } else {
            // put results from backend to frontend
            this.getSelectedObjects(alModel, fileId);
            this.saveFunction();
            if (this._isMounted) this.forceUpdate();
          }
        },
        (error) => {
          window.openErrorDialog(error);
        }
      );
    }
  };

  prepareImagesForTraining = (idx, tool, projectId, previewRect) => {
    // save/crop all labeled images of all files before training
    // if end of files --> stop
    if (idx >= this.state.project.files.length) {
      // when all images are saved --> start training
      this.startPassiveLearningTraining(
        tool,
        projectId,
        this.state.project.files[0].id
      );
      return;
    }
    // if saved --> images of secene already prepared --> TODO: fix this
    // if (false /*this.state.project.files[idx].saved*/) {
    //   this.prepareImagesForTraining(idx + 1, tool, projectId, previewRect);
    //   return;
    // }
    // if not annotated --> no classified images for training in this file --> TODO: fix this
    // else if (false /*!this.state.project.files[idx].annotated*/) {
    //   this.prepareImagesForTraining(idx + 1, tool, projectId, previewRect);
    //   return;
    // }
    else {
      if (!this.automaticTraining) {
        // show loading spinner
        this.props.spinloader.show();
      }
      let fileIDs = this.state.project.files;
      let x = this.createProjectModel("none");
      Backend.activeLearningSignalR(
        {
          name: tool.toolConfig.name,
          projectId: projectId,
          fileIDs: [fileIDs[idx].id],
          parameters: previewRect,
          preview: false,
          rect: null,
          project: x,
        },
        () => {
          // set file saved true if all rois are annotated
          if (fileIDs[idx].fullyAnnotated === true) {
            let projectObject = this.state.project;
            projectObject.files[idx].saved = true;
            this.setMountedState({
              project: projectObject,
            });
          }

          // saves images for training recursively for each scene
          if (idx + 1 < this.state.project.files.length) {
            this.prepareImagesForTraining(
              idx + 1,
              tool,
              projectId,
              previewRect
            );
          } else {
            // when all images are saved --> start training
            this.startPassiveLearningTraining(tool, projectId, fileIDs[0].id);
          }
        },
        (error) => {
          window.openErrorDialog(error);
        }
      );
    }
  };

  startClassification = (idx, tool, projectId, previewRect) => {
    // start classification of rois with dl model
    // if end of files --> stop
    if (idx >= this.state.project.files.length) {
      this.setAlruns(false);
      this.props.spinloader.hide();
      return;
    }
    // if fullyAnnotated --> no unlabeled images ( --> fix this: when fully annotated apply model does not work because fullyannotated can be set wrong)
    // if (false /*this.state.project.files[idx].fullyAnnotated*/) {
    //   this.startClassification(idx + 1, tool, projectId, previewRect);
    // } else {
    if (!this.automaticTraining) {
      // show loading spinner
      this.props.spinloader.show();
    }
    let fileIDs = this.state.project.files;
    let projModel = this.createProjectModel("none");
    Backend.activeLearningSignalR(
      {
        name: tool.toolConfig.name,
        projectId: projectId,
        fileIDs: [fileIDs[idx].id],
        parameters: previewRect,
        preview: false,
        rect: null,
        project: projModel,
      },
      (alModel) => {
        // do classification recursively for each scene
        if (idx + 1 < this.state.project.files.length) {
          this.getSelectedObjects(alModel, fileIDs[idx].id);
          this.startClassification(idx + 1, tool, projectId, previewRect);
        } else {
          // put results from backend to frontend
          this.getSelectedObjects(alModel, fileIDs[idx].id);
          this.setAlruns(false);
          this.props.spinloader.hide();
        }
      },
      (error) => {
        window.openErrorDialog(error);
        this.props.spinloader.hide();
      }
    );
    // }
  };

  applyDL(tool, projectId, fileId, passiveLearning, applyModel) {
    let previewRect;
    if (passiveLearning) {
      // start passive learning
      if (!applyModel) {
        // start training
        previewRect = {
          // rect parameters for train model
          x: 1,
          y: 1,
          w: 2,
          h: 2,
        };
        if (tool.toolConfig.name.includes("alm_")) {
          window.showSuccessSnackbar("Preparing data for training");
          this.prepareImagesForTraining(0, tool, projectId, previewRect);
        }
      } else {
        // Apply Model (classify unlabeled images)
        previewRect = {
          // rect parameters for apply model
          x: 1,
          y: 1,
          w: 5,
          h: 5,
        };
        if (tool.toolConfig.name.includes("alm_")) {
          window.showSuccessSnackbar("classify unlabeled images");
          this.startClassification(0, tool, projectId, previewRect);
        }
      }
    } else {
      // start active learning
      previewRect = {
        // rect parameters for al
        x: 0,
        y: 0,
        w: 10,
        h: 10,
      };
      window.showSuccessSnackbar("Start Active Learning ...");
      if (tool.toolConfig.name.includes("alm_")) {
        Backend.activeLearningSignalR(
          {
            name: tool.toolConfig.name,
            projectId: projectId,
            fileIds: [fileId],
            parameters: previewRect,
            preview: false,
            rect: null,
            project: this.createProjectModel("none"),
          },
          (alModel) => {
            // after active learning --> rois are sorted by uncertainty
            this.getSelectedObjects(alModel, fileId, true);
            this.saveFunction();
            if (this._isMounted) this.forceUpdate();
          },
          (error) => {
            window.openErrorDialog(error);
          }
        );
      }
    }
  }

  setAlruns(x) {
    this.setMountedState({ alruns: x, activeTool: Tools.NONE });
  }

  trainingWarning = (warning) => {
    // give snackbar warnings
    if (warning === "subtype") {
      window.showWarningSnackbar("Please switch to parent.");
    } else if (warning === "tooFewAnnotations") {
      window.showWarningSnackbar(
        "One subtype needs at least 5 objects to start training. And one other subtype needs at least one object."
      );
    } else if (warning === "hasNoChilds") {
      window.showWarningSnackbar("This structure has no classes.");
    }
  };

  trainingProgress = (message) => {
    if (
      this.tools[this.state.activeTool] &&
      (this.tools[this.state.activeTool]["name"] === "AI Training" ||
        this.tools[this.state.activeTool]["name"] === "Instance Segmentation" ||
        this.tools[this.state.activeTool]["name"] === "Classification")
    ) {
      this.tools[this.state.activeTool].updateTrainingProgress(message);
      if (message.includes("training done!!!")) this.props.spinloader.hide();
      else if (message.includes("No annotations in")) {
        window.showWarningSnackbar(message);
      } else if (message.includes("Continue training with")) {
        window.showSuccessSnackbar(message);
      } else if (message.includes("Train model from scratch")) {
        window.showSuccessSnackbar(message);
      } else if (message.includes("time elapsed")) this.props.spinloader.hide();
      else if (
        message.includes(
          "No trainingdata, please annotate at least 15+ objects"
        )
      ) {
        window.showErrorSnackbar(message);
        this.props.spinloader.hide();
      }
    }
  };

  galleryTrainingProgress = (message) => {
    // get training progress for gallery from backend messages
    if (this.gallery) {
      this.gallery.setProgress(message);
    }
  };

  setGridSize = (x) => {
    this.setMountedState({ gridSize: x });
  };

  /**
   * Writes all ROIs into both the parent (sub-) structure (as usual) as well
   * as the corresponding subtypes.
   * This causes a considerable overhead for the filesize, but removing it can
   * cause issues when training.
   */
  adjustRoiLayers = () => {
    // make all subtype rois in subtype roilayers
    const { structures, roiLayers } = this.props.projectContext;

    for (let file of this.state.project.files) {
      let fileId = file.id;
      if (typeof roiLayers[fileId] === "undefined") {
        continue;
      }
      // make subtype rois in subtype layers
      structures.forEach((element, idx) => {
        if (structures[idx].classificationSubtype) {
          let childIds = [];
          let childLabels = [];
          this.findAllSubtypes(structures[idx]).forEach((element) => {
            childIds.push(element.id);
            childLabels.push(element.label);
          });

          let parentLayer = this.getParentIndexLayer(structures[idx]);
          let subtypeLayer = structures.findIndex(
            (element) => element.id === structures[idx].id
          );

          if (
            roiLayers[fileId][parentLayer] &&
            roiLayers[fileId][subtypeLayer]
          ) {
            roiLayers[fileId][parentLayer].layer.regionRois.forEach((roi) => {
              if (
                roi.structureId === element.id ||
                childIds.includes(roi.structureId) ||
                childLabels.includes(roi.subtypeName)
              ) {
                roiLayers[fileId][subtypeLayer].layer.regionRois.push(roi);
              }
            });
          }
        }
      });
    }
  };

  findChilds = (str) => {
    const { structures } = this.props.projectContext;
    // return direct classification subtypes
    return structures.filter(
      (element) =>
        element.subtypeLevel === str.subtypeLevel + 1 &&
        element.parentId === str.id &&
        element.classificationSubtype
    );
  };

  findAllSubtypes = (structure) => {
    // get all subtypes of selectedLayer (also subsubtypes)

    // first get all direct childs
    let childs = this.findChilds(structure);
    let allChilds = [];

    // search for childs of childs until no childs are checked for childs
    while (childs.length !== 0) {
      // code block to be executed
      childs = childs.concat(this.findChilds(childs[0]));
      if (!allChilds.includes(childs[0]) && childs[0].classificationSubtype) {
        allChilds.push(childs[0]);
      }
      childs.shift();
    }
    return allChilds;
  };

  /**
   * Add object key and value to string
   * @param {*} string tmp string that is used to store parts of a large object
   * @param {*} key object key that should be added
   * @param {*} value object value(s) that should be added
   * @param {*} filesByteArray array of string parts
   * @returns [string, filesByteArray] after updating
   */
  addStuff = (string, key, value, filesByteArray) => {
    string += '"' + key + '":' + JSON.stringify(value);
    let res = this.checkStringLength(string, filesByteArray);
    return res;
  };

  /**
   * check string Length and if larger then 10000 chars, update filesByteArray
   * @param {*} string tmp string that is used to store parts of a large object
   * @param {*} filesByteArray array of string parts
   * @returns [string, filesByteArray] after updating
   */
  checkStringLength = (string, filesByteArray) => {
    // if more then 10000 chars add current string part to filesByteArray
    // clear string
    if (string.length > 10000) {
      filesByteArray.push(string);
      string = "";
    }
    return [string, filesByteArray];
  };

  /**
   * finalize filesByteArray by adding the last string part
   * @param {*} string tmp string that is used to store parts of a large object
   * @param {*} filesByteArray array of string parts
   * @returns [string, filesByteArray] after updating
   */
  finalizeByteArray(string, filesByteArray) {
    filesByteArray.push(string);
    string = "";
    return [string, filesByteArray];
  }

  /**
   * convert JSON files object, that's to large for JSON.stringify() manually
   * @param {*} files JSON object
   * @returns blob
   */
  largeFilesJSONtoBlobConverter = (files) => {
    let filesByteArray = [];
    let res;
    // Manually decompose the files object, save to string, split and convert parts to byteArray
    // Issue! ByteArray get's to big at some point -> blob has to be created and updated on the go
    let tmpString = "[";
    for (let fileIdx in files) {
      let file = files[fileIdx];
      let keys = Object.keys(files[fileIdx]);
      tmpString += "{";
      for (let keyIdx in keys) {
        let key = keys[keyIdx];
        let value = file[key];
        if (key !== "annotations") {
          res = this.addStuff(tmpString, key, value, filesByteArray);
          tmpString = res[0];
          filesByteArray = res[1];
        } else {
          let annotations = value;
          // Array of Objects
          tmpString += '"annotations":[';
          for (let annoIdx in annotations) {
            // new Object
            tmpString += "{";
            let anno = annotations[annoIdx];
            let annoKeys = Object.keys(anno);
            for (let annoKeyIdx in annoKeys) {
              let annoKey = annoKeys[annoKeyIdx];
              let annoValue = anno[annoKey];
              if (annoKey !== "geoJSON") {
                res = this.addStuff(
                  tmpString,
                  annoKey,
                  annoValue,
                  filesByteArray
                );
                tmpString = res[0];
                filesByteArray = res[1];
              } else {
                tmpString += '"geoJSON":{';
                let geoJSON = annoValue;
                let geoJSONKeys = Object.keys(geoJSON);
                for (let geoJSONKeyIdx in geoJSONKeys) {
                  let paramKey = geoJSONKeys[geoJSONKeyIdx];
                  let paramValue = geoJSON[paramKey];
                  if (paramKey !== "coordinates") {
                    res = this.addStuff(
                      tmpString,
                      paramKey,
                      paramValue,
                      filesByteArray
                    );
                    tmpString = res[0];
                    filesByteArray = res[1];
                  } else {
                    tmpString += '"coordinates":[';
                    for (let polyIdx in paramValue) {
                      let poly = paramValue[polyIdx];
                      tmpString += JSON.stringify(poly);
                      if (polyIdx < paramValue.length - 1) tmpString += ",";
                      let res = this.checkStringLength(
                        tmpString,
                        filesByteArray
                      );
                      tmpString = res[0];
                      filesByteArray = res[1];
                    }
                    tmpString += "]";
                  }
                  if (geoJSONKeyIdx < geoJSONKeys.length - 1) tmpString += ",";
                }
                tmpString += "}";
              }
              if (annoKeyIdx < annoKeys.length - 1) tmpString += ",";
            }
            tmpString += "}";
            if (annoIdx < annotations.length - 1) tmpString += ",";
          }
          tmpString += "]";
        }
        if (keyIdx < keys.length - 1) tmpString += ",";
      }
      tmpString += "}";
      if (fileIdx < files.length - 1) tmpString += ",";
    }
    tmpString += "]";
    res = this.finalizeByteArray(tmpString, filesByteArray);
    tmpString = "";
    filesByteArray = res[1];

    // create blob from filesByteArray
    let blob = new Blob(filesByteArray, {
      type: "application/octet-stream",
    });
    filesByteArray = [];
    console.log("blob", blob);

    return blob;
  };

  saveWithSpinloader(projectModel, callback = this.defaultSaveCallback) {
    this.props.spinloader.show();
    setTimeout(() => {
      let files = projectModel.files.slice();

      let blob;
      try {
        blob = new Blob([JSON.stringify(files)]);
      } catch {
        console.log(
          "Files to large for JSON.stringify(). Continuing with custom function."
        );
        blob = this.largeFilesJSONtoBlobConverter(files);
      }

      let projectId = projectModel.id;
      let fileId = this.state.activeFileId;
      // empty annotations, to save only project
      for (let file of projectModel.files) {
        // save without annotations, since annotations will be saved seperately
        file.annotations = null;
      }
      if (this.state.annotationsAreReduced) {
        window.showErrorSnackbar("Sample Annotations can't be saved!");
        this.props.spinloader.hide();
        if (typeof callback === "function") {
          callback();
        }
      } else {
        Backend.saveProject(projectModel, (data) => {
          if (data.success) {
            Backend.saveAnnotations(projectId, fileId, blob, (data) => {
              this.props.spinloader.hide();
              if (data.success) {
                window.showSuccessSnackbar("Project saved succesfully!");
                if (typeof callback === "function") {
                  callback();
                }
              } else {
                window.showErrorSnackbar(
                  "Project couldn't be saved! Failed while saving annotations!"
                );
              }
            });
          } else {
            this.props.spinloader.hide();
            window.showErrorSnackbar("Project couldn't be saved!");
          }
        });
      }
    }, 0);
  }

  defaultSaveCallback() {
    console.log("Saved successfully");
  }

  saveFunction(fromExport, callback = this.defaultSaveCallback) {
    // this.props.persistentStorage.save("ActiveFileId", this.state.activeFileId);
    //Backend.saveProject(projectModel, () => { });
    this.props.persistentStorage.save(
      "SplitscreenFileIds",
      this.state.splitscreenFileIds
    );
    this.props.persistentStorage.save("ActiveFileId", this.state.activeFileId);
    const projectModel = this.createProjectModel("all", null, fromExport);
    this.saveWithSpinloader(projectModel, () => {
      window.projectHistory.clear(); //clear to prevent history syncing bugs and clean up space
      callback();
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  /***
   * Needed for ScreenshotTool
   */
  getProjectInfoString = () => {
    const project = this.state.project;
    const file = project.files.find(
      (file) => file.id === this.state.activeFileId
    );
    const fileName = file.fileName.split(".").slice(0, -1).join(".");

    // projekt_methode_filename_SceneNr_001.png
    return project.name + "_" + project.type + "_" + fileName;
  };

  onSaveScreenshot = () => {
    const file = this.state.project.files.find(
      (file) => file.id === this.state.activeFileId
    );

    let img = this.rendererdict[this.state.activeFileId].canvas;

    let ctx = img.getContext("2d");

    ctx.save();
    let a = ctx.getTransform().a;
    let d = ctx.getTransform().d;
    let e = ctx.getTransform().e;
    let f = ctx.getTransform().f;
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    let projectName = file.fileName.split(".").slice(0, -1).join(".");
    if (
      this.state.project.type.includes("HistoPointCounting") ||
      this.state.project.type.includes("HistoClassification")
    ) {
      if (projectName.includes("__")) {
        projectName = projectName.split("-").slice(0, -4).join("-");
      } else {
        projectName = projectName.split("-").slice(0, -3).join("-");
      }
    }

    let fontSize = 20;
    ctx.font = fontSize + "px Arial";

    // Box-Position
    let x = 10;
    let y = 10;
    let width = ctx.measureText(projectName).width + 10;
    let height = parseInt(fontSize, 10) + 10;

    // Draw Box-Border
    ctx.fillStyle = "black";
    let thickness = 1;
    ctx.fillRect(
      x - thickness,
      y - thickness,
      width + thickness * 2,
      height + thickness * 2
    );

    // Draw Box
    // ctx.globalAlpha = 0.8;
    ctx.fillStyle = "white";
    ctx.fillRect(x, y, width, height);

    // Add Text
    // ctx.globalAlpha = 1;
    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    ctx.fillStyle = "black";
    ctx.fillText(projectName, 15, 15);

    ctx.setTransform(a, 0, 0, d, e, f);
    ctx.restore();

    img = img.toDataURL("image/jpeg", 0.9);

    if (
      this.state.project.type.includes("HistoPointCounting") ||
      this.state.project.type.includes("HistoClassification")
    ) {
      const fileName = file.fileName.split(".").slice(0, -1).join(".");
      let lastIndex = file.sourcePath.replace(/\\/g, "/").lastIndexOf("/");
      const folderPath = file.sourcePath.substring(0, lastIndex + 1);
      Backend.saveScreenshot(
        folderPath,
        fileName,
        this.dataURItoBlob(img),
        (response) => {
          window.showSuccessSnackbar(
            "Screenshot saved here: " + response.savePath
          );
        }
      );
    } else {
      let link = document.createElement("a");

      // projekt_methode_filename_SceneNr_001.png
      let screenShotName =
        this.getProjectInfoString() + "_S" + (file.scene + 1) + ".jpg";
      link.download = screenShotName;
      link.href = img.replace("image/jpeg", "image/octet-stream");
      link.click();
    }
  };

  getMousePosition = () => {
    return [this.mousePosition.mouseX, this.mousePosition.mouseY];
  };

  zoomToRect = (rect) => {
    // zoom to rect in viewer/rendererdict[this.state.activeFileId]
    this.rendererdict[this.state.activeFileId].zoomFit();
    //this.rendererdict[this.state.activeFileId].zoomToRect();
    let width = rect.right - rect.left;
    let height = rect.bottom - rect.top;
    this.createZoomObjectForRect(
      false,
      rect.left - width,
      rect.right + width,
      rect.top - height,
      rect.bottom + height
    );
  };

  moveToRect = (rect) => {
    // make roi/rect in center of screen
    if (!this.rendererdict[this.state.activeFileId]) {
      setTimeout(() => this.moveToRect(), 400);
    } else {
      this.rendererdict[this.state.activeFileId].centerRoi(rect);
    }
  };

  onZoomOriginal = () => {
    this.rendererdict[this.state.activeFileId].zoomOriginal();
    if (this.state.showFullscreen) {
      if (this.state.fullscreenChain) {
        for (const value of Object.values(this.state.fullscreenFileIds)) {
          this.rendererdict["Full" + value].zoomOriginal();
        }
      } else {
        this.rendererdict["Full" + this.state.activeFileId].zoomOriginal();
      }
    }
  };

  onZoomOneToN = (x) => {
    this.rendererdict[this.state.activeFileId].zoomOneToN(x);
    if (this.state.showFullscreen) {
      if (this.state.fullscreenChain) {
        for (const value of Object.values(this.state.fullscreenFileIds)) {
          this.rendererdict["Full" + value].zoomOneToN(x);
        }
      } else {
        this.rendererdict["Full" + this.state.activeFileId].zoomOneToN(x);
      }
    }
  };

  fullscreenInitFilePlacement = (pt, fileId, factor, fs = false) => {
    const r = fs
      ? this.rendererdict["Full" + fileId]
      : this.rendererdict[fileId];

    let o = this.props.tiles.getTransformationOffset(fileId);

    r.ctx.setTransform(1, 0, 0, 1, 0, 0);
    r.ctx.translate(pt.x, pt.y);
    r.ctx.translate(-o[0] * factor, -o[1] * factor);
    r.ctx.scale(factor, factor);
  };

  onZoomFit = () => {
    if (this.state.showFullscreen) {
      if (
        this.state.fullscreenChain &&
        this.state.fullscreenFileIds.length === 2
      ) {
        let pt = null;
        let factor = null;

        for (const value of Object.values(this.state.fullscreenFileIds)) {
          let m = this.props.tiles.getTransformationMatrix(value);
          this.rendererdict["Full" + value].zoomFit();
          if (!Array.isArray(m)) {
            pt = this.rendererdict["Full" + value].getPointInCanvasCoord(0, 0);
            factor = this.rendererdict["Full" + value].state.initialScale;
          }
        }
        for (const value of Object.values(this.state.fullscreenFileIds)) {
          let m = this.props.tiles.getTransformationMatrix(value);
          if (Array.isArray(m) && pt !== null && factor !== null) {
            this.fullscreenInitFilePlacement(pt, value, factor, true);
          }
        }
      } else {
        this.rendererdict["Full" + this.state.activeFileId].zoomFit();
      }
    } else {
      this.rendererdict[this.state.activeFileId].zoomFit();
      if (Object.keys(this.chainListFileIds).length > 1) {
        for (const value of Object.values(this.chainListFileIds)) {
          if (value !== this.props.activeFileId)
            this.rendererdict[value].zoomFit();
        }
      }
      let pt = null;
      let factor = null;

      for (const value of Object.values(this.state.splitscreenFileIds)) {
        let m = this.props.tiles.getTransformationMatrix(value);
        if (!Array.isArray(m)) {
          pt = this.rendererdict[value].getPointInCanvasCoord(0, 0);
          factor = this.rendererdict[value].state.initialScale;
        }
      }
      for (const value of Object.values(this.state.splitscreenFileIds)) {
        let m = this.props.tiles.getTransformationMatrix(value);
        if (Array.isArray(m) && pt !== null && factor !== null) {
          this.fullscreenInitFilePlacement(pt, value, factor);
        }
      }
    }
  };

  onZoomDelta = (delta) => {
    this.rendererdict[this.state.activeFileId].zoomDelta(delta);
    if (this.state.showFullscreen) {
      if (this.state.fullscreenChain) {
        for (const value of Object.values(this.state.fullscreenFileIds)) {
          this.rendererdict["Full" + value].zoomDelta(delta);
        }
      } else {
        this.rendererdict["Full" + this.state.activeFileId].zoomDelta(delta);
      }
    }
  };

  onSaveClick = (callback) => {
    this.saveFunction(null, callback);
  };

  onSaveGalleryClick = () => {
    this.saveFunction();
  };

  histogramChanged(histogramConfig) {
    this.rendererdict[this.state.activeFileId].updateFLChannels(
      histogramConfig
    );
  }

  toggleSideBar = () => {
    if (this.state.sideBarWidth > 0) {
      this.setMountedState({
        originalSideBarWidth: this.state.sideBarWidth,
        sideBarWidth: 0,
      });
    } else {
      this.setMountedState({
        sideBarWidth: this.state.originalSideBarWidth,
      });
    }
    this.updateDimensions();
    this.props.spinloader.setRightWidth(
      this.state.sideBarWidth +
        this.state.verticalToolBarWidth +
        this.state.classRoomChatWidth
    );
  };

  toggleClassRoomChat = () => {
    if (this.state.classRoomChatWidth === 0) {
      this.setMountedState({
        classRoomChatWidth: 300,
      });
    } else {
      this.setMountedState({
        classRoomChatWidth: this.state.classRoomChatWidth > 0 ? 0 : 300,
      });
    }
    this.updateDimensions();
    this.props.spinloader.setRightWidth(
      this.state.sideBarWidth +
        this.state.verticalToolBarWidth +
        this.state.classRoomChatWidth
    );
  };

  mouseWheelEvent = (e) => {
    if (e.ctrlKey) {
      this.setMountedState({
        opacity: Math.min(1, Math.max(0, this.state.opacity + e.deltaY / 1000)),
      });
    }
    if (
      typeof this.tools[this.state.activeTool] !== "undefined" &&
      this.tools[this.state.activeTool].mouseWheelEvent
    )
      this.tools[this.state.activeTool].mouseWheelEvent(e);
  };

  showGrid = () => {
    // check if show tile name in rois --> only if project contains tiles tool
    return this.state.project
      ? this.checkToolInConfig("TilesTool")
        ? true
        : false
      : false;
  };

  resizeSideBar = (deltaX, resizeEnd) => {
    let newSideBarWidth = this.state.sideBarWidth + deltaX;
    if (
      newSideBarWidth > this.state.minSideBarWidth &&
      window.innerWidth - newSideBarWidth > this.state.minRendererWidth
    ) {
      this.setMountedState({ sideBarWidth: newSideBarWidth });
      this.props.persistentStorage.save("sideBarWidth", newSideBarWidth);
    }
    if (resizeEnd) {
      this.updateDimensions();
    }
  };

  resizeTimeLine = (deltaY, resizeEnd) => {
    let newTimeLineHeight = this.state.timeLineHeight + deltaY;
    if (
      newTimeLineHeight > this.state.minTimeLineHeight &&
      window.innerHeight - newTimeLineHeight - 64 > this.state.minCanvasHeight
    ) {
      this.setMountedState({ timeLineHeight: newTimeLineHeight });
      this.props.persistentStorage.save("timeLineHeight", newTimeLineHeight);
    }
    if (resizeEnd) {
      this.updateDimensions();
    }
  };

  updateToolBarWidth = (newToolBarWidth, galleryView) => {
    if (this.state.verticalToolBarWidth !== newToolBarWidth) {
      this.setMountedState({
        verticalToolBarWidth: newToolBarWidth,
      });
      this.updateDimensions();
    } else if (galleryView && this.state.verticalToolBarWidth !== 45) {
      this.setMountedState({
        verticalToolBarWidth: 45,
      });
      this.updateDimensions();
    }
  };

  isElementVisible = (el) => {
    // check if element was visible in previous use of project
    // check saved value of element
    let visible = this.props.persistentStorage.load(el);

    // if undefined make element visible
    if (typeof visible === "undefined") {
      return el === "showResultTable" || el === "showZoomBar" ? false : true;
    } else {
      return visible;
    }
  };

  setSideBarWidth = () => {
    // set sideBarWidth depending on project module
    // get saved SideBarWith from local storage
    let sideBarWidthObject = this.props.persistentStorage.load("sideBarWidth");
    let sideBarWidthFromConfig =
      this.state.viewerConfig.project.projectStringProperties["SideBarWidth"];
    let sideBarWidth;
    // check if special project module
    if (this.resultTabActive()) {
      if (this.state && this.state.verticalToolBarWidth) {
        sideBarWidth =
          window.innerWidth - this.state.verticalToolBarWidth - 5 - 900; // subtract verticalToolBar, Padding and Renderer
      } else {
        sideBarWidth = window.innerWidth - 45 - 5 - 900; // subtract verticalToolBar, Padding and Renderer
      }
    } else {
      if (sideBarWidthObject) {
        // if sideBarWidth was saved to persistentStorage
        sideBarWidth = sideBarWidthObject;
      } else {
        // check if sideBarWidth is set in config --> if not set default value 455
        sideBarWidth =
          sideBarWidthFromConfig && sideBarWidthFromConfig !== ""
            ? parseInt(sideBarWidthFromConfig, 10)
            : 455;
      }
    }
    this.setMountedState({ sideBarWidth });
    this.updateDimensions();
  };

  getProjectLabel = () => {
    if (this.state && this.state.viewerConfig) {
      return this.state.viewerConfig.project.label;
    }
  };

  saveChainStatus = () => {
    this.props.persistentStorage.save("chainList", this.chainList);
    this.props.persistentStorage.save(
      "chainListFileIds",
      this.chainListFileIds
    );
  };

  onSelectFileSplitscreen = (fileId) => {
    //get all ome and histoconfig prepared
    let { splitscreenFileIds } = this.state;
    if (splitscreenFileIds.length < 9) {
      this.addFileToFullscreen(fileId, 0);
      splitscreenFileIds.push(fileId);
      this.props.persistentStorage.save("ActiveFileId", fileId);
      this.props.persistentStorage.save(
        "SplitscreenFileIds",
        splitscreenFileIds
      );
      this.openFile(fileId, this.props.id, true);
      this.setMountedState({
        splitscreenFileIds,
        activeFileId: fileId,
      });
      this.updateDimensions();
    } else {
      window.showErrorSnackbar(
        "A maximum of 9 images within the splitscreen is allowed"
      );
    }
  };

  onSwitchFileSplitscreen = (newFileId) => {
    let { activeFileId, splitscreenFileIds } = this.state;

    let rendererRef = this.rendererdict[activeFileId];
    delete this.rendererdict[activeFileId];
    this.rendererdict[newFileId] = rendererRef;

    let index = splitscreenFileIds.indexOf(activeFileId);
    splitscreenFileIds[index] = newFileId;
    activeFileId = newFileId;
    this.openFile(activeFileId, this.props.id, true);
    this.props.persistentStorage.save("ActiveFileId", activeFileId);
    this.props.persistentStorage.save("SplitscreenFileIds", splitscreenFileIds);

    this.chainList[index] = false;
    delete this.chainListFileIds[index];
    this.saveChainStatus();

    this.setMountedState({
      splitscreenFileIds,
      activeFileId,
    });
  };

  onExcludeSplitscreen = (index, e) => {
    let { splitscreenFileIds, activeFileId } = this.state;

    if (activeFileId === splitscreenFileIds[index]) {
      splitscreenFileIds.splice(index, 1);
      activeFileId = splitscreenFileIds[Math.max(index - 1, 0)];
    } else {
      splitscreenFileIds.splice(index, 1);
    }
    this.props.persistentStorage.save("ActiveFileId", activeFileId);
    this.props.persistentStorage.save("SplitscreenFileIds", splitscreenFileIds);
    this.setMountedState({
      splitscreenFileIds,
      activeFileId,
    });

    this.chainList[index] = false;
    delete this.chainListFileIds[index];
    this.saveChainStatus();
    this.updateDimensions();
    e.stopPropagation();
    return false;
  };

  onChainSplitscreen = (index, currentFileId, e) => {
    if (!this.chainList[index]) {
      this.chainList[index] = true;
      this.chainListFileIds[index] = currentFileId;
      this.saveChainStatus();
    } else if (this.chainList[index]) {
      this.chainList[index] = false;
      delete this.chainListFileIds[index];
      this.saveChainStatus();
    }
    if (e) {
      e.stopPropagation();
    }
  };

  onChangeChain = (bool, index, fileId) => {
    this.chainList[index] = bool;
    this.chainListFileIds[index] = fileId;
    if (!bool) {
      delete this.chainListFileIds[index];
    }
    this.saveChainStatus();
  };

  onDropFile = (event) => {
    event.preventDefault();
    let id = event.dataTransfer.getData("Text");
    for (let i = 0; i < this.state.project.files.length; i++) {
      if (this.state.project.files[i].id === id) {
        this.onSelectFileSplitscreen(id);
      }
    }
    this.setMountedState({ showSplitscreenDropzone: false });
  };

  setActiveView = (index) => {
    let { splitscreenFileIds, activeFileId } = this.state;
    if (activeFileId !== splitscreenFileIds[index]) {
      activeFileId = splitscreenFileIds[index];
    }
    this.addFileToFullscreen(activeFileId, 0);
    this.props.persistentStorage.save("ActiveFileId", activeFileId);
    this.setMountedState({
      activeFileId,
    });
  };

  onToggleFullscreen = () => {
    if (this.state.showFullscreen) {
      this.setMountedState({ showOverlay: false, showWindowTool: false });
      this.setOpacityChangeFullscreen(0, 100);
      this.setOpacityChangeFullscreen(1, 0);
    }
    this.setMountedState({ showFullscreen: !this.state.showFullscreen });
    this.updateDimensions();
  };

  setOpacityChangeFullscreen = (id, value) => {
    if (id === 0) {
      this.setMountedState({ firstFullscreenOpacity: value });
    } else {
      this.setMountedState({ secFullscreenOpacity: value });
    }
  };

  addFileToFullscreen = (fileId, idx) => {
    let { fullscreenFileIds } = this.state;
    if (
      (idx === 0 && fullscreenFileIds[1] === fileId) ||
      (idx === 1 && fullscreenFileIds[0] === fileId)
    ) {
      let firstRef = this.rendererdict["Full" + fullscreenFileIds[0]];
      let secRef = this.rendererdict["Full" + fullscreenFileIds[1]];
      let firstId = fullscreenFileIds[0];
      let secId = fullscreenFileIds[1];
      this.rendererdict["Full" + fullscreenFileIds[1]] = firstRef;
      this.rendererdict["Full" + fullscreenFileIds[0]] = secRef;
      fullscreenFileIds[0] = secId;
      fullscreenFileIds[1] = firstId;
    } else if (fullscreenFileIds[idx] && fullscreenFileIds[idx] !== fileId) {
      let rendererRef = this.rendererdict["Full" + fullscreenFileIds[idx]];
      delete this.rendererdict["Full" + fullscreenFileIds[idx]];
      this.rendererdict["Full" + fileId] = rendererRef;
      fullscreenFileIds[idx] = fileId;
    }
    if (fullscreenFileIds.length === 0 || fullscreenFileIds.length === 1) {
      fullscreenFileIds[idx] = fileId;
    }

    if (this.sliderRef) {
      this.sliderRef.setState({ init: false });
    }
    this.setMountedState({
      fullscreenFileIds,
    });
  };

  setActiveFileFullscreen = (fileId) => {
    this.setMountedState({ activeFileId: fileId });
  };

  onToggleFullscreenChain = () => {
    this.setMountedState({ fullscreenChain: !this.state.fullscreenChain });
  };

  onToggleOverlay = () => {
    this.setMountedState({
      showOverlay: !this.state.showOverlay,
      showWindowTool: false,
    });
  };

  onToggleWindowTool = () => {
    this.setMountedState({ showWindowTool: !this.state.showWindowTool });
  };

  toggleShowFirstAccordion = () => {
    this.setMountedState({
      showFirstAccordion: !this.state.showFirstAccordion,
    });
  };

  /**
   * Saves those structures to which AI results will be saved to.
   * @param {object} obj Structure information including the model used.
   * @param {string} task What to do with a given structure. Supports "add" and "remove_all".
   */
  setAiUsedStructures = (obj, task) => {
    switch (task) {
      // Add a strucuture with an assigend model
      case "add":
        {
          let aiStructures = this.state.aiUsedStructures;
          if (!aiStructures.filter((e) => e.id === obj.id).length > 0) {
            aiStructures.push(obj);
          }
          this.setMountedState({ aiUsedStructures: aiStructures });
        }
        break;

      // Remove everything
      case "remove_all":
        this.setMountedState({ aiUsedStructures: [] });
        break;

      default:
        console.warn(`Invalid task provided to setAiUsedStructures(): ${task}`);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      verticalToolBarWidth,
      sideBarWidth,
      classRoomChatWidth,
      activeTool,
      project,
      containerKey,
      // view layout flags
      showGallery,
      show3DViewer,
      showPointCloud,
      showTilesGallery,
      showTimeBar,
      displayTimeBar,
      showZStackBar,
      displayZStackBar,
      drawLayer,
      commentLayers,
      landmarkLayers,
      opacity,
      importProgress,
      splitscreenFileIds,
      showSplitscreenDropzone,
      activeFileId,
      omeDict,
      minZ,
      maxZ,
      fullscreenFileIds,
      histogramConfig,
      showFullscreen,
      showOverlay,
      showWindowTool,
    } = this.state;

    const {
      structures,
      roiLayers,
      //fileRoiLayers,
      selectedLayer,
    } = this.props.projectContext;

    return (
      <Grid container className={classes.root} key={containerKey}>
        <FindFilesDialog
          ref={(c) => (this.FindMissingFiles = c)}
          projectActionMode={ProjectActionMode.Update}
        />
        <Grid
          container
          className={classes.mainContainer}
          style={{
            position: "relative",
            height: "100%",
            flexBasis:
              "calc(100% - " +
              (verticalToolBarWidth + sideBarWidth + classRoomChatWidth) +
              "px)",
            overflowY:
              showGallery || showTilesGallery
                ? this.state.scrollGallery
                : "inherit",
            overflowX: "inherit",
          }}
          onWheel={this.mouseWheelEvent}
          onDragEnter={
            this.state &&
            (() => {
              if (
                this.state.viewerConfig.project.projectProperties[
                  "EnableSplitscreen"
                ]
              )
                this.setMountedState({ showSplitscreenDropzone: true });
            })
          }
          onDragLeave={() => {
            this.setMountedState({ showSplitscreenDropzone: false });
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            if (
              this.state.viewerConfig.project.projectProperties[
                "EnableSplitscreen"
              ]
            )
              this.onDropFile(e);
          }}
        >
          {showSplitscreenDropzone && (
            <div className={classes.dropfieldRoot}>
              Drop file here for split screen.
            </div>
          )}
          {!showGallery &&
            !showTilesGallery &&
            !showFullscreen &&
            splitscreenFileIds.map((currentFileId, index) => (
              <Grid
                item
                key={index}
                xs={
                  splitscreenFileIds.length === 4
                    ? 6
                    : splitscreenFileIds.length > 2
                    ? 4
                    : splitscreenFileIds.length > 1
                    ? 6
                    : 12
                }
                style={{
                  border: splitscreenFileIds.length > 1 && "1px solid",
                  position: "relative",
                  background: "#EBEBEB",
                  height:
                    splitscreenFileIds.length === 4
                      ? "50%"
                      : splitscreenFileIds.length > 6
                      ? "33.333333333%"
                      : splitscreenFileIds.length > 3
                      ? "50%"
                      : "100%",
                  zIndex: currentFileId === activeFileId ? "0" : "1",
                }}
                onClick={() => this.setActiveView(index, currentFileId)}
              >
                <Dialog
                  open={importProgress > -1 && importProgress < 100}
                  hideBackdrop
                  style={{ pointerEvents: "none" }}
                >
                  <DialogTitle>
                    Importing File: <br />
                    &quot;
                    {this.state.project &&
                      this.state.project.files.find(
                        (file) => file.id === this.state.activeFileId
                      ).fileName}
                    &quot;
                  </DialogTitle>
                  <LinearProgress
                    variant="determinate"
                    value={importProgress}
                    style={{ height: 10 }}
                  />
                </Dialog>
                {/* ADD 3D-Viewer-Component!!! */}
                {show3DViewer &&
                  !showGallery &&
                  !showTilesGallery &&
                  omeDict[currentFileId] &&
                  this.zoomObjectDict &&
                  histogramConfig[currentFileId] && (
                    <React.Fragment>
                      {currentFileId === activeFileId &&
                        splitscreenFileIds.length > 1 && (
                          <div className={classes.activeIndicatorBorder} />
                        )}
                      {splitscreenFileIds.length > 1 && (
                        <Tooltip disableInteractive title={"Exclude Scene"}>
                          <IconButton
                            className={classes.excludeButton}
                            style={{ color: "#666" }}
                            size="small"
                            onClick={(e) => this.onExcludeSplitscreen(index, e)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {splitscreenFileIds.length > 1 && (
                        <Tooltip disableInteractive title={"Chain Scene"}>
                          <IconButton
                            className={classes.attachButton}
                            style={{ color: "#666" }}
                            size="small"
                            onClick={(e) =>
                              this.onChainSplitscreen(index, currentFileId, e)
                            }
                          >
                            {this.chainList[index] ? (
                              <LinkIcon style={{ color: "#0673C1" }} />
                            ) : (
                              <LinkOffIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                      <Viewer3D
                        fileId={currentFileId}
                        ome={omeDict[currentFileId]}
                        projectId={this.props.id}
                        minZ={minZ}
                        maxZ={maxZ}
                        showPointCloud={showPointCloud}
                      />
                    </React.Fragment>
                  )}
                {!showGallery &&
                  !show3DViewer &&
                  !showTilesGallery &&
                  omeDict[currentFileId] &&
                  this.zoomObjectDict &&
                  histogramConfig[currentFileId] && (
                    <React.Fragment>
                      {currentFileId === activeFileId &&
                        splitscreenFileIds.length > 1 && (
                          <div className={classes.activeIndicatorBorder} />
                        )}
                      {splitscreenFileIds.length > 1 && (
                        <Tooltip disableInteractive title={"Exclude Scene"}>
                          <IconButton
                            className={classes.excludeButton}
                            style={{ color: "#666" }}
                            size="small"
                            onClick={(e) => this.onExcludeSplitscreen(index, e)}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {splitscreenFileIds.length > 1 && (
                        <Tooltip disableInteractive title={"Chain Scene"}>
                          <IconButton
                            className={classes.attachButton}
                            size="small"
                            onClick={(e) =>
                              this.onChainSplitscreen(index, currentFileId, e)
                            }
                          >
                            {this.chainList[index] ? (
                              <LinkIcon style={{ color: "#0673C1" }} />
                            ) : (
                              <LinkOffIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                      <Renderer
                        dimensionsUpdated={this.state.dimensionsUpdated}
                        rightSpace={
                          sideBarWidth +
                          verticalToolBarWidth +
                          classRoomChatWidth
                        }
                        splitscreenCount={splitscreenFileIds.length}
                        isChained={this.chainList[index]}
                        chainListFileIds={this.chainListFileIds}
                        splitscreenFileIds={splitscreenFileIds}
                        isActive={currentFileId === activeFileId}
                        canvasId={"mainCanvas" + index}
                        componentRef={(c) =>
                          (this.rendererdict[currentFileId] = c)
                        }
                        rendererDict={this.rendererdict}
                        projectConfig={this.state.projectConfig}
                        ome={omeDict[currentFileId]}
                        histogramConfig={histogramConfig[currentFileId]}
                        rois={this.state.rois}
                        fileId={currentFileId}
                        activeFileId={activeFileId}
                        projectId={this.props.id}
                        activeTool={
                          currentFileId === activeFileId
                            ? activeTool
                            : Tools.NONE
                        }
                        onChangeROIs={(e) => this.setMountedState({ rois: e })}
                        // toggle different dialogs
                        showGallery={showGallery}
                        showTilesGallery={showTilesGallery}
                        displayTimeBar={displayTimeBar}
                        displayZStackBar={displayZStackBar}
                        showZStackBar={showZStackBar}
                        showTimeBar={showTimeBar}
                        tools={this.tools}
                        structures={structures}
                        roiLayers={roiLayers[currentFileId]}
                        allRoiLayers={roiLayers}
                        drawLayer={
                          currentFileId === activeFileId
                            ? drawLayer
                            : {
                                regionRois: [],
                                inverted: false,
                                clear: false,
                              }
                        }
                        commentLayer={commentLayers[currentFileId]}
                        landmarkLayer={landmarkLayers[currentFileId]}
                        selectedLayer={selectedLayer}
                        opacity={opacity}
                        changeHiConfig={this.setHistogramConfig}
                        updateProject={this.updateProject}
                        tzoomROI1={this.resetRoiZoomInDict}
                        zoomROI={
                          this.zoomObjectDict[currentFileId]
                            ? this.zoomObjectDict[currentFileId].zoomRoi
                            : false
                        }
                        zoomLeft={
                          this.zoomObjectDict[currentFileId]
                            ? this.zoomObjectDict[currentFileId].zoomLeft
                            : 0
                        }
                        zoomRight={
                          this.zoomObjectDict[currentFileId]
                            ? this.zoomObjectDict[currentFileId].zoomRight
                            : 0
                        }
                        zoomTop={
                          this.zoomObjectDict[currentFileId]
                            ? this.zoomObjectDict[currentFileId].zoomTop
                            : 0
                        }
                        zoomBottom={
                          this.zoomObjectDict[currentFileId]
                            ? this.zoomObjectDict[currentFileId].zoomBottom
                            : 0
                        }
                        zoomROI1={this.resetRoiZoom}
                        setSelectedRoi={this.setSelectedRoi}
                        setAIObjects={this.setAIObjects}
                        selRoi={this.state.selRoi}
                        showGridLabels={this.showGrid()}
                        viewerConfig={this.state.viewerConfig}
                        activeTab={this.props.projectContext.activeTab}
                        onChangeTool={this.onChangeTool}
                        setChangingFile={
                          this.props.projectContext.setChangingFile
                        }
                        changingFile={this.props.projectContext.changingFile}
                        project={project}
                        updateGlobalZ={this.updateGlobalZ}
                        updateGlobalT={this.updateGlobalT}
                        timeLineHeight={this.state.timeLineHeight}
                        resizeTimeLine={this.resizeTimeLine}
                        loadedAnnotationsCounter={
                          this.state.loadedAnnotationsCounter
                        }
                        frameArrayDict={this.state.frameArrayDict}
                        projectContext={this.props.projectContext}
                        setSelectedLayer={this.setSelectedLayer}
                        showMarks={activeTool === "landmark" ? true : false}
                        landmarkLayers={landmarkLayers}
                        showFullscreen={showFullscreen}
                        splitscreenIdx={index}
                        onChangeChain={this.onChangeChain}
                        fsChain={this.onToggleFullscreenChain}
                        resultTabActive={() => this.resultTabActive()}
                        onSelectFile={this.onSelectFile}
                        openNextFile={this.openNextFile}
                        openPrevFile={this.openPrevFile}
                        updateVisibleROIDebounced={
                          this.updateVisibleROIDebounced
                        }
                      />
                    </React.Fragment>
                  )}
              </Grid>
            ))}
          {!showGallery &&
            !showTilesGallery &&
            omeDict[activeFileId] &&
            this.zoomObjectDict &&
            histogramConfig[activeFileId] &&
            showFullscreen &&
            fullscreenFileIds.map((currentFileId, index) => (
              <Grid
                item
                key={"f" + index}
                style={{
                  position: "absolute",
                  background: "#EBEBEB",
                  height: "100%",
                  width: "100%",
                  opacity:
                    index === 0
                      ? this.state.firstFullscreenOpacity + "%"
                      : this.state.secFullscreenOpacity + "%",
                  pointerEvents:
                    currentFileId === activeFileId ? "auto" : "none",
                }}
              >
                <Renderer
                  dimensionsUpdated={this.state.dimensionsUpdated}
                  rightSpace={
                    sideBarWidth + verticalToolBarWidth + classRoomChatWidth
                  }
                  splitscreenCount={splitscreenFileIds.length}
                  isChained={this.state.fullscreenChain}
                  chainListFileIds={this.chainListFileIds}
                  splitscreenFileIds={fullscreenFileIds}
                  isActive={currentFileId === activeFileId}
                  canvasId={"FullscreenCanvas" + index}
                  componentRef={(c) =>
                    (this.rendererdict["Full" + currentFileId] = c)
                  }
                  rendererDict={this.rendererdict}
                  projectConfig={this.state.projectConfig}
                  ome={omeDict[currentFileId]}
                  histogramConfig={histogramConfig[currentFileId]}
                  rois={this.state.rois}
                  fileId={currentFileId}
                  activeFileId={activeFileId}
                  projectId={this.props.id}
                  activeTool={
                    currentFileId === activeFileId ? activeTool : Tools.NONE
                  }
                  onChangeROIs={(e) => this.setMountedState({ rois: e })}
                  // toggle different dialogs
                  showGallery={showGallery}
                  showTilesGallery={showTilesGallery}
                  displayTimeBar={displayTimeBar}
                  displayZStackBar={displayZStackBar}
                  showZStackBar={showZStackBar}
                  showTimeBar={showTimeBar}
                  tools={this.tools}
                  structures={structures}
                  roiLayers={roiLayers[currentFileId]}
                  allRoiLayers={roiLayers}
                  drawLayer={
                    currentFileId === activeFileId
                      ? drawLayer
                      : {
                          regionRois: [],
                          inverted: false,
                          clear: false,
                        }
                  }
                  commentLayer={commentLayers[currentFileId]}
                  landmarkLayer={landmarkLayers[currentFileId]}
                  selectedLayer={selectedLayer}
                  opacity={opacity}
                  changeHiConfig={this.setHistogramConfig}
                  updateProject={this.updateProject}
                  tzoomROI1={this.resetRoiZoomInDict}
                  zoomROI={
                    this.zoomObjectDict[currentFileId]
                      ? this.zoomObjectDict[currentFileId].zoomRoi
                      : false
                  }
                  zoomLeft={
                    this.zoomObjectDict[currentFileId]
                      ? this.zoomObjectDict[currentFileId].zoomLeft
                      : 0
                  }
                  zoomRight={
                    this.zoomObjectDict[currentFileId]
                      ? this.zoomObjectDict[currentFileId].zoomRight
                      : 0
                  }
                  zoomTop={
                    this.zoomObjectDict[currentFileId]
                      ? this.zoomObjectDict[currentFileId].zoomTop
                      : 0
                  }
                  zoomBottom={
                    this.zoomObjectDict[currentFileId]
                      ? this.zoomObjectDict[currentFileId].zoomBottom
                      : 0
                  }
                  zoomROI1={this.resetRoiZoom}
                  setSelectedRoi={this.setSelectedRoi}
                  setAIObjects={this.setAIObjects}
                  selRoi={this.state.selRoi}
                  showGridLabels={this.showGrid()}
                  viewerConfig={this.state.viewerConfig}
                  activeTab={this.props.projectContext.activeTab}
                  onChangeTool={this.onChangeTool}
                  setChangingFile={this.props.projectContext.setChangingFile}
                  changingFile={this.props.projectContext.changingFile}
                  project={project}
                  updateGlobalZ={this.updateGlobalZ}
                  updateGlobalT={this.updateGlobalT}
                  timeLineHeight={this.state.timeLineHeight}
                  resizeTimeLine={this.resizeTimeLine}
                  loadedAnnotationsCounter={this.state.loadedAnnotationsCounter}
                  frameArrayDict={this.state.frameArrayDict}
                  showMarks={activeTool === "landmark" ? true : false}
                  landmarkLayers={landmarkLayers}
                  showFullscreen={showFullscreen}
                  fullscreenIdx={index}
                  onChangeChain={this.onChangeChain}
                  fsChain={this.onToggleFullscreenChain}
                  showWindowTool={showWindowTool}
                  windowToolRef={this.windowToolRef}
                  resultTabActive={() => this.resultTabActive()}
                  updateVisibleROIDebounced={this.updateVisibleROIDebounced}
                />
              </Grid>
            ))}
          {!showGallery &&
            !showTilesGallery &&
            omeDict[activeFileId] &&
            this.zoomObjectDict &&
            histogramConfig[activeFileId] &&
            showFullscreen &&
            showOverlay && (
              <OverlaySlider
                componentRef={(c) => (this.sliderRef = c)}
                setOpacity={this.setOpacityChangeFullscreen}
                activeFileId={activeFileId}
                splitscreenFileIds={splitscreenFileIds}
                omeDict={omeDict}
                addFile={this.addFileToFullscreen}
                setActive={this.setActiveFileFullscreen}
                fullscreenFileIds={fullscreenFileIds}
                fsChain={this.onToggleFullscreenChain}
                isChained={this.state.fullscreenChain}
                rendererdict={this.rendererdict}
              ></OverlaySlider>
            )}
          {!showGallery &&
            !showTilesGallery &&
            omeDict[activeFileId] &&
            this.zoomObjectDict &&
            histogramConfig[activeFileId] &&
            showFullscreen &&
            showWindowTool && (
              <WindowTool
                componentRef={(c) => (this.windowToolRef = c)}
                activeFileId={activeFileId}
                rendererDict={this.rendererdict}
                fullscreenFileIds={fullscreenFileIds}
              ></WindowTool>
            )}
          {(showGallery || showTilesGallery) && histogramConfig[activeFileId] && (
            <Gallery
              componentRef={(c) => (this.gallery = c)}
              ome={omeDict[activeFileId]}
              fileId={activeFileId}
              projectId={this.props.id}
              structures={structures}
              roiLayers={roiLayers[activeFileId]}
              selectedLayer={selectedLayer}
              visibleImage={this.visibleImage}
              coloredImages={this.coloredImages}
              histogramConfig={histogramConfig[activeFileId]}
              gallery={this.createZoomObjectForRect}
              activeTool={activeTool}
              tools={this.tools}
              visImgs={this.state.visImgs}
              selectedObjects={this.selObjects}
              drawLayer={drawLayer}
              saveChangesGallery={this.saveChangesGallery}
              applyDL={this.applyDL}
              setApplyModelAfterTraining={this.setApplyModelAfterTraining}
              makePredictions={this.applyModelAfterTraining}
              selObjs={this.selObjs}
              updateCount={this.updateCount}
              alruns={this.state.alruns}
              setAlruns={this.setAlruns}
              graphData={this.graphData}
              updateViewer={this.updateViewer}
              onGallerychangePage={this.onGallerychangePage}
              showZStackBar={
                this.props.persistentStorage.load(
                  "showZStackBar" + activeFileId
                ) === true ||
                this.props.persistentStorage.load(
                  "showZStackBar" + activeFileId
                ) === false
                  ? this.props.persistentStorage.load(
                      "showZStackBar" + activeFileId
                    )
                  : false
              }
              viewerConfig={this.state.viewerConfig}
              startAutomaticTraining={this.startAutomaticTraining}
              updateStructure={this.state.updateStructure}
              trainingWarning={this.trainingWarning}
              setFUllyAnnotated={this.setFUllyAnnotated}
              setAnnotated={this.setAnnotated}
              showGallery={showGallery}
              showTilesGallery={showTilesGallery}
              project={project}
              onSelectLayer={(e) => {
                this.props.projectContext.setState({ selectedLayer: e });
              }}
              globalZ={this.state.z}
              updateFileClasses={this.updateFileClasses}
            />
          )}
        </Grid>
        {omeDict[activeFileId] &&
          histogramConfig[activeFileId] &&
          this.rendererdict[activeFileId] && (
            <VerticalToolBar
              annotationsAreReduced={this.state.annotationsAreReduced}
              isImporting={importProgress > -1 && importProgress < 100}
              verticalToolBarWidth={this.state.verticalToolBarWidth}
              viewerConfig={this.state.viewerConfig}
              structures={structures}
              roiLayers={roiLayers[activeFileId]}
              // tool selection
              activeTool={activeTool}
              onChangeTool={this.onChangeTool}
              // layer selection
              selectedLayer={selectedLayer}
              // toggle different dialogs
              showGallery={showGallery}
              filesGalleryActive={
                showGallery &&
                this.state.viewerConfig.project.projectStringProperties[
                  "ViewerType"
                ] === "FilesGallery"
              }
              onToggleGallery={(e) => {
                this.setMountedState({
                  showGallery: e,
                  showTilesGallery: false,
                });
                if (showTilesGallery) {
                  this.toggleSideBar();
                }
                if (showGallery) {
                  this.updateDimensions();
                  if (this.resultTabActive()) {
                    this.onChangeTool("pointcountingtile");
                  }
                }
                if (this.state.project.type.includes("HistoPointCounting")) {
                  // make parent to selectedLayer again
                  let parentIndex = selectedLayer;
                  if (structures[selectedLayer].classificationSubtype) {
                    parentIndex = this.getParentIndex(
                      structures[selectedLayer].parentId
                    );
                  }
                  this.props.projectContext.setState({
                    selectedLayer: parentIndex,
                  });
                  // hide sidebar
                  this.toggleSideBar();
                }
              }}
              changeToSelectedFile={this.changeToSelectedFile}
              showTilesGallery={showTilesGallery}
              // show 3D Viewer
              show3DViewer={show3DViewer}
              onToggle3DViewer={(e) => {
                this.toggleSideBar();
                this.saveFunction(null, () => {
                  console.log("SaveFunction Callback");
                  this.setMountedState({
                    show3DViewer: e,
                    showGallery: false,
                    showTilesGallery: false,
                  });
                });
              }}
              showPointCloud={showPointCloud}
              onToggleMeshView={(e) => {
                this.setMountedState({ showPointCloud: e });
              }}
              onToggleTilesGallery={(e) => {
                this.setMountedState({
                  showTilesGallery: e,
                  showGallery: false,
                });
                this.toggleSideBar();
              }}
              onToggleTimeBar={() => {
                this.setMountedState({ showTimeBar: !this.state.showTimeBar });
                this.updateDimensions();
              }}
              onSaveScreenshot={this.onSaveScreenshot}
              onZoomOriginal={this.onZoomOriginal}
              onZoomOneToN={this.onZoomOneToN}
              onZoomFit={this.onZoomFit}
              // save project
              onSave={this.onSaveClick}
              //save gallery images
              onSaveGallery={this.onSaveGalleryClick}
              alruns={this.state.alruns}
              onZoomDelta={this.onZoomDelta}
              applyDL={this.applyDL}
              tools={this.tools}
              fileId={activeFileId}
              projectId={this.props.id}
              saveChangesGallery={this.saveChangesGallery}
              project={this.state.project}
              setGalleryTool={this.setGalleryTool}
              setAlruns={this.setAlruns}
              trainingWarning={this.trainingWarning}
              onToggleSideBar={this.toggleSideBar}
              resizeSideBar={this.resizeSideBar}
              updateToolBarWidth={this.updateToolBarWidth}
              ome={omeDict[activeFileId]}
              activeTab={this.props.projectContext.activeTab}
              splitscreenCount={splitscreenFileIds.length}
              displayTimeBar={displayTimeBar}
              displayZStackBar={displayZStackBar}
              rendererRef={
                showFullscreen
                  ? this.rendererdict["Full" + activeFileId]
                  : this.rendererdict[activeFileId]
              }
              onToggleFullscreen={this.onToggleFullscreen}
              showFullscreen={showFullscreen}
              onToggleOverlay={this.onToggleOverlay}
              showOverlay={showOverlay}
              onToggleWindowTool={this.onToggleWindowTool}
              showWindowTool={showWindowTool}
              aiUsedStructures={this.state.aiUsedStructures}
            />
          )}
        {omeDict[activeFileId] && histogramConfig[activeFileId] && (
          <SideBar
            componentRef={(c) => (this.sidebar = c)}
            key={activeFileId}
            sideBarWidth={sideBarWidth}
            project={project}
            alterStructure={this.alterStructure}
            structures={structures}
            roiLayers={roiLayers[activeFileId]}
            // tool selection
            activeTool={activeTool}
            onChangeTool={this.onChangeTool}
            // layer selection
            selectedLayer={selectedLayer}
            // toggle different dialogs
            showGallery={showGallery}
            onToggleGallery={(e) => {
              this.setMountedState({ showGallery: e, showTilesGallery: false });
              if (showTilesGallery) {
                this.toggleSideBar();
              }
              if (showGallery) {
                this.updateDimensions();
                if (this.resultTabActive()) {
                  this.onChangeTool("pointcountingtile");
                }
              }
              if (this.state.project.type.includes("HistoPointCounting")) {
                // make parent to selectedLayer again
                let parentIndex = selectedLayer;
                if (structures[selectedLayer].classificationSubtype) {
                  parentIndex = this.getParentIndex(
                    structures[selectedLayer].parentId
                  );
                }
                this.props.projectContext.setState({
                  selectedLayer: parentIndex,
                });
                // hide sidebar
                this.toggleSideBar();
              }
            }}
            showTilesGallery={showTilesGallery}
            // show 3D Viewer
            show3DViewer={show3DViewer}
            onToggle3DViewer={(e) => {
              this.saveFunction(null, () => {
                console.log("SaveFunction Callback");
                this.setMountedState({
                  show3DViewer: e,
                  showGallery: false,
                  showTilesGallery: false,
                });
              });
            }}
            showPointCloud={showPointCloud}
            onToggleMeshView={(e) => {
              this.setMountedState({ showPointCloud: e });
            }}
            onToggleTilesGallery={(e) => {
              this.setMountedState({ showTilesGallery: e, showGallery: false });
              this.toggleSideBar();
            }}
            onToggleTimeBar={() => {
              this.setMountedState({ showTimeBar: !this.state.showTimeBar });
              this.updateDimensions();
            }}
            onSaveScreenshot={this.onSaveScreenshot}
            onZoomOriginal={this.onZoomOriginal}
            onZoomOneToN={this.onZoomOneToN}
            onZoomFit={this.onZoomFit}
            // save project
            onSave={this.onSaveClick}
            //save gallery images
            onSaveGallery={this.onSaveGalleryClick}
            alruns={this.state.alruns}
            onZoomDelta={this.onZoomDelta}
            applyDL={this.applyDL}
            tools={this.tools}
            fileId={activeFileId}
            projectId={this.props.id}
            saveChangesGallery={this.saveChangesGallery}
            setGalleryTool={this.setGalleryTool}
            setAlruns={this.setAlruns}
            trainingWarning={this.trainingWarning}
            onToggleSideBar={this.toggleSideBar}
            resizeSideBar={this.resizeSideBar}
            ome={omeDict[activeFileId]}
            activeTab={this.props.projectContext.activeTab}
            splitscreenCount={splitscreenFileIds.length}
            displayTimeBar={displayTimeBar}
            displayZStackBar={displayZStackBar}
            rendererRef={this.rendererdict[activeFileId]}
            onToggleFullscreen={this.onToggleFullscreen}
            showFullscreen={showFullscreen}
            viewerConfig={this.state.viewerConfig}
            splitscreenFileIds={splitscreenFileIds}
            onSelectFile={this.onSelectFile}
            onExcludeFilesToggle={this.onExcludeFilesToggle}
            // meta data
            histogramConfig={histogramConfig}
            onChangeChannels={(e) => this.histogramChanged(e)}
            id={this.props.id}
            // roi list
            rois={this.state.rois}
            onCenterROI={(roi) =>
              this.rendererdict[activeFileId].centerROI(roi)
            }
            onHoverROI={(roi, en) =>
              this.rendererdict[activeFileId].hoverROI(roi, en)
            }
            // tool parameters
            tool={this.tools[this.state.activeTool]}
            // import export parametersets
            onExportParameters={this.onExportParameters}
            onImportParameters={this.onImportParameters}
            allRoiLayers={roiLayers}
            deleteforAllScenes={this.deleteforAllScenes}
            updateViewer={this.updateViewer}
            onChangeLayers={() => {
              if (this._isMounted) this.forceUpdate();
            }}
            onSelectLayer={(e, callback) => {
              this.props.projectContext.setState(
                { selectedLayer: e },
                callback
              );
            }}
            opacity={opacity}
            onChangeOpacity={(e) => this.setMountedState({ opacity: e })}
            setSelectedLayer={this.setSelectedLayer}
            updateStructureChange={this.updateStructureChange}
            setAIFormData={this.setAIFormData}
            formDataAICockpit={this.state.formDataAICockpit}
            setSideBarWidth={this.setSideBarWidth}
            onUpdateDimensions={() => this.updateDimensions()}
            showFirstAccordion={this.state.showFirstAccordion}
            toggleShowFirstAccordion={this.toggleShowFirstAccordion}
            aiUsedStructures={this.state.aiUsedStructures}
            setAiUsedStructures={this.setAiUsedStructures}
          />
        )}
        <ClassroomChat classRoomChatWidth={this.state.classRoomChatWidth} />
      </Grid>
    );
  }
}

Viewer.propTypes = {
  classes: PropTypes.object.isRequired,
  persistentStorage: PropTypes.object,
  projectContext: PropTypes.object,
  id: PropTypes.string,
  tiles: PropTypes.object,
  projectHistory: PropTypes.object,
  spinloader: PropTypes.object,
  resultTab: PropTypes.object,
  activeFileId: PropTypes.string,
  history: PropTypes.object,
  roiLayers: PropTypes.array,
  structures: PropTypes.array,
};

export default withRouter(
  withTiles(withAllViewerContexts(withResultTab(withStyles(styles)(Viewer))))
);
